import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme)=>({
  radioField:{
    display:'flex',
    alignItems:'center',
    height:24,
    gap:13,
    justifyContent:'flex-start',
    cursor:'pointer',
  },
  radioBox:{
    height:18,
    width:18,
    borderRadius:18,
    border:'1px solid #968896',
    display:'flex',
    alignItems:'center',
    justifyContent:'center',
    backgroundColor:'#fff',
    '&.checked':{
      border:'1px solid '+theme.palette.primary.main,
    }
  },
  radioBoxInternal:{
    height:10,
    width:10,
    borderRadius:15,
    backgroundColor:'#fff',
    '&.checked':{
      backgroundColor:theme.palette.primary.main,
    }
  }
}));

export default useStyles;
