import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  root: {
    width: 90,
    height: 32,
    borderRadius: 4,
    color:'#545964',
    padding:'7px 14px 7px 10px',
    border: '1px solid #AAA2AA',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },

  downArrowStyle:{
    borderBottom: 'solid 2px #7F7182',
    borderLeft: 'solid 2px #7F7182',
    height: 8,
    width: 8,
    transform: 'rotate(-45deg) translateY(-1.5px)',
  },

  customSelectField: {
    minWidth: '100px',
    '& .MuiInputBase-input': {
      fontSize: '16px',
      fontWeight: 400,
      backgroundColor: 'white',
      padding: '5px 35px 5px 8px',
      borderRadius: '4px',
      color: '#828282',
    },
  },
}));

export default useStyles;
