import React, { useState, useCallback, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import {
  IconButton,
  TableCell,
  TableRow,
  Menu,
  MenuItem,
  Select,
} from '@material-ui/core';
import { MoreVert, LinkOff } from '@material-ui/icons';
import clsx from 'clsx';
import { t } from 'ttag';
import useConfirmationDialog from '@components/ConfirmationDialogV2';
import useBacNet from '@hooks/useBacNet';
import { useStoreState } from '@models/RootStore';
import useStyles from './styles';


/**
 * DeviceTableRow component renders a row in the devices table with details about a BACnet device.
 *
 * @component
 * @param {Object} props - The properties object.
 * @param {Object} props.bacnetDevice - The BACnet device object.
 * @param {Object} props.parameterTemplates - The parameter Templates.
 * @param {string} props.bacnetDevice.id - The unique identifier of the BACnet device.
 * @param {string} props.bacnetDevice.serial - The serial number of the BACnet device.
 * @param {string} props.bacnetDevice.network - The network identifier of the BACnet device.
 * @param {string} props.bacnetDevice.bacnetId - The BACnet ID of the device.
 * @param {string} props.bacnetDevice.title - The title of the BACnet device.
 * @param {string} props.bacnetDevice.name - The name of the BACnet device.
 * @param {string} props.bacnetDevice.description - The description of the BACnet device.
 * @returns {JSX.Element} The rendered component.
 */
const DeviceTableRow = (props) => {
  const classes = useStyles();
  const history = useHistory();

  const {
    bacnetDevice,
    siteId,
    handleParamTemplateAssign,
    handleParamTemplateUnassign,
    handleUiTemplateAssign,
    handleUiTemplateUnassign,
    handleConnectionChange,
    uiTemplates,
    goToUiTemplateMapping,
    onDeleteBacknetDevice,
  } = props;

  const {
    deleteBacNetUnit
  } = useBacNet();

  const [actionsMenuAnchorEl, setActionsMenuAnchorEl] = useState(null);
  const [showConfirmationDialog, ConfirmationDialog] = useConfirmationDialog();
  const devices = useStoreState((state) => state.devices.allDevices);
  const deviceIsConnected = devices?.[bacnetDevice?.device]?.isConnected;
  const isDisconnected = !deviceIsConnected || !bacnetDevice.isConnected;

  const filteredUiTemplates = useMemo(
    () =>{
      if(!uiTemplates || !bacnetDevice){
        return [];
      }

      return uiTemplates.filter((uiTemplate) => uiTemplate.paramTemplate == bacnetDevice.paramTemplate);
    },
    [uiTemplates, bacnetDevice]
  );

  const handleClick = (event) => {
    setActionsMenuAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setActionsMenuAnchorEl(null);
  };

  //show confirm dialog
  const handleConnect = async () => {
    const result = await showConfirmationDialog({
      title: t`Connect Device?`,
      bodyMessage: t`Are you sure you want to connect this device?`,
      confirmText: t`Connect`,
      cancelText: t`Cancel`,
    });

    if(result) {
      handleConnectionChange(bacnetDevice?.id, true);
      handleClose();
    }
  };

  //show handleDisconnect
  const handleDisconnect = async () => {
    const result = await showConfirmationDialog({
      title: t`Disconnect Device?`,
      bodyMessage: t`Are you sure you want to disconnect this device?`,
      confirmText: t`Disconnect`,
      cancelText: t`Cancel`,
    });

    if(result) {
      handleConnectionChange(bacnetDevice?.id, false);
      handleClose();
    }
  };

  // confirm delete device and do it
  const handleDeleteDevice = async () => {
    const result = await showConfirmationDialog({
      title: t`Delete Device?`,
      bodyMessage: t`Are you sure you want to delete this device?`,
      confirmText: t`Delete`,
      cancelText: t`Cancel`,
    });

    if(result) {
      await deleteBacNetUnit(bacnetDevice?.id);
      onDeleteBacknetDevice(bacnetDevice?.id);
      handleClose();
    }
  };

  const goToParamTemplateMapping = () => {
    history.push(
      `/automation/bacnet-detection/site/${siteId}/bacnet-unit/${bacnetDevice.id}`
    );
  };

  const handleGoToParamTemplateMapping = () => {
    goToUiTemplateMapping(bacnetDevice.uiTemplate ?? null);
    handleClose();
  };

  const isActionsMenuOpen = !!actionsMenuAnchorEl;

  const handleParamTemplateChange = useCallback(async (event) => {
    const templateId = event.target.value;
    if (templateId) {
      await handleParamTemplateAssign(templateId, bacnetDevice.id);
    } else {
      await handleParamTemplateUnassign(
        bacnetDevice?.paramTemplate,
        bacnetDevice.id
      );
    }
  }, []);

  const handleUiTemplateChange = useCallback(async (event) => {
    const templateId = event.target.value;
    if (templateId) {
      await handleUiTemplateAssign(event.target.value, bacnetDevice.id);
    } else {
      await handleUiTemplateUnassign(bacnetDevice?.uiTemplate, bacnetDevice.id);
    }
  }, []);

  if(!bacnetDevice){
    return null;
  }

  const renderParamTemplateSelect = () => {

    if(!bacnetDevice.compatibleParamTemplates?.length){
      return '--';
    }

    return (
      <Select
        fullWidth
        disableUnderline
        value={bacnetDevice.paramTemplate || null}
        onChange={handleParamTemplateChange}
        displayEmpty
        disabled={bacnetDevice.isEngaged}
        classes={{ select: classes.inputRoot }}
      >
        <MenuItem value={null} disabled={!bacnetDevice.paramTemplate}>
          {'--'}
        </MenuItem>
        {bacnetDevice.compatibleParamTemplates?.map((template) => {
          const { id, name } = template;
          return (
            <MenuItem key={id} value={id}>
              {name}
            </MenuItem>
          );
        })}
      </Select>
    );

  };

  const renderUiTemplatesSelect = () => {

    if(!filteredUiTemplates.length){
      return '--';
    }

    return (
      <Select
        fullWidth
        disableUnderline
        value={bacnetDevice.uiTemplate || null}
        onChange={handleUiTemplateChange}
        displayEmpty
        disabled={bacnetDevice.isEngaged || !bacnetDevice.paramTemplate}
        classes={{ select: classes.inputRoot }}
        inputProps={{ className: classes.textOverflow }}
      >
        <MenuItem value={null} disabled={!bacnetDevice.uiTemplate}>
          {'--'}
        </MenuItem>
        {filteredUiTemplates.map((uiTemplate) => {
          const { id, name } = uiTemplate;
          return (
            <MenuItem key={id} value={id}>
              {name}
            </MenuItem>
          );
        })}
      </Select>
    );
  };

  const getLineCellValue = () => {

    const {line} = bacnetDevice;

    if(isNaN(line)){
      return 'unknown line';
    }

    if(line === 0){
      return '0 (IP)';
    }

    return `${line} (MSTP)`;

  };

  return (
    <TableRow tabIndex={-1} key={bacnetDevice.id} className={clsx({[classes.disconnectedDevice]: !bacnetDevice.isConnected})}>
      <TableCell title={`${t`App Name`}: ${bacnetDevice.title}`} style={{cursor:'help'}} classes={{ root: clsx(classes.TableCell) }} align="left">
        <div className={classes.nameCell}>
          <span>{bacnetDevice.name}</span>
          {isDisconnected && <LinkOff style={{ color: 'red' }} />}
        </div>
      </TableCell>
      <TableCell classes={{ root: clsx(classes.TableCell) }} align="left">
        {bacnetDevice.bacnetId ?? '<?>'}
      </TableCell>
      <TableCell classes={{ root: clsx(classes.TableCell) }} align="left">
        {bacnetDevice.vendorId}
      </TableCell>
      <TableCell classes={{ root: clsx(classes.TableCell) }} align="left">
        {bacnetDevice.vendorName}
      </TableCell>
      <TableCell classes={{ root: clsx(classes.TableCell) }} align="left">
        {bacnetDevice.macAddress}
      </TableCell>
      <TableCell classes={{ root: clsx(classes.TableCell) }} align="left">
        {bacnetDevice.serial}
      </TableCell>
      <TableCell style={{width:65}} classes={{ root: clsx(classes.TableCell) }} align="left">
        {getLineCellValue()}
      </TableCell>
      <TableCell classes={{ root: clsx(classes.TableCell) }} align="left">
        {bacnetDevice.modelName}
      </TableCell>
      <TableCell
        classes={{ root: clsx(classes.TableCell, classes.MediumWidth) }}
        align="left"
      >
        {renderParamTemplateSelect()}
      </TableCell>
      <TableCell
        classes={{ root: clsx(classes.TableCell, classes.mediumWidth) }}
        align="left"
      >
        {renderUiTemplatesSelect()}
      </TableCell>
      <TableCell
        classes={{ root: clsx(classes.TableCell, classes.statusCell, { [classes.connectedDevice]: bacnetDevice.isEngaged }) }}
        align="center"
      >
        {bacnetDevice.isEngaged ? t`Bound to app` : t`Not Bound`}
      </TableCell>
      <TableCell classes={{ root: classes.TableCell }} align="left">
        <IconButton
          id="actions-menu-button"
          aria-controls={isActionsMenuOpen ? 'actions-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={isActionsMenuOpen ? 'true' : undefined}
          onClick={handleClick}
        >
          <MoreVert />
        </IconButton>
        <Menu
          id="actions-menu"
          anchorEl={actionsMenuAnchorEl}
          open={isActionsMenuOpen}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'actions-menu-button',
          }}
        >
          <MenuItem
            key="connect"
            disabled={bacnetDevice.isEngaged || !bacnetDevice.paramTemplate}
            onClick={handleConnect}
          >{t`Bind`}</MenuItem>
          <MenuItem
            key="disconnect"
            disabled={!bacnetDevice.isEngaged}
            onClick={handleDisconnect}
          >{t`Unbind`}</MenuItem>
          <MenuItem
            key="param-template-mapping"
            onClick={goToParamTemplateMapping}
          >{t`Object Mapping`}</MenuItem>
          <MenuItem
            key="ui-tempalte-mapping"
            onClick={handleGoToParamTemplateMapping}
          >{t`Control UI`}</MenuItem>
          {
            !bacnetDevice.isConnected && (
              <>
                <MenuItem disabled>
                  <hr style={{ width: '100%', border: 'none', borderTop: '1px solid #ccc' }} />
                </MenuItem>
                <MenuItem
                  key="delete"
                  style={{ color: 'red' }}
                  onClick={handleDeleteDevice}
                >{
                    t`Delete`}
                </MenuItem>
              </>
            )
          }
        </Menu>
      </TableCell>
      <ConfirmationDialog />
    </TableRow>
  );
};

/**
 * Comparison function to determine if the DeviceTableRow component should re-render.
 *
 * @function
 * @param {Object} prevProps - The previous properties.
 * @param {Object} prevProps.bacnetDevice - The previous BACnet device object.
 * @param {string} prevProps.bacnetDevice.id - The unique identifier of the previous BACnet device.
 * @param {string} prevProps.bacnetDevice.title - The title of the previous BACnet device.
 * @param {boolean} nextProps.bacnetDevice.isEngaged - The isEngaged of the previous BACnet device.
 * @param {string} nextProps.bacnetDevice.paramTemplate - The paramTemplate of the previous BACnet device.
 * @param {Object} nextProps - The next properties.
 * @param {Object} nextProps.bacnetDevice - The next BACnet device object.
 * @param {string} nextProps.bacnetDevice.id - The unique identifier of the next BACnet device.
 * @param {string} nextProps.bacnetDevice.title - The title of the next BACnet device.
 * @param {boolean} nextProps.bacnetDevice.isEngaged - The isEngaged of the next BACnet device.
 * @param {string} nextProps.bacnetDevice.paramTemplate - The paramTemplate of the next BACnet device.
 * @param {string} nextProps.uiTemplates - The uiTemplate of the next BACnet device.
 * @returns {boolean} True if the component should not re-render, false otherwise.
 */
const compareFn = function (prevProps, nextProps) {

  const result =
    prevProps.bacnetDevice.id === nextProps.bacnetDevice.id &&
    prevProps.bacnetDevice.title === nextProps.bacnetDevice.title &&
    prevProps.bacnetDevice.isEngaged === nextProps.bacnetDevice.isEngaged &&
    prevProps.bacnetDevice.paramTemplate === nextProps.bacnetDevice.paramTemplate &&
    prevProps.bacnetDevice.uiTemplate === nextProps.bacnetDevice.uiTemplate &&
    prevProps.uiTemplates === nextProps.uiTemplates;

  return result;
};

export default React.memo(DeviceTableRow, compareFn);
