import React from 'react';

const wrapperStyle = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  gap: 10,
};

const arrowStyle = {
  borderColor: '#7F7182',
  borderBottom: 'solid 2px',
  borderLeft: 'solid 2px',
  height: 8,
  width: 8,
};

export const upArrowStyle = {
  ...arrowStyle,
  transform: 'rotate(135deg) translateY(-3px)',
};

export const downArrowStyle = {
  ...arrowStyle,
  transform: 'rotate(-45deg) translateY(-1.5px)',
};

function NumericControlIcon() {
  return (
    <div style={wrapperStyle}>
      <div style={downArrowStyle}></div>
      <div style={upArrowStyle}></div>
    </div>
  );
}

export default NumericControlIcon;
