// @ts-nocheck
import {
  Button as MButton,
  ButtonGroup,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import clsx from "clsx";
import _ from "lodash";
import React from "react";
import { t } from "ttag";
import Button from "../../cool_widgets/Button";
import { ArrowDownO, Close, Plus } from "../../icons";
import Tooltip from "../Tooltip/LightTooltip";
import styles from "./AutomationRule.style";

const CustomSelect = ({ className, error, disabled, options = [], value, placeholder, onChange, optionValue = "value", label = "name", name, formLabel }: any) => {
  const classes = styles();
  const replacedValue = value ?? "";
  const displayLabel = replacedValue ? (formLabel ?? placeholder) : placeholder; // fallback to placeholder if formLabel is missing

  return (
    <FormControl variant="outlined" className={clsx(classes.formControl, { [className]: className })}>
      <InputLabel id="demo-simple-select-outlined-label" className={classes.labelSelect}>{displayLabel}</InputLabel>
      <Select
        name={name}
        displayEmpty
        disabled={disabled}
        value={replacedValue}
        onChange={onChange}
        error={error}
        variant="outlined"
        disableUnderline
        classes={{ icon: classes.arrowDownIcon }}
        IconComponent={ArrowDownO}
        className={clsx(classes.select, { [classes.disabledSelect]: options.length === 0 || disabled })}
        label={placeholder}
        MenuProps={{
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "left"
          },
          transformOrigin: {
            vertical: "top",
            horizontal: "left"
          },
          getContentAnchorEl: null
        }}
      >
        {_.isEmpty(options) ? <MenuItem value={"none"} disabled>{t`No Options`}</MenuItem> : <MenuItem value={""} disabled
          style={{ display: "none" }}
        ></MenuItem>}
        {options.map((option: any) => {
          return (<MenuItem key={option[optionValue]} value={option[optionValue]} disabled={option.readOnly} className={clsx({ [classes.separator]: option.isSeparator })}>
            {option[label]}
          </MenuItem>);
        }
        )}
      </Select >
    </FormControl>

  );
};

const Box = ({ title, children, small, bLabel1, bLabel2, bDisabled1, bDisabled2, action1, action2, className, addBottomMargin, boxRef }: any) => {
  const classes = styles();

  return <div id="box1" className={clsx(classes.boxContainer, { [classes.small]: small, [classes.addBottomMargin]: addBottomMargin, [className]: className })}>
    <div id="box-header1" className={classes.boxHeader}>
      <Typography className={classes.boxTitle}>{title}</Typography>
      <div>
        {action1 && <Button
          white
          width={small ? 130 : 144}
          className={clsx(classes.btn, classes.addMargin13, { [classes.disabledWhiteButton]: bDisabled1 })}
          startIcon={<Plus className={classes.plusIcon} />}
          disabled={bDisabled1}
          onClick={action1}
          onMouseDown={(event: any) => { event.preventDefault(); }}
        >
          {bLabel1}
        </Button>}
        {action2 && <Button onMouseDown={(event: any) => { event.preventDefault(); }} white width={110} className={clsx(classes.btn, { [classes.disabledWhiteButton]: bDisabled2 })} disabled={bDisabled2} onClick={action2}
          startIcon={small ? <Plus className={classes.plusIcon} /> : undefined} >
          {bLabel2}
        </Button>}
      </div>
    </div>
    <div ref={boxRef} className={clsx(classes.boxContent, { [classes.unitsAppliedBox]: small })}>{children}</div>
  </div >;
};

const CustomedTextField = ({ maxLength = "", error, label, disabled, placeholder, value, width, normalMargin, className, onChange, name, type = "string" }: any, props: any) => {
  const classes = styles();

  return <TextField
    label={label}
    disabled={disabled}
    classes={{ root: classes.label }}
    name={name}
    value={value}
    style={width ? { width } : undefined}
    onChange={onChange}
    placeholder={placeholder}
    variant="outlined"
    error={error}
    className={clsx(classes.textField, { [classes.addMargin]: !normalMargin, [className]: className })}
    type={type}
    inputProps={{ maxLength }}
  />;
};
const GButton = ({ inGroup, selected = "and", onClick, disabled }: any) => {
  const classes = styles();

  return <ButtonGroup style={{ borderRadius: 6, margin: inGroup ? "5px 0" : "10px 0", marginLeft: inGroup ? 13 : 30 }}
  >
    <MButton disabled={disabled} onMouseUp={() => onClick("and")} className={clsx(classes.andOrButton, { [classes.selectedAndOrButton]: selected === "and" })}>And</MButton>
    <MButton disabled={disabled} onMouseUp={() => onClick("or")} className={clsx(classes.andOrButton, { [classes.selectedAndOrButton]: selected === "or" })}>Or</MButton>
  </ButtonGroup>;
};

const UnitWrapper = ({ onRemove, siteName, systemName, unitName, disabled = false }: any) => {
  const classes = styles();

  return <div className={classes.wrapperContainer}>
    <Tooltip arrow interactive placement="top-start" title={siteName + " /" + systemName + " /" + unitName}>
      <Typography className={classes.namesContainer}><span className={classes.name}>{siteName || ""}</span> <span className={classes.middle}>/</span> <span className={classes.name}>{systemName || ""}</span><span className={classes.middle}>/</span> <span className={classes.name}>{unitName || ""}</span></Typography>
    </Tooltip>
    <IconButton disabled={disabled} className={classes.removeButton} onClick={onRemove}><Close color="#fff" /></IconButton></div>;
};

const SystemWrapper = ({ onRemove, siteName, systemName, disabled = false }: any) => {
  const classes = styles();

  return <div className={classes.wrapperContainer}>
    <Tooltip arrow interactive placement="top-start" title={siteName + " /" + systemName} >
      <Typography className={classes.namesContainer}><span className={classes.name}>{siteName || ""}</span> <span className={classes.middle}>/</span> <span className={classes.name}>{systemName}</span></Typography>
    </Tooltip>
    <IconButton disabled={disabled} className={classes.removeButton} onClick={onRemove}><Close color="#fff" /></IconButton></div>;
};

const TextInput = ({
  id,
  label,
  error,
  className,
  onChange,
  ...props
}: any) => {
  const classes = clsx(
    "input-group",
    {
      "animated shake error": !!error
    }
    // className
  );
  return (
    <div className={classes}>
      <label htmlFor={id}>
        {label}
      </label>
      <input
        id={id}
        className="text-input"
        onChange={onChange}
        {...props}
      />
      <p>]{error} </p>
    </div>
  );
};

const CustomAutoComplete = ({
  filters = {},
  className,
  error,
  disabled,
  options = [],
  value,
  placeholder,
  onChange,
  label = 'name',
  name,
  getOptionDisabledCondition,
  formLabel
}) => {
  const classes = styles();

  const getOptionDisabled = getOptionDisabledCondition
    ? (option) => getOptionDisabledCondition(option)  // custom disable condition
    : undefined;  // no disabling by default

  const displayLabel = value ? (formLabel || placeholder) : placeholder;

  return (
    <Autocomplete
      className={clsx(classes.autoComplete, className, { [classes.disabledSelect]: disabled })}
      classes={{
        input: classes.inputRoot,
        endAdornment: classes.endAdornment,
        popupIndicator: classes.popupIndicator
      }}
      popupIcon={<div style={{
        height: "36px",
        width: "25px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
      }}><ArrowDownO /></div>}
      noOptionsText={t`No Options`}
      closeText=""
      disableClearable={true}
      disabled={disabled}
      value={value || ""}
      options={options}
      getOptionDisabled={getOptionDisabled}  // apply the dynamic disable condition if provided
      getOptionLabel={(option: any) => option[label] || ""}
      onChange={onChange}
      renderInput={(params) => (
        <TextField {...params} value={value || ""} classes={{ root: classes.label }} className={clsx(classes.textField, { [classes.disabledSelect]: disabled })} name={name} variant="outlined" disabled={disabled} label={displayLabel} error={error} />
      )}
    />
  );
};

export { CustomSelect, Box, CustomedTextField, GButton, SystemWrapper, UnitWrapper, TextInput, CustomAutoComplete };
