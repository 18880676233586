// @ts-nocheck
import { IconButton } from "@material-ui/core";
import clsx from "clsx";
import { getIn } from "formik";
import _ from "lodash";
import React, { useMemo } from 'react';
import { t } from "ttag";
import Button from "../../cool_widgets/Button";
import { Delete } from "../../icons";
import { useStoreState } from "../../models/RootStore";
import Tooltip from "../Tooltip/LightTooltip";
import styles from "./AutomationRule.style";
import { getCustomOptions } from "./conditionUtils";
import { CustomAutoComplete, CustomedTextField, CustomSelect } from "./CustomedComponents";
import useTypes from '@hooks/useTypes';

const indoorActionOptions = [
  { value: 1, enum: "unitOperationStatuses", name: "ON/OFF" },
  { value: 3, data_unit_of_measurement: "°C", name: "Setpoint" },
  { value: 4, name: "Fan", enum: "unitFanModes" },
  { value: 2, enum: "unitOperationModes", name: "Mode" },
  { value: 7, name: 'Send Alert', isSeparator: true}
];

const actionWRCOptions = [
  { value: 8, enum: "trueFalseEnum", name: "WRC On/Off" },
  { value: 9, enum: "trueFalseEnum", name: "WRC Mode" },
  { value: 10, enum: "trueFalseEnum", name: "WRC Setpoint" },
];

const hRVactionOptions = [
  { value: 1, enum: "unitOperationStatuses", name: "ON/OFF" },
  { value: 4, name: "Fan", enum: "unitFanModes" },
  { value: 2, enum: "unitOperationModes", name: "Mode" },
  { value: 7, name: 'Send Alert', isSeparator: true }
];
const onOffSensorEnums = [{ value: "0", name: t`OFF` }, { value: 1, name: t`ON` }];
const systemActionOptions = [
  { value: 1, enum: "unitOperationStatuses", name: "ON/OFF" },
  { value: 3, data_unit_of_measurement: "°C", name: "Setpoint" },
  { value: 4, name: "Fan", enum: "unitFanModes" },
  { value: 2, enum: "unitOperationModes", name: "Mode" }
];

const sensorActionOptions = [
  { value: 6, name: "Value" },
  { value: 7, name: 'Send Alert', isSeparator: true}
];

const bacNetUnitActionOptions = [
  { value: 7, name: 'Send Alert', isSeparator: true }
];

const soruceOptions = [
  { value: "systems", name: "System" },
  { value: "outputSensors", name: "Output Device" },
  { value: "indoors", name: "Indoor Unit" },
  { value: "alert", name: "Alert" },
  { value: 'WRCLocks', name: 'WRC locks' },
];

const ruleParameters: any = {
  4: {
    allBrands: true,
    code: 52,
    data_unit_of_measurement: "",
    enabledInTriggers: false,
    enum: "unitFanModes",
    hvac_param_name: "FanSpeed",
    max: 10,
    min: 0,
    plotable: true,
    showInGraph: true,
    title: "Fan Speed"
  },
  2: {
    allBrands: true,
    code: 51,
    data_unit_of_measurement: "",
    enabledInTriggers: true,
    enum: "unitOperationModes",
    hvac_param_name: "Mode",
    max: 10,
    min: 0,
    plotable: true,
    showInGraph: true,
    title: "Mode"
  },
  1: {
    allBrands: true,
    code: 48,
    data_unit_of_measurement: "",
    enabledInTriggers: true,
    enum: "unitOperationStatuses",
    hvac_param_name: "IndoorOnOffStatus",
    max: 1,
    min: 0,
    plotable: true,
    showInGraph: false,
    title: "ON/OFF"
  },
  49: {
    allBrands: true,
    code: 49,
    data_unit_of_measurement: "°C",
    enabledInTriggers: true,
    hvac_param_name: "RoomTemp",
    max: 0,
    min: 100,
    plotable: true,
    showInGraph: false,
    title: "Room Temp"
  },
  3: {
    allBrands: true,
    code: 50,
    data_unit_of_measurement: "°C",
    enabledInTriggers: true,
    hvac_param_name: "SetTemp",
    max: 0,
    min: 100,
    plotable: true,
    showInGraph: false,
    title: "Set Temp"
  },
  57: {
    allBrands: true,
    code: 57,
    data_unit_of_measurement: "",
    enabledInTriggers: false,
    enum: "",
    hvac_param_name: "SiteTemp",
    max: 150,
    min: -50,
    plotable: true,
    showInGraph: true,
    title: "Site Temperature"
  },
  8: {
    code: 8,
    enum: "trueFalseEnum",
    data_unit_of_measurement: "",
    title: "WRC On/Off",
    hvac_param_name: "WRC On/Off",
    max: 1,
    min: 0,
    plotable: true,
    allBrands: true,
    enabledInTriggers: true,
    showInGraph: true
  },
  9: {
    code: 9,
    enum: "trueFalseEnum",
    data_unit_of_measurement: "",
    title: "WRC Mode",
    hvac_param_name: "WRC Mode",
    max: 10,
    min: 0,
    plotable: true,
    allBrands: true,
    enabledInTriggers: true,
    showInGraph: true
  },
  10: {
    code: 10,
    enum: "trueFalseEnum",
    data_unit_of_measurement: "",
    title: "WRC Setpoint",
    hvac_param_name: "WRC Setpoint",
    max: 100,
    min: 0,
    plotable: true,
    allBrands: true,
    enabledInTriggers: true,
    showInGraph: true
  }
};

const selectItemPlaceholders = {
  systems: t`Select System`,
  outputSensors: t`Select Sensor`,
  indoors: t`Select Indoor Unit`,
  "": t`Select`,
  bacNetUnits: t`Select BACnet Device`,
  WRCLocks: t`Select Indoor Unit`
};

const selectItemLabels = {
  systems: t`System`,
  outputSensors: t`Sensor`,
  indoors: t`Indoor Unit`,
  '': t`Select`,
  bacNetUnits: t`BACnet Device`,
  WRCLocks: t`Indoor Unit`
};

const actionsOptionsMapBySource = {
  systems: systemActionOptions,
  outputSensors: sensorActionOptions,
  indoors: indoorActionOptions, // default for indoors
  WRCLocks: actionWRCOptions,
  bacNetUnits: bacNetUnitActionOptions
};

const getActionOptions = (source, item, bacNetObjectsByTemplateId) => {
  // handle the "indoors" source with a special case for hrv indoors "subtype 2"
  if (source === 'indoors' && item?.subType === 2) {
    return hRVactionOptions;
  }

  if (source === 'bacNetUnits') {
    return getBacNetObjectOptions(item, bacNetObjectsByTemplateId);
  }

  // return the options based on the source type
  return actionsOptionsMapBySource[source] || [];
};

const getBacNetObjectOptions = (item, objectsByTemplateId) => {
  if (!item) {
    return bacNetUnitActionOptions;
  }

  return [
    ...(objectsByTemplateId[item.paramTemplate] || []),
    ...bacNetUnitActionOptions
  ];
};

export default (props: any) => {
  const classes = styles();
  const {
    errors,
    touched,
    index,
    action,
    setFieldValue,
    serviceParamTypes,
    customEnumOptions,
    site,
    values,
    deleteAction,
    setValues,
    disabled = false,
    duplicateAction = null
  } = props;

  const {
    temperatureScale,
    enableBacnet,
  } = useStoreState((s) => s.users.me);

  const {
    trapActionTypes,
    bacnetMeasurementUnits,
    trapActionParameterOperators,
  } = useTypes();

  const expandedSourceOptions = useMemo(() => {
    return enableBacnet
      ? [
          ...soruceOptions,
          { value: 'bacNetUnits', name: 'BACnet Device' },
        ]
      : soruceOptions;
  }, [enableBacnet]);

  const actionOptions = useMemo(() =>
    getActionOptions(
      action.source,
      action.itemId,
      site.bacNetObjectsByTemplateId
    ),
    [action.source, action.itemId, site.bacNetObjectsByTemplateId]
  );

  const renderValueField = () => {
    if (action.action === trapActionTypes.createEvent) {
      return null;
    }

    let type;
    let options;
    let enumOptions;
    let label = t`Value`;

    if (action.source === 'outputSensors') {
      type = 'number';
      enumOptions = action.itemId?.type === 129 && onOffSensorEnums;
    } else if (action.source === 'bacNetUnits') {
      const bacnetUnitObjects =  site.bacNetObjectsByTemplateId[action.itemId?.paramTemplate] ?? [];
      const object = bacnetUnitObjects.find(object => object.code === action.action);
      enumOptions = object?.enum && getCustomOptions(action.source, object.enum);

      if (object?.data_unit_of_measurement) {
        label = label + ` (${bacnetMeasurementUnits[object.data_unit_of_measurement] ?? t`unknown`})`;
      }
    } else {
      enumOptions = ruleParameters[action.action]?.enum ? getCustomOptions(
        action.source,
        ruleParameters[action.action]?.enum,
        action.itemId.subType,
        serviceParamTypes,
        customEnumOptions
      ) : null;

      if (+action.action === trapActionTypes.setUnitSetpoint) {
        label = label + `${temperatureScale === 2 ? ' °F' : ' °C'}`;
      }
    }

    const operatorSelect = (
      <CustomSelect
        disabled={disabled || !!enumOptions || !action.itemId || !action.action}
        className={classes.width130}
        error={getIn(errors, `actions[${index}].operator`) && getIn(touched, `actions[${index}].operator`)}
        placeholder={t`Operator`}
        name='operator'
        value={action.operator || '='}
        onChange={(event) => setFieldValue(`actions[${index}].operator`, event.target.value)}
        options={trapActionParameterOperators}
        label='label'
      />
    );

    if (enumOptions) {
       if (action.operator !== '=') {
         setFieldValue(`actions[${index}].operator`, '=');
       }

       return (
         <>
           {action.source !== 'WRCLocks' && operatorSelect}
           <CustomSelect
             disabled={disabled || !action.itemId || !action.action}
             className={classes.width130}
             error={getIn(errors, `actions[${index}].value`) && getIn(touched, `actions[${index}].value`)}
             placeholder={t`Value`}
             name='value'
             value={action.value}
             onChange={(event: any) => setFieldValue(`actions[${index}].value`, event.target.value)}
             options={enumOptions}
           />
         </>
       )
    }

    const isLabelLong = label.length > 10;
    const shortLabel = isLabelLong ? `${label.substring(0, 10)}...` : label;
    return (
      <>
        {action.source !== 'WRCLocks' && operatorSelect}
        <Tooltip title={label} placement='bottom' disableTooltip={!isLabelLong}>
          <span>
            <CustomedTextField
              disabled={disabled || !action.itemId || !action.action}
              className={classes.width130}
              error={getIn(errors, `actions[${index}].value`) && getIn(touched, `actions[${index}].value`)}
              onChange={(event: any) => setFieldValue(`actions[${index}].value`, event.target.value)}
              name='value'
              type={type}
              value={action.value}
              label={shortLabel}
            />
          </span>
        </Tooltip>
      </>
    );
  };

  return (
    <div className={clsx(classes.conditionContainer, classes.marginBottom)}>
      <CustomSelect
        className={classes.width180}
        error={getIn(errors, `actions[${index}].source`) && getIn(touched, `actions[${index}].source`)}
        placeholder={t`Select Source`}
        formLabel={t`Source`}
        value={action.source}
        name="source"
        onChange={(event: any) => {
          setFieldValue(`actions[${index}]`, {
            ...action,
            source: event.target.value,
            itemId: '',
            action: '',
            value: '',
            operator: '',
          });
        }}
        options={expandedSourceOptions}
        disabled={disabled}
      />
      {action.source !== "alert" &&
        <>
          <CustomAutoComplete
            error={getIn(errors, `actions[${index}].itemId`) && getIn(touched, `actions[${index}].itemId`)}
            placeholder={selectItemPlaceholders[action.source || ""]}
            formLabel={selectItemLabels[action.source || ""]}
            onChange={(event: any, value: any) => {
              if (action.source === 'bacNetUnits') {
                setFieldValue(`actions[${index}]`, {
                  ...action,
                  itemId: value,
                  value: '',
                  action: action.action === trapActionTypes.createEvent ? action.action : ''
                });
                return;
              }

              setFieldValue(`actions[${index}].itemId`, value);
              if (action.source === "indoors" && action.itemId && (action.itemId.subType !== value?.subType)) {
                setFieldValue(`actions[${index}].value`, "");
                if (+action?.action === 3) {
                  setFieldValue(`actions[${index}].action`, "");
                }
              }
            }}
            value={action.itemId}
            options={action.source ? site[action.source] : []}
            name="itemId"
          />
          <CustomSelect
            disabled={disabled || !action.itemId}
            className={classes.actionSelect}
            error={getIn(errors, `actions[${index}].action`) && getIn(touched, `actions[${index}].action`)}
            placeholder={t`Select Parameter / Action`}
            formLabel={t`Parameter / Action`}
            name="action"
            value={action.action}
            options={actionOptions}
            onChange={(event: any) => {
              setFieldValue(`actions[${index}]`, {
                ...action,
                action: event.target.value,
                value: '',
                operator: '='
              });
            }}
          />
          {renderValueField()}
        </>
      }
      <div className={classes.buttonsContainer}>
        {!!duplicateAction && <Tooltip title={t`Duplicate action`} >
          <Button
            disabled={disabled}
            onClick={duplicateAction}
            variant="text"
            size="small"
            className={classes.duplicateButton}
          >
            {t`Duplicate`}
          </Button>
        </Tooltip>
        }
        <IconButton
          disabled={disabled}
          className={clsx(classes.iconBtnStyle, classes.deleteIconNotInGroup)}
          onClick={deleteAction}
        >
          <Delete style={{ width: 20, height: 20 }} />
        </IconButton>
      </div>
    </div>
  );
};
