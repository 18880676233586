// @ts-nocheck
import { IconButton, Typography } from "@material-ui/core";
import { DatePicker } from "@material-ui/pickers";
import clsx from "clsx";
import { getIn } from "formik";
import moment from "moment";
import React, { useEffect, useState, useMemo } from 'react';
import { t } from "ttag";
import Button from "../../cool_widgets/Button";
import Checkbox from "../../cool_widgets/CoolCheckbox";
import { CheckboxChecked, Delete } from "../../icons";
import { useStoreState } from "../../models/RootStore";
import CommonUtils from "../../utils/CommonUtils";
import Tooltip from "../Tooltip/LightTooltip";
import AnomaliesUtils from "./AnomaliesUtils";
import styles from "./AutomationRule.style";
import { getCustomOptions } from "./conditionUtils";
import { CustomAutoComplete, CustomedTextField, CustomSelect, GButton } from "./CustomedComponents";
import _ from "lodash";
import useTypes from '@hooks/useTypes';

/**
 * Interface representing an HVAC parameter.
 */
interface HvacParam {
  /** Whether this parameter applies to all brands.*/
  allBrands: boolean;
  /** Unique code for the parameter.*/
  code: number;
  /** Unit of measurement used for the data (may be empty string).*/
  data_unit_of_measurement: string;
  /** Whether this parameter can be used in triggers.*/
  enabledInTriggers: boolean;
  /** The enumeration used for this parameter (e.g., "unitOperationStatuses").*/
  enum: string;
  /** The internal name of the HVAC parameter.*/
  hvac_param_name: string;
  /** Maximum allowed value for the parameter.*/
  max: number;
  /** Minimum allowed value for the parameter.*/
  min: number;
  /** Whether this parameter can be plotted on a graph.*/
  plotable: boolean;
  /** Whether this parameter should be shown in a graph.*/
  showInGraph: boolean;
  /** Human-readable title for the parameter.*/
  title: string;
/** Whether this parameter is custom */
  isCustomParam:boolean
}

const soruceOptions = [
  { value: "systems", name: "System" },
  { value: "sensors", name: "Input Device" },
  { value: "indoors", name: "Indoor Unit" },
  { value: "WRCLocks", name: "WRC locks" },
  { value: "powerMeters", name: "Power Meters" }
];
const serviceSoruceOptions = [
  { value: "systems", name: "System" },
  { value: "sensors", name: "Input Device" },
  { value: "services", name: "Indoor Unit" },
  { value: "outdoors", name: "Outdoor Unit" },
  { value: "time", name: "Time" },
  { value: "WRCLocks", name: "WRC locks" },
  { value: 'powerMeters', name: 'Power Meters' },
];
const operators = [
  { value: ">", name: " >" },
  { value: "<", name: " <" },
  { value: "=", name: "=" },
  { value: "!=", name: "≠" }
];
const theresholdOptions = [
  { value: "threshold", name: " >" },
  { value: "thresholdWithin", name: " <" }
];
const enumOperators = [
  { value: "=", name: "=" },
  { value: "!=", name: "≠" }
];
const numOfUnitsOptions = [
  { value: 1, name: "# of Indoor Units" },
  { value: 2, name: "% of Indoor Units" }
];
const timeFrequencyOptions = [
  { value: "dateTime", name: "Specific Date" },
  { value: "hourTime", name: "Specific Hours" },
  { value: "hourlyTime", name: "Hourly" },
  { value: "dailyTime", name: "Daily" },
  { value: "monthlyTime", name: "Monthly" },
  { value: "weekDayTime", name: "Week Day (Day)" }
];

const timeOperators = [
  { value: ">", name: " >" },
  { value: "<", name: " <" },
  { value: "=", name: "=" },
];

const timeFrequenciesWithMultiOperators: any = {
  hourTime: true,
};

const MonthlyOptions = Array.from({ length: 28 }, (_, i) => ({ value: (i + 1).toString().padStart(2, '0'), name: (i + 1).toString() }));
const selectItemLabels: any = {
  systems: t`Select System`,
  sensors: t`Select Sensor`,
  indoors: t`Select Indoor Unit`,
  services: t`Select Indoor Unit`,
  outdoors: t`Select Outdoor Unit`,
  powerMeters: t`Select Power Meter`,
  WRCLocks:t`Unit`,
  bacNetUnits: t`Select BACnet Device`,
  "": t`Select`
};
const sensorEnums: any = {
  129: [{ value: "0", name: t`OFF` }, { value: 1, name: t`ON` }],
  5: [{ value: 1, name: t`OPEN` }, { value: "0", name: t`CLOSE` }],
  6: [{ value: 1, name: t`OPEN` }, { value: "0", name: t`CLOSE` }]
};
const savedParams: any = {};

const sortParams = (params = []) => {
  return params.sort((a, b) => a.title.toLowerCase().localeCompare(b.title.toLowerCase()));
}

const ConditionItem = (props: any) => {
  const classes = styles();
  const {
    errors,
    touched,
    name,
    setFieldValue,
    serviceParamTypes,
    lastCondition,
    index,
    condition,
    site,
    checkedConditionIds,
    customEnumOptions,
    inGroup = false,
    deleteCondition,
    disabled = false,
    duplicateCondition = null,
    duplicateGroup = null,
    getServiceParams,
    powerMeterParameters
  } = props;

  const temperatureScale = useStoreState((s) => s.users.me.temperatureScale);
  const pressureUnit = useStoreState((s) => s.users.me.measurementUnits);
  const sitesFlags = useStoreState((s) => s.sites.sitesFlags);
  const { isServiceSite = false } = sitesFlags[site?.id] || {};
  const [params, setParams] = useState<any>([]);
  const { getDurationByMin, selectableDurations } = AnomaliesUtils;
  const isPreDefined = CommonUtils.isValidDuration(condition.duration) && selectableDurations.find((element) => getDurationByMin(element.value) === getDurationByMin(condition.duration));
  const [isCustomDuration, setIsCustomDuration] = useState<boolean>(!isPreDefined);
  const { weekDays = {} } = useStoreState((state) => state.types);
  const enableBacnet = useStoreState((s) => s.users.me.enableBacnet);
  const weekDaysArr = Object.entries(weekDays).map(([k, v]) => ({ name: v, value: k }));

  const measuresMap: any = {
    "°C": temperatureScale === 2 ? "°F" : "°C",
    "kg/cm2": pressureUnit === 2 ? "PSI" : "kg/cm2",
    "MPa": pressureUnit === 2 ? "PSI" : "MPa",
    "PSI": pressureUnit === 1 ? "kg/cm2" : "PSI"
  };

  const { bacnetMeasurementUnits } = useTypes();

  const updateSavedParams = (newParams:HvacParam[]):void => {
    setParams(sortParams(newParams));
  }

  const wrcParameters: any = {
    110: {
      code: 110,
      enum: "trueFalseEnum",
      data_unit_of_measurement: "",
      title: "WRC On/Off",
      hvac_param_name: "WRC On/Off",
      max: 1,
      min: 0,
      plotable: true,
      allBrands: true,
      enabledInTriggers: true,
      showInGraph: true
    },
    111: {
      code: 111,
      enum: "trueFalseEnum",
      data_unit_of_measurement: "",
      title: "WRC Mode",
      hvac_param_name: "WRC Mode",
      max: 10,
      min: 0,
      plotable: true,
      allBrands: true,
      enabledInTriggers: true,
      showInGraph: true
    },
    112: {
      code: 112,
      enum: "trueFalseEnum",
      data_unit_of_measurement: "",
      title: "WRC Setpoint",
      hvac_param_name: "WRC Setpoint",
      max: 100,
      min: 0,
      plotable: true,
      allBrands: true,
      enabledInTriggers: true,
      showInGraph: true
    }
  }

  if (wrcParameters[condition.parameter?.code] && !condition.parameter.enum) {
    condition.parameter.enum = wrcParameters[condition.parameter.code].enum
  }

  const getParams = () => {
    const { type, brand } = condition?.itemId || {};
    if (isServiceSite && (type === 2 || type === 3)) {
      if (savedParams[type] && savedParams[type][brand]) {
        updateSavedParams(savedParams[type][brand]);
        return;
      }
      getServiceParams({ site: site?.id, brand, unitType: type }).then((res: HvacParam[]) => {
        const filteredRes = Object.values(res).filter((param: HvacParam) => !param.isCustomParam && (_.isUndefined(param.enabledInTriggers) || param.enabledInTriggers === true));
        updateSavedParams(filteredRes);
        if (!savedParams[type])
          savedParams[type] = {};
        savedParams[type][brand] = filteredRes;
      });
    }
    else if (type === 8 /*"WRCLocks"*/) {
      updateSavedParams(Object.values(wrcParameters));
    }
    else {
      updateSavedParams(condition.params);
    }
  };

  useEffect(() => {
    getParams();
  }, [props.condition.itemId]);

  if (!site) {
    return null;
  }

  const renderTimersOptions = (type: any, setFieldValue: any) => {
    switch (type) {
      case 'dateTime':
        return <DatePicker
          disableToolbar
          variant="inline"
          format="MM/dd/yyyy"
          margin="normal"
          name="value"
          label={t`Date`}
          value={moment(condition.value.split(" ")[0], "MM/DD/YYYY").toDate()}
          onChange={(d: any) => setFieldValue(`${name}[${index}].value`, condition.value.split(" ").length > 1 ? (moment(d).format("MM/DD/YYYY") + " " + condition.value.split(" ")[1]) : (moment(d).format("MM/DD/YYYY") + " 00:00"))}
          inputVariant="outlined"
          InputProps={{
            disableUnderline: true,
            style: {
              height: "36px"
            }
          }}
          style={{
            marginTop: "8px",
            marginRight: "15px"
          }}
          className={classes.width120}
        />
      case 'monthlyTime':
        return <CustomSelect
          className={classes.width130}
          error={getIn(errors, `${name}[${index}].value`) && getIn(touched, `${name}[${index}].value`)}
          placeholder="Day"
          name="value"
          value={condition.value.split(" ")[0] || MonthlyOptions[0].value}
          options={[...MonthlyOptions, { name: "end", value: "end" }]}
          onChange={(event: any) => setFieldValue(`${name}[${index}].value`, condition.value.split(" ").length > 1 ? (event.target.value + " " + condition.value.split(" ")[1]) : (event.target.value + " 00:00"))}
        />;
      case 'weekDayTime':
        return <CustomSelect
          className={classes.width130}
          error={getIn(errors, `${name}[${index}].value`) && getIn(touched, `${name}[${index}].value`)}
          placeholder="Weekday"
          name="value"
          value={condition.value.split(" ")[0] || weekDaysArr[0].value}
          options={weekDaysArr}
          onChange={(event: any) => setFieldValue(`${name}[${index}].value`, condition.value.split(" ").length > 1 ? (event.target.value + " " + condition.value.split(" ")[1]) : (event.target.value + " 00:00"))}
        />;
      default:
        return null;
    }
  }

  const handleTimeChange = (setFieldValue: any, newvalue: string) => {
    if (condition.value.split(" ").length > 1) {
      const toUpdate = condition.value.split(" ")[0] + " " + newvalue;
      setFieldValue(`${name}[${index}].value`, toUpdate)
      return;
    }
    setFieldValue(`${name}[${index}].value`, newvalue)
  }

  const getTimeValue = () => {
    if (condition.value.split(" ").length > 1) {
      return condition.value.split(" ")[1];
    }
    return condition.value;
  }

  const expandedSourceOptions = useMemo(() => {
    return enableBacnet
      ? [
          ...serviceSoruceOptions,
          { value: 'bacNetUnits', name: 'BACnet Device' },
        ]
      : serviceSoruceOptions;
  }, [enableBacnet]);

  const parameterOptions = useMemo(() => {
    let options;
    if (condition.source === 'powerMeters') {
      options = powerMeterParameters;
    } else if (condition.source === 'bacNetUnits') {
      options = site.bacNetObjectsByTemplateId[condition.itemId?.paramTemplate] ?? [];
    } else if (condition.source === 'WRCLocks') {
      options = params;
    } else {
      options = params?.filter(param => !wrcParameters[param.code])
    }

    return sortParams(options);
  }, [condition.source, condition.itemId, params]);

  const operatorOptions = useMemo(() => {
    if (condition.parameter?.enum) {
        return enumOperators
    }

    if (isServiceSite && condition.source !== 'WRCLocks' && condition.source !== 'powerMeters' && condition.source !== 'bacNetUnits') {
      return [...operators, { value: 'threshold', name: 'Difference' }];
    }

    return operators;
  }, [condition.source, condition.parameter]);

  const measurementUnit = condition.source === 'bacNetUnits'
    ? (condition.parameter?.data_unit_of_measurement
        ? bacnetMeasurementUnits[condition.parameter.data_unit_of_measurement] ?? t`unknown`
        : '')
    : measuresMap[condition.parameter?.data_unit_of_measurement] || '';

  const label = `${t`Value`}${measurementUnit ? ` (${measurementUnit})` : ''}`;
  const isLabelLong = label.length > 10;
  const shortLabel = isLabelLong ? `${label.substring(0, 10)}...` : label;

  return (
    <>
      <div className={clsx(classes.conditionContainer, { [classes.groupCondition]: inGroup })}>
        <Checkbox
          disabled={disabled}
          color="default"
          edge="end"
          variant="outlined"
          onChange={(event: any) => {
            setFieldValue("checkedConditionIds", event.target.checked ? [...checkedConditionIds, condition.key] : checkedConditionIds.filter((id: any) => id === condition.key));
          }}
          checkedIcon={<CheckboxChecked />}
          className={clsx(classes.checkboxStyle, { [classes.hideCheckbox]: inGroup })}
        />
        <CustomSelect
          className={classes.width180}
          error={getIn(errors, `${name}[${index}].source`) && getIn(touched, `${name}[${index}].source`)}
          placeholder="Select Source"
          name="source"
          options={isServiceSite ? expandedSourceOptions : soruceOptions}
          onChange={(event: any) => {
            if (condition.source) {
              setFieldValue(`${name}[${index}]`, { ...condition, source: event.target.value, itemId: "", operator: "", parameter: "" });
              return;
            }
            setFieldValue(`${name}[${index}].source`, event.target.value);
          }}
          value={condition.source}
          disabled={!site || disabled}
        />

        {(condition.source !== "time") && <CustomAutoComplete
          error={getIn(errors, `${name}[${index}].itemId`) && getIn(touched, `${name}[${index}].itemId`)}
          placeholder={selectItemLabels[condition.source]}
          value={condition.itemId}
          options={condition.source ? site[condition.source] : []}
          onChange={(event: any, value: any) => {
            if (condition.source === 'bacNetUnits') {
              setFieldValue(`${name}[${index}]`, {
                ...condition,
                itemId: value,
                parameter: '',
                object: '',
                value: '',
                operator: ''
              });

              return;
            }
            if (condition.source === "indoors" && condition.itemId && (condition.itemId.subType !== value?.subType)) {
              if (condition.parameter?.code === 50) {
                setFieldValue(`${name}[${index}]`, { ...condition, itemId: value, parameter: "", operator: "", value: "" });
              } else {
                setFieldValue(`${name}[${index}]`, { ...condition, itemId: value, value: "" });
              }
              return;
            }
            setFieldValue(`${name}[${index}].itemId`, value);
          }}
          name="itemId"
          disabled={!condition.source || !site || disabled}
        />}
        {condition.source === "time" ?
          <>
            <CustomSelect
              className={classes.width130}
              error={getIn(errors, `${name}[${index}].timeFrequency`) && getIn(touched, `${name}[${index}].timeFrequency`)}
              placeholder={t`Frequency`}
              name="Frequency"
              options={timeFrequencyOptions}
              onChange={(event: any) => {
                setFieldValue(`${name}[${index}].timeFrequency`, event.target.value);
                if (event.target.value === "dateTime") {
                  const d = new Date();
                  setFieldValue(`${name}[${index}].value`, condition.value.split(" ").length > 1 ? (moment(d).format("MM/DD/YYYY") + " " + condition.value.split(" ")[1]) : (moment(d).format("MM/DD/YYYY") + " 00:00"))
                }
                if(!timeFrequenciesWithMultiOperators[event.target.value])
                  setFieldValue(`${name}[${index}].operator`, "")
              }}
              value={condition.timeFrequency}
              disabled={!condition.source || !site || disabled}
            />
            {/*only show operator field here if selected time frequency support multi operators*/}
            {!!timeFrequenciesWithMultiOperators[condition.timeFrequency] && <CustomSelect
              className={classes.width130}
              error={getIn(errors, `${name}[${index}].operator`) && getIn(touched, `${name}[${index}].operator`)}
              placeholder={t`Operator`}
              name="Operator"
              options={timeOperators}
              onChange={(event: any) => {
                setFieldValue(`${name}[${index}].operator`, event.target.value);
              }}
              value={condition.operator}
              disabled={!condition.source || !condition.timeFrequency || !site || disabled}
            />}
            {renderTimersOptions(condition.timeFrequency, setFieldValue)}
            {condition.timeFrequency !== 'hourlyTime' && <CustomedTextField
              className={classes.width130}
              error={!CommonUtils.isValidTime(getTimeValue()) || (getIn(errors, `${name}[${index}].value`) && getIn(touched, `${name}[${index}].value`))}
              placeholder={t`hh:mm`}
              name={`${name}[${index}].value`}
              onChange={(event: any) => handleTimeChange(setFieldValue, event.target.value)}
              value={getTimeValue()}
              label={t`Time`}
              optionValue="value"
              disabled={disabled}
              maxLength={5}
            />}
          </>
          :
          <>
            {condition.source === "systems" &&
              <>
                <CustomSelect
                  className={classes.width180}
                  error={getIn(errors, `${name}[${index}].unitsNumType`) && getIn(touched, `${name}[${index}].unitsNumType`)}
                  placeholder="Select # of units"
                  name="unitsNumType"
                  options={numOfUnitsOptions}
                  onChange={(event: any) => {
                    const { target: { value } } = event;
                    setFieldValue(`${name}[${index}].unitsNumType`, value);
                  }}
                  value={condition.unitsNumType}
                  disabled={!site || !condition.itemId || disabled}
                />
                <div style={{ position: "relative" }}>
                  <CustomedTextField
                    className={classes.width130}
                    error={getIn(errors, `${name}[${index}].unitsNum`) && getIn(touched, `${name}[${index}].unitsNum`)}
                    value={condition.unitsNum}
                    onChange={(event: any) => setFieldValue(`${name}[${index}].unitsNum`, event.target.value)}
                    name="unitsNum"
                    type="number"
                    disabled={!condition.unitsNumType || !site || disabled}
                    label={t`Value` + `${condition.unitsNumType % 2 === 0 ? " (%)" : " (#)"}`}
                  />
                  {(getIn(errors, `${name}[${index}].unitsNum`) && getIn(touched, `${name}[${index}].unitsNum`) && getIn(errors, `${name}[${index}].unitsNum`) !== t`Required`) && <Typography className={classes.errorText}>{getIn(errors, `${name}[${index}].unitsNum`)}</Typography>}
                </div>
              </>
            }
            {condition.source !== "sensors" && <CustomAutoComplete
              error={getIn(errors, `${name}[${index}].parameter`) && getIn(touched, `${name}[${index}].parameter`)}
              placeholder={"Parameter"}
              onChange={(event: any, value: any) => {
                setFieldValue(`${name}[${index}]`, { ...condition, parameter: value, value: "", operator: "" });
              }}
              value={condition.parameter}
              label="title"
              optionValue="code"
              options={parameterOptions}
              name="parameter"
              disabled={!site || disabled || (condition.source === "systems" ? !condition.unitsNumType : !condition.itemId)}
              filters={condition.source === "systems" ? { key: "code", value: 109 } : condition.itemId?.subType === 2 ? { key: "code", value: 50 } : {}}
            />}

            <CustomSelect
              className={classes.width130}
              error={getIn(errors, `${name}[${index}].operator`) && getIn(touched, `${name}[${index}].operator`)}
              placeholder={t`Operator`}
              name="operator"
              options={operatorOptions}
              onChange={(event: any) => setFieldValue(`${name}[${index}].operator`, event.target.value)}
              value={condition.operator}
              disabled={!site || disabled || (condition.source === "sensors" ? !condition.itemId : !condition.parameter)}
            />
            {condition.operator === "threshold" && <>
              <CustomAutoComplete
                error={getIn(errors, `${name}[${index}].parameter2`) && getIn(touched, `${name}[${index}].parameter2`)}
                placeholder={"Parameter 2"}
                onChange={(event: any, value: any) => {
                  setFieldValue(`${name}[${index}]`, { ...condition, parameter2: value, value: "", thresholdOperator: "" });
                }}
                value={condition.parameter2}
                label="title"
                optionValue="code"
                // options={params}
                options={params?.filter((param: any) => condition.source === "WRCLocks" || !wrcParameters[param.code])}
                name="parameter2"
                disabled={!site || disabled || (condition.source === "systems" ? !condition.unitsNumType : !condition.itemId)}
                filters={condition.source === "systems" ? { key: "code", value: 109 } : condition.itemId?.subType === 2 ? { key: "code", value: 50 } : {}}
              />
              <CustomSelect
                className={classes.width140}
                error={getIn(errors, `${name}[${index}].thresholdOperator`) && getIn(touched, `${name}[${index}].thresholdOperator`)}
                placeholder={t`Difference`}
                name="thresholdOperator"
                options={theresholdOptions}
                onChange={(event: any) => setFieldValue(`${name}[${index}].thresholdOperator`, event.target.value)}
                value={condition.thresholdOperator}
                disabled={!site || disabled || (condition.source === "sensors" ? !condition.itemId : !condition.parameter)}
              />
            </>}
            {condition.source === "sensors" ?
              <>
                {sensorEnums[condition.itemId?.type] ?
                  <CustomSelect
                    className={classes.width130}
                    error={getIn(errors, `${name}[${index}].value`) && getIn(touched, `${name}[${index}].value`)}
                    placeholder="Value"
                    name="value"
                    value={condition.value}
                    onChange={(event: any) => setFieldValue(`${name}[${index}].value`, event.target.value)}
                    options={sensorEnums[condition.itemId?.type]}
                    disabled={disabled}
                  /> :
                  <CustomedTextField
                    className={classes.width130}
                    error={getIn(errors, `${name}[${index}].value`) && getIn(touched, `${name}[${index}].value`)}
                    onChange={(event: any) => setFieldValue(`${name}[${index}].value`, event.target.value)}
                    name="value"
                    type="number"
                    value={condition.value}
                    label={t`Value` + (condition.itemId?.type === 1 ? `${temperatureScale === 2 ? "°F" : "°C"}` : "")}
                    disabled={disabled}
                  />}
              </> :
              condition.parameter?.enum ?
                <CustomSelect
                  className={classes.width130}
                  error={getIn(errors, `${name}[${index}].value`) && getIn(touched, `${name}[${index}].value`)}
                  placeholder="Value"
                  name="value"
                  value={condition.value}
                  options={getCustomOptions(condition.source, condition.parameter.enum, condition.itemId.subType, serviceParamTypes, customEnumOptions)}
                  onChange={(event: any) => setFieldValue(`${name}[${index}].value`, event.target.value)}
                  disabled={!site || !condition.operator || disabled}
                /> :
                <Tooltip title={label} placement='bottom' disableTooltip={!isLabelLong}>
                  <span>
                    <CustomedTextField
                      className={classes.width130}
                      error={getIn(errors, `${name}[${index}].value`) && getIn(touched, `${name}[${index}].value`)}
                      onChange={(event: any) => setFieldValue(`${name}[${index}].value`, event.target.value)}
                      value={condition.value}
                      name='value'
                      type='number'
                      label={shortLabel}
                      disabled={!site || !condition.operator || disabled}
                    />
                  </span>
                </Tooltip>
            }
            <CustomAutoComplete
              disabled={disabled}
              error={getIn(errors, `${name}[${index}].duration`) && getIn(touched, `${name}[${index}].duration`)}
              placeholder="Duration"
              onChange={(event: any, value1: any) => {
                const value = value1?.value || "";
                setIsCustomDuration(value === "00:00");
                setFieldValue(`${name}[${index}].duration`, value);
              }}
              value={isPreDefined ? isPreDefined : { title: t`Custom`, value: "00:00" }}
              label="title"
              optionValue="value"
              options={[{
                title: t`Custom`,
                value: "00:00"
              }, ...selectableDurations]}
              name={`${name}[${index}].duration`}
            />

            {isCustomDuration && <CustomedTextField
              className={classes.width130}
              error={!CommonUtils.isValidDuration(condition.duration) || (getIn(errors, `${name}[${index}].duration`) && getIn(touched, `${name}[${index}].duration`))}
              placeholder={t`hh:mm`}
              name={`${name}[${index}].duration`}
              onChange={(event: any) => setFieldValue(`${name}[${index}].duration`, event.target.value)}
              value={condition.duration}
              label={t`Duration`}
              optionValue="value"
              disabled={disabled}
              maxLength={5}
            />}
          </>}
        <div className={classes.buttonsContainer}>
          {!!duplicateCondition && !inGroup && <Tooltip title={t`Duplicate condition`} >
            <Button
              disabled={disabled}
              onClick={duplicateCondition}
              variant="text"
              size="small"
              className={classes.duplicateButton}
            >
              {t`Duplicate`}
            </Button>
          </Tooltip>
          }
          <IconButton
            disabled={disabled}
            className={clsx(classes.iconBtnStyle, { [classes.deleteIconNotInGroup]: !inGroup })}
          >
            <Delete style={{ width: 20, height: 20 }} onClick={deleteCondition} />
          </IconButton>
        </div>
      </div>
      {!lastCondition && <div className={classes.GbuttonContainer}>
        <GButton
          disabled={disabled}
          selected={condition.relation}
          onClick={(text: any) => setFieldValue(`${name}[${index}].relation`, text)}
        />
        {!!duplicateGroup && inGroup && index === 0 && <Tooltip title={t`Duplicate group`} >
          <Button
            disabled={disabled}
            onClick={duplicateGroup}
            variant="text"
            size="small"
            style={{ marginRight: 30 }}
            className={classes.duplicateButton}
          >
            {t`Duplicate`}
          </Button>
        </Tooltip>
        }
      </div>
      }
    </>
  );
};
export default ConditionItem;
