import Grid from "@material-ui/core/Grid";
import { includes, isEmpty, isUndefined } from "lodash";
import moment from "moment";
import "moment-timezone";
import React, { useCallback, useEffect, useState } from "react";
import { t } from "ttag";
import { useStoreActions, useStoreState } from "../../models/RootStore";
import ErrorBox from "../WarningBox/ErrorBox";
import UnitStatsGraph from "./UnitStatsGraph";
import UnitStatsSlider from "./UnitStatsSlider";
import UnitStatsTable from "./UnitStatsTable";
import useCoolPoll from "@hooks/useCoolPoll";
import { formatToOneDecimal } from "@services/utils";

const MAX_ALLOWED_GAP_PRO = 1000 * 60 * 20; // (20 mins)
const AUTOUPDATE_INTERVAL = 1000 * 60; // one minute
const MAX_ALLOWED_STATS_SELECTIONS = 6;
const paramsColorsSet: any = ["#7a6095", "#35a8e0", "#00b259", "#ef3b2f", "#f8b133", "#7f7182"];

const UnitStats: React.FC<any> = ({ bacnetUnit, site, type, minDate }) => {
  const getBacnetUnitsStats = useStoreActions((a) => a.bacnetDevices.getBacnetUnitsStats);
  const getBacnetParameterTemplateById = useStoreActions((a) => a.bacnetDevices.getBacnetParameterTemplateById);
  const { addMessage } = useStoreActions((action) => action.errorMessage);
  const temperatureScale = useStoreState((s) => s.users.me.temperatureScale || 0);
  const { dateFormat, timeFormat } = useStoreState((state) => state.users);
  const [isLoading, setIsLoading] = useState(true);
  const [statDataCollection, setStatDataCollection] = useState<any>([]);
  const [autoupdate, setAutoupdate] = useState(false);
  const [paramsTable, setParamsTable] = useState<any>({});
  const [updateTime, setUpdateTime] = useState<any>("");
  const [unitFetchedData, setUnitFetchedData] = useState<any>({});
  const [paramsColors, setParamsColors] = useState<any>(new Map());
  const [colorSet, setColorSet] = useState<any>(paramsColorsSet);
  const [selectedStartTime, setSelectedStartTime] = useState<any>(new Date().getTime() - 1 * 24 * 60 * 60 * 1000); //defaultDatePast);
  const [selectedEndTime, setSelectedEndTime] = useState<any>(new Date().getTime()); //defaultDateNow);
  const [showAutoUpdateWarning, handleShowAutoUpdateWarning] = useState<boolean>(false);
  const [disableUpdateBtn, setDisableUpdateBtn] = useState<boolean>(false);
  const [counter, setCounter] = useState<number>(0);
  const dateTimeFormat = `${dateFormat} ${timeFormat}`;
  const timezone = site?.timezone || moment.tz.guess();
  const serviceParams = useStoreState((s) => s.serviceParams);
  const [unitParamTemplate, setUnitParamTemplate] = useState<any>({});
  const { bacnetMeasurementUnitEnums } = useStoreState((state) => state.types);
  const userPreferences = useStoreState((state) => state.users.userPreferences);
  const updateUserPreferences = useStoreActions((actions) => actions.users.updateUserPreferences);

  const prepareUserPrefData = () => {
    if (isEmpty(paramsTable)) {
      return;
    }
    const showingParams = Object.values(paramsTable)
      .filter((row: any) => row?.isShowing || row?.isChecked)
      .reduce((acc: Record<string, { isShowing: boolean; isChecked: boolean }>, row: any) => {
        acc[row.code] = {
          isShowing: row.isShowing,
          isChecked: row.isChecked,
        };
        return acc;
      }, {});
  
    let userPrefObj = userPreferences?.bacnetPreferdParams || {};
    userPrefObj = {
      ...userPrefObj,
      [bacnetUnit.id]: showingParams,
    };
    updateUserPreferences({ bacnetPreferdParams: userPrefObj });
  };

  useEffect(() => {
    if (isLoading) {
      return;
    }
    prepareUserPrefData();
  }, [paramsTable]);

  useEffect(() => {
    setColorSet([...paramsColorsSet]);
  }, [bacnetUnit.site, type]);

  const prepareGraphsData = (statsData: any) => {
    const statDataCollectionTemp: any = [];

    statDataCollectionTemp.push({
      timestamp: selectedStartTime,
      noData: 0.5
    });

    statsData.forEach((statPoint: any, statPointIndex: any) => {
      let point = { ...statPoint, noData: 0.5 };

      statDataCollectionTemp.push({
        ...point
      });

      if (
        statsData[statPointIndex + 1] &&
        statsData[statPointIndex + 1].timestamp >= statPoint.timestamp + MAX_ALLOWED_GAP_PRO
      ) {
        statDataCollectionTemp.push({
          timestamp: statPoint.timestamp + 2,
          noData: 0.5
        });
      }

    });

    statDataCollectionTemp.push({
      timestamp: selectedEndTime,
      noData: 0.5
    });

    setStatDataCollection(statDataCollectionTemp);
  };

  const fetchStatsData = async (id: string, startTime: number, endTime: number) => {
    setIsLoading(true);

    try {
      const [statsRes, paramsRes] = await Promise.all([
        getBacnetUnitsStats({ bacnetUnitId: id, startTime, endTime }),
        getBacnetParameterTemplateById(bacnetUnit.paramTemplate),
      ]);
      setUnitParamTemplate(paramsRes);
      setUnitFetchedData(statsRes);
      prepareGraphsData(statsRes.entries);
      prepareTableData(statsRes, paramsRes);
    } catch (err: any) {
      addMessage({ message: err.message });
    } finally {
      setIsLoading(false);
      if (autoupdate) {
        if (counter === 59) {
          setAutoupdate(false);
          handleShowAutoUpdateWarning(true);
          setCounter(0);
        } else {
          setCounter((prevCounter) => prevCounter + 1);
        }
      }
    }
  };

  useEffect(() => {
    if (!bacnetUnit || !selectedStartTime || !selectedEndTime) {
      return;
    }
    fetchStatsData(bacnetUnit.id, selectedStartTime, selectedEndTime);
  }, [bacnetUnit, selectedStartTime, selectedEndTime, temperatureScale]);

  const onRefresh = useCallback(() => {
    if (!selectedStartTime) {
      return;
    }

    onDateRangeChange({ startDate: new Date(moment(selectedStartTime).tz(timezone).format("llll")), endDate: new Date(moment().tz(timezone).format("llll")) }, true);
  }, [selectedStartTime]);

  const isToday = useCallback((date: any) => {
    const today = new Date(moment().tz(timezone).format("llll"));
    return date.getDate() === today.getDate() &&
      date.getMonth() === today.getMonth() &&
      date.getFullYear() === today.getFullYear();
  }, []);

  const getParamName = useCallback(
    (unitParamTemplate, code) => {
      if (!unitParamTemplate?.data) return code;
      for (let key in unitParamTemplate.data) {
        if (unitParamTemplate.data[key].shortId === code.split("/")[0]) {
          return unitParamTemplate.data[key].name;
        }
      }
      return code;
    },
    []
  );

  const getMeasurementUnits = useCallback(
    (unitParamTemplate, code) => {
      if (!unitParamTemplate?.data) return code;
      for (let key in unitParamTemplate.data) {
        if (unitParamTemplate.data[key].shortId === code.split("/")[0]) {
          return bacnetMeasurementUnitEnums?.[unitParamTemplate.data[key].units];
        }
      }
      return '';
    },
    []
  );

  const onDateRangeChange = useCallback(async (
    dateRange: {
      startDate?: Date | undefined;
      endDate?: Date | undefined;
    },
    skipDateFrameChecking = false) => {
    if (!isUndefined(dateRange.startDate) && !isUndefined(dateRange.endDate)) {
      const timezoneOffset = moment().tz(timezone).utcOffset() * 60 * 1000;
      const checkIsToday = isToday(dateRange.endDate);
      const currentHourMinsArray = moment().tz(timezone).format("HH:mm").split(":");
      const startHoursArray = moment(dateRange.startDate).format("HH:mm").split(":");
      const startTime = Date.UTC(dateRange.startDate.getFullYear(),
        dateRange.startDate.getMonth(), dateRange.startDate.getDate(), +startHoursArray[0], +startHoursArray[1]) - timezoneOffset;
      const endTime = checkIsToday ? Date.UTC(dateRange.endDate.getFullYear(),
        dateRange.endDate.getMonth(), dateRange.endDate.getDate(), +currentHourMinsArray[0], +currentHourMinsArray[1], 0) - timezoneOffset :
        Date.UTC(dateRange.endDate.getFullYear(),
          dateRange.endDate.getMonth(), dateRange.endDate.getDate(), 23, 59, 59) - timezoneOffset;

      if (!isToday(new Date(moment(endTime).tz(timezone).format("llll"))) || (!skipDateFrameChecking && (endTime - startTime > 86400000))) {
        autoupdate && setAutoupdate(false);
        !disableUpdateBtn && setDisableUpdateBtn(true);
        counter && setCounter(0);
      } else {
        disableUpdateBtn && setDisableUpdateBtn(false);
      }

      setSelectedStartTime(startTime);
      setSelectedEndTime(endTime);
    }
  }, [autoupdate, disableUpdateBtn, counter]);

  const exportFile = useCallback(async (onlySelected?: boolean) => {
    const checkedKeys = Object.keys(paramsTable).filter(key => paramsTable[key].isChecked);
    const { title = "" } = bacnetUnit || {};
    // Extract the codes dynamically and replace them with meaningful names
    const dataHeaders = onlySelected ? checkedKeys : Object.keys(unitFetchedData?.entries?.[0] || {}).filter(key =>
      key !== "timestamp" && key !== "day" && key !== "unit_uid" && key !== "device_serial"
    );
    // Generate meaningful headers using getParamName
    const headers = [
      "Date/Time",
      "BACnet Device Name",
      ...dataHeaders.map(code => getParamName(unitParamTemplate, code))
    ];
    let rows = headers.join(",") + "\r\n";

    (unitFetchedData?.entries || []).forEach((dataRow: any) => {
      const { timestamp, day, unit_uid, device_serial, ...readings } = dataRow;
      const date = moment(timestamp).tz(timezone).format(`${dateTimeFormat}`);
      const values = dataHeaders.map(code => readings[code]).join(",");
      rows += `${date},${title},${values}` + "\r\n";
    });

    let link = window.document.createElement("a");
    link.setAttribute("href", "data:text/csv;charset=utf-8,%EF%BB%BF" + encodeURI(rows));
    link.setAttribute("download", `${title}.csv`);
    link.click();
  }, [unitFetchedData, paramsColors, paramsTable, unitParamTemplate, getParamName]);

  useCoolPoll({
    frequency: AUTOUPDATE_INTERVAL,
    enabled: autoupdate && !!selectedStartTime,
    onPoll: () => {
      onRefresh();
    },
  });
  
  const showHideAllParams = useCallback((isChecked: boolean) => {
    const rows = { ...paramsTable };

    Object.keys(rows).forEach((key: any) => {
      const obj = rows[key];
      obj.isShowing = isChecked;
      if (!isChecked) {
        obj.isChecked = false;
      }
    });

    let colors = colorSet;
    if (!isChecked) {
      colors = [...paramsColorsSet];
    }
    Array.from(paramsColors, ([id]) => {
      if (rows[id]) {
        if (!rows[id].isChecked) {
          paramsColors.delete(id);
        }
      }
    });

    setParamsColors(new Map(paramsColors));
    setColorSet(colors);
    setParamsTable(rows);
  }, [paramsColors, colorSet, paramsTable]);

  const showHideParam = useCallback((code: any) => {
    const oldVal = !!paramsTable[code]?.isShowing;
    if (paramsColors.has(code)) {
      setColorSet([...colorSet, paramsColors.get(code)]);
      paramsColors.delete(code);
      setParamsColors(paramsColors);
    }
    setParamsTable({ ...paramsTable, [code]: { ...paramsTable[code], isShowing: !oldVal, isChecked: false } });
  }, [paramsColors, colorSet, paramsTable]);

  const prepareTableData = async (data: any, unitParamTemplate: any) => {
    const currentObj = data.current;
    if (isEmpty(currentObj)) {
      return;
    }
    const { timestamp } = currentObj[0]
    const rangesObj = data.ranges;
    const selectedStats: any = userPreferences.bacnetPreferdParams || {};
    const newSelected: any = new Map();
    const colorSetTemp = [...paramsColorsSet];
    const tableRows: any = {};
    const unitSupportedParams = !rangesObj ? null : Object.keys(rangesObj);

    if (data && !isEmpty(serviceParams) && unitSupportedParams && currentObj) {
      unitSupportedParams && !isEmpty(unitSupportedParams) && unitSupportedParams.forEach((code: any) => {
        const ranges = rangesObj[code];
        const {
          value,
          min = 0,
          max = 0
        } = ranges;
        const currentValue = currentObj[0]?.jdata?.[code];
        let isChecked = false;
        let isShowing = false;

        if ((selectedStats[bacnetUnit.id]?.[code] && newSelected.size < MAX_ALLOWED_STATS_SELECTIONS)) {
          isShowing = selectedStats[bacnetUnit.id]?.[code]?.isShowing;
          isChecked = selectedStats[bacnetUnit.id]?.[code]?.isChecked;
          newSelected.set(code, colorSetTemp.pop());
        }
        
        const slider = !isEmpty(ranges) ? <UnitStatsSlider data={{
          code,
          slider: {
            min: formatToOneDecimal(min),
            max: formatToOneDecimal(max),
            selectionMin: formatToOneDecimal(min),
            selectionMax: formatToOneDecimal(max),
            value: formatToOneDecimal(value),
          },
          value: formatToOneDecimal(currentValue),
          name: code
        }} index={`slider${code}`} /> : null;

        tableRows[code] = {
          code,
          name: getParamName(unitParamTemplate, code),
          measurementUnits: getMeasurementUnits(unitParamTemplate, code),
          value: formatToOneDecimal(value),
          slider,
          plottable: true,
          isShowing,
          isChecked,
        };
      });
    }
    setParamsTable({ ...tableRows });
    setParamsColors(newSelected);
    setUpdateTime(timestamp);
    setColorSet(colorSetTemp);
  };


  useEffect(() => {
    const usedColors = Object.values(Object.fromEntries(paramsColors));
    const unUsedColors = paramsColorsSet.filter((color: any) => usedColors.indexOf(color) === -1);
    setColorSet(unUsedColors);
  }, [paramsColors]);

  const updateParamRow = useCallback((code: any) => {
    const oldVal = paramsTable[code].isChecked;
    if (!oldVal && paramsColors.size >= MAX_ALLOWED_STATS_SELECTIONS) {
      return;
    }
    if (!oldVal) {
      const colorSetTemp = colorSet;
      const newColor = colorSetTemp.pop();
      paramsColors.set(code, newColor);

      setColorSet(colorSetTemp);
    } else {
      setColorSet([...colorSet, paramsColors.get(code)]);
      paramsColors.delete(code);
    }
    setParamsColors(new Map(paramsColors));
    setParamsTable({ ...paramsTable, [code]: { ...paramsTable[code], isChecked: !oldVal } });
  }, [paramsTable, colorSet, paramsColors]);

  return (
    <Grid container spacing={0} style={{ padding: "16px 0" }}>
      <Grid item xs={5}>
        <UnitStatsTable
          paramsTable={paramsTable}
          endTime={selectedEndTime}
          isToday={isToday}
          onRefresh={onRefresh}
          isLoading={isLoading}
          updateTime={updateTime}
          autoupdate={autoupdate}
          setAutoupdate={setAutoupdate}
          timezone={timezone}
          updateParamRow={updateParamRow}
          showHideAllParams={showHideAllParams}
          showHideParam={showHideParam}
          disableUpdateBtn={disableUpdateBtn}
        />
      </Grid>
      <Grid item={true} xs={7}>
        <UnitStatsGraph
          isToday={isToday}
          startTime={selectedStartTime}
          endTime={selectedEndTime}
          unitStatsData={bacnetUnit.id === "all" ? statDataCollection : Object.values(statDataCollection)}
          onDateRangeChange={onDateRangeChange}
          onRefresh={onRefresh}
          isLoading={isLoading}
          exportFile={exportFile}
          enums={bacnetUnit?.enums}
          timezone={timezone}
          dateFormat={dateFormat}
          timeFormat={timeFormat}
          hideLegends={isEmpty(paramsTable)}
          paramName={t`Value`}
          paramsColors={paramsColors}
          paramsTable={paramsTable}
          onChipDelete={updateParamRow}
          minDate={minDate}
        />
      </Grid>
      {showAutoUpdateWarning && <ErrorBox
        title={t`Confirm`}
        error={"Auto Update has stopped. Do you want to proceed?"}
        onAccept={() => { handleShowAutoUpdateWarning(false); setAutoupdate(true); }}
        onClose={() => handleShowAutoUpdateWarning(false)}
        acceptTitle={t`Yes`}
        cancelTitle={t`No`}
      />
      }
    </Grid>
  );
};

export default UnitStats;
