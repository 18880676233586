import { useStoreState } from '@models/RootStore';

/**
 * @typedef {Object} Types
 * @property {Object.<string, string>} temperatureScale - Mapping of temperature scale codes to their names.
 * @property {Object.<string, string>} pressureScale - Mapping of pressure scale codes to their names.
 * @property {Object.<string, string>} operationStatuses - Mapping of operation status codes to status names.
 * @property {Object.<string, {
*   temperatureLimits: Object.<string, number[]>,
*   temperatureColorRange: number[]
* }>} operationModesWithTemperatures - Operation modes with temperature limits and color ranges.
* @property {Object.<string, string>} fanModes - Mapping of fan mode codes to fan mode names.
* @property {Object.<string, string>} curveTempModes - Mapping of curve temperature mode codes to their names.
* @property {Object.<string, string>} swingModes - Mapping of swing mode codes to swing mode names.
* @property {Object.<string, string>} operationModes - Mapping of operation mode codes to mode names.
* @property {Object.<string, string>} fanModesExtended - Extended mapping of fan mode codes to names.
* @property {Object.<string, string>} operationModesExtended - Extended mapping of operation mode codes to names.
* @property {Object.<string, string>} weekDays - Names of the days of the week.
* @property {Object.<string, number>} locks - Mapping of lock types to their numeric codes.
* @property {Object.<string, number>} unitTypes - Mapping of unit types to their numeric codes.
* @property {Object.<string, string>} unitSubTypes - Mapping of unit subtypes to subtype names.
* @property {Object.<string, {
*   name: string,
*   enableMinMaxSelection: boolean,
*   enableMeasurementUnitSelection: boolean,
*   enableNormalModeSelection: boolean,
*   enableView: boolean,
*   measurementUnits?: number,
*   valueMin?: number,
*   valueMax?: number
* }>} sensorTypes - Mapping of sensor type codes to their properties.
* @property {Object.<string, {
*   name: string,
*   enableSelection: boolean
* }>} sensorMeasurementUnits - Mapping of measurement unit codes to their properties.
* @property {Object.<string, number>} outdoorUnitTasks - Mapping of outdoor unit task types to their numeric codes.
* @property {string[]} systemTypes - Array of system type names.
* @property {Array.<{
*   value: number,
*   type: string,
*   name: string,
*   isWaterHeater: boolean,
*   hasBooster: boolean,
*   notes?: string
* }>} hvacBrands - Array of HVAC brand details.
* @property {Object.<string, Object.<string, { name: string }>>} hvacBrandsSubTypes - Mapping of HVAC brand codes to subtypes and their names.
* @property {Object.<string, number>} proParsingCodes - Mapping of parsing code names to their numeric codes.
* @property {Object.<string, number>} capacityMeasurementUnitTypes - Mapping of capacity measurement unit types to their numeric codes.
* @property {Object.<string, number>} actionSources - Mapping of action source names to their numeric codes.
* @property {Object.<string, { value: number, text: string }>} billingModels - Mapping of billing model names to their values and descriptions.
* @property {Object.<string, { value: number, text: string }>} customerStatuses - Mapping of customer status names to their values and descriptions.
* @property {Object.<string, { value: number, text: string }>} creationMethods - Mapping of creation method names to their values and descriptions.
* @property {Object.<string, number>} trapTypes - Mapping of trap type names to their numeric codes.
* @property {Object.<string, number>} eventTypes - Mapping of event type names to their numeric codes.
* @property {Object.<string, string>} eventTypeStrings - Mapping of event type codes to their string representations.
* @property {Object.<string, number>} eventStatusTypes - Mapping of event status names to their numeric codes.
* @property {Object.<string, number>} eventClearTypes - Mapping of event clear type names to their numeric codes.
* @property {Object.<string, number>} ppdResolution - Mapping of PPD resolution types to their numeric codes.
* @property {Object.<string, number>} measurementUnitTypes - Mapping of measurement unit types to their numeric codes.
* @property {Object.<string, string>} languages - Mapping of language codes to language names.
* @property {Object.<string, number>} serviceParamValueTypes - Mapping of service parameter value types to their numeric codes.
* @property {Object.<string, number>} procedureRunningModes - Mapping of procedure running modes to their numeric codes.
* @property {Object.<string, number>} procedureRunningStates - Mapping of procedure running states to their numeric codes.
* @property {Object.<string, number>} procedureStateCommands - Mapping of procedure state commands to their numeric codes.
* @property {Object.<string, number>} procedureStepTypes - Mapping of procedure step types to their numeric codes.
* @property {Object.<string, number>} procedureDeviceCommands - Mapping of procedure device commands to their numeric codes.
* @property {Object.<string, number>} procedureConditions - Mapping of procedure conditions to their numeric codes.
* @property {Object.<string, number>} procedureStepResultTypes - Mapping of procedure step result types to their numeric codes.
* @property {Object.<string, number>} procedureRunResultTypes - Mapping of procedure run result types to their numeric codes.
* @property {Object.<string, string>} calculatedMode - Mapping of calculated mode codes to mode names.
* @property {Object.<string, number>} applications - Mapping of application types to their numeric codes.
* @property {Array.<{ name: string, text: string, value: number }>} timeFormat - Array of time format options.
* @property {Array.<{ name: string, text: string, value: number }>} dateFormat - Array of date format options.
* @property {Object.<string, { value: number, text: string }>} endActionTypes - Mapping of end action types to their values and descriptions.
* @property {Object.<string, number>} siteStatuses - Mapping of site status names to their numeric codes.
* @property {Object.<string, number>} devicePackageStatuses - Mapping of device package statuses to their numeric codes.
* @property {Object.<string, { name: string, shortName: string }>} deviceTypes - Mapping of device type codes to their names and short names.
* @property {Object.<string, number>} scheduledReportTypes - Mapping of scheduled report types to their numeric codes.
* @property {Object.<string, number>} powerMeterTypes - Mapping of power meter types to their numeric codes.
* @property {Object.<string, number>} customParameterTypes - Mapping of custom parameter types to their numeric codes.
* @property {Object.<string, number>} customParameterOperations - Mapping of custom parameter operations to their numeric codes.
* @property {Object.<string, string>} waterHeaterModes - Mapping of water heater mode codes to mode names.
* @property {Object.<string, string>} waterHeaterOperationStatusesEnum - Mapping of water heater operation status codes to status names.
* @property {Object.<string, number>} schedulePatterns - Mapping of schedule pattern types to their numeric codes.
* @property {Object.<string, number>} changeoverTypes - Mapping of changeover types to their numeric codes.
* @property {Object.<string, number>} entityTypes - Mapping of entity types to their numeric codes.
* @property {Object.<string, string>} trapTelemetryCodes - Mapping of trap telemetry codes to their string representations.
* @property {Object.<string, { value: number, text: string }>} customerLevelPackagesEnum - Mapping of customer level package types to their values and descriptions.
* @property {Object.<string, number>} regionTypes - Mapping of region types to their numeric codes.
* @property {Object.<string, number>} redirectPage - Mapping of redirect page names to their numeric codes.
* @property {Object.<string, string>} trapPriorityTypes - Mapping of trap priority types to their names.
* @property {Object.<string, number>} sitePackageEnums - Mapping of site package types to their numeric codes.
* @property {Object.<string, string>} sitePackagesFlagsEnums - Mapping of site package flag codes to their names.
* @property {Object.<string, string>} sitePackagesNamesEnums - Mapping of site package codes to their names.
* @property {Object.<string, string>} siteSubscriptionStatusEnums - Mapping of site subscription status codes to their names.
* @property {Object.<string, number>} deviceBrands - Mapping of device brand codes to their numeric codes.
* @property {Object.<string, number>} ppdReportFormatTypes - Mapping of PPD report format types to their numeric codes.
* @property {Object.<string, {
*   enableWrcSettingsOnOffLock: boolean,
*   enableWrcSettingsSetpointChangeLock: boolean,
*   enableWrcSettingsModeLock: boolean,
*   enableWrcSettingsLimitCoolingSetpoint: boolean,
*   enableWrcSettingsLimitHeatingSetpoint: boolean,
*   enableWrcSettingsRestrictionsLock: boolean,
*   enableWrcSettingsFanModeOnOffLock: boolean,
*   enableAppSettingsOnOffLock: boolean,
*   enableAppSettingsSetpointChangeLock: boolean,
*   enableAppSettingsModeLock: boolean,
*   enableAppSettingsModeControl: boolean,
*   enableAppSettingsFanControl: boolean,
*   enableAppSettingsSwingControl: boolean,
*   enableAppSettingsLimitCoolingSetpoint: boolean,
*   enableAppSettingsLimitHeatingSetpoint: boolean,
*   enableAppSettingsLimitAutoSetpoint: boolean,
*   enableAppSettingsSetpointControlResolution: boolean,
*   enableAppSettingsTimer: boolean,
*   enableAppSettingsFanModeOnOffLock: boolean,
*   enableOperationStatusSelection: boolean,
*   enableOperationModeSelection: boolean,
*   enableFanModeSelection: boolean,
*   enableSwingModeSelection: boolean,
*   enableSetpointControl: boolean,
*   enableAmbientTemperature: boolean,
*   enableHeaterTankSPControl: boolean,
*   enableHeaterBoosterStatusSelection: boolean,
*   enableHeaterTankTemp: boolean,
*   enablefanModeOnOffSelection: boolean,
*   enableCurveTempSelection?: boolean,
*   setpointControlModes: Object.<string, boolean>
* }>} unitSettingsProps - Mapping of unit settings properties by unit type.
* @property {Object.<string, number[]>} subTypesFanModes - Mapping of unit subtypes to available fan mode codes.
* @property {Object.<string, number[]>} subTypesOperationModes - Mapping of unit subtypes to available operation mode codes.
* @property {Object.<string, number[]>} subTypesSwingModes - Mapping of unit subtypes to available swing mode codes.
* @property {number[]} defaultOperationModes - Default operation mode codes.
* @property {number[]} defaultFanModes - Default fan mode codes.
* @property {number[]} defaultSwingModes - Default swing mode codes.
* @property {Object.<string, string>} eventTypesToSeverityMapping - Mapping of event types to severity levels.
* @property {Object.<string, number>} rulesTypes - Mapping of rules types to their numeric codes.
* @property {Object.<string, number>} alertGroupTypes - Mapping of alert group types to their numeric codes.
* @property {Object.<string, Object.<string, number[]>>} defaultUnitTempLimits - Default temperature limits for units.
* @property {Object.<string, Object.<string, number[]>>} unitTempLimitsObjBySubType - Temperature limits by unit subtype.
* @property {Object.<string, Object.<string, Object.<string, number[]>>>} unitTempLimitsBySubType - Temperature limits by unit subtype and mode.
* @property {Object.<string, string>} scheduletypes - Mapping of schedule type codes to their names.
* @property {Object.<string, number>} siteCategories - Mapping of site categories to their numeric codes.
* @property {Object.<string, number>} siteOnboardingStatus - Mapping of site onboarding statuses to their numeric codes.
* @property {Object.<string, number>} sitePhases - Mapping of site phases to their numeric codes.
* @property {Object.<string, number>} invoicingPeriods - Mapping of invoicing periods to their numeric codes.
* @property {Object.<string, number>} currencyTypes - Mapping of currency types to their numeric codes.
* @property {Object.<string, number>} dataReportTypes - Mapping of data report types to their numeric codes.
* @property {Object.<string, number>} scheduleCategories - Mapping of schedule categories to their numeric codes.
* @property {Object.<string, number>} stepsRunningModes - Mapping of steps running modes to their numeric codes.
* @property {Object.<string, { label: string, value: number }>} stepsRunningModesOptions - Options for steps running modes.
* @property {Object.<string, number>} journyPhaseTypes - Mapping of journey phase types to their numeric codes.
* @property {Object.<string, string>} procedureStepTypesTitles - Titles for procedure step types.
* @property {Object.<string, number>} accountPhases - Mapping of account phases to their numeric codes.
* @property {Object.<string, number>} userRoles - Mapping of user roles to their numeric codes.
* @property {Object.<string, number>} actionValueSources - Mapping of action value sources to their numeric codes.
* @property {Array.<{ value: number, label: string }>} actionValueSourceOptions - Array of action value source options.
* @property {Array.<{ value: string, label: string }>} actionParameterOperators - Array of action parameter operators.
* @property {Object.<string, number>} bacnetTemplateTypes - Mapping of BACnet template types to their numeric codes.
* @property {Object.<string, string>} bacnetMeasurementUnitEnums - Mapping of BACnet measurement unit codes to their names.
* @property {Object.<string, { value: number, label: string }>} bacnetUnitTypes - Mapping of BACnet unit types to their values and labels.
* @property {Object.<string, { value: number, label: string }>} bacnetUnitMeasurementUnitsTypes - Mapping of BACnet measurement unit types to their values and labels.
* @property {Object.<string, string>} bacnetObjectTypes - Mapping of BACnet object type codes to their names.
* @property {Object.<string, string>} bacnetMeasurementUnits - Mapping of BACnet measurement units to their names.
* @property {Object.<string, { value: number, label: string }>} defaultBinaryEnums - Default binary enumeration mappings.
* @property {Object.<string, { value: number, label: string }>} defaultReverseBinaryEnums - Default reverse binary enumeration mappings.
* @property {Object.<string, string>} bacnetCovOptionsTypes - Mapping of BACnet COV options to their names.
* @property {number[]} bacnetTemplatePriorities - Array of BACnet template priorities.
* @property {Object.<number, string>} bacnetPriorities - Mapping of BACnet template priorities.
* @property {Object.<string, number>} trapActionTypes - Mapping of interlock action types to their numeric codes.
* @property {Object.<string, {value: string, label: string}>[]} trapActionParameterOperators - Array of interlock action parameter operators.
*/

/**
 * Hook to get the types object from the store.
 * @returns {Types} The types object containing all the mappings and configurations.
 */
const useTypes = () => {
  const types = useStoreState((state) => state.types);
  return types;
};

export default useTypes;
