import { t as translate } from 'ttag';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import OnGrey from '@icons/OnGrey';
import InfoIcon from '@icons/InfoIcon';
import ToggleIcon from '@icons/ToggleIcon';
import ListIcon from '@icons/ListIcon';
import NumericControlIcon from '@icons/numericControlIcon';

/*
Icons
*/
const OnOffIcon = () => {
  const classes = makeStyles(() => ({
    onOffWrap: {
      width: '16px',
      height: '16px',
      borderRadius: '50%',
      backgroundColor: '#6B4B68',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      '&>svg': {
        transform: 'scale(0.5)',
      },
    },
  }))();

  return (
    <div className={classes.onOffWrap}>
      <OnGrey stroke={'#fff'} />
    </div>
  );
};

export const labelSizes = {
  extraSmall: 12,
  small: 18,
  medium: 22,
  large: 28,
};

export const labelConfig = {
  position: [
    { id: 'top', name: translate`Top` },
    { id: 'bottom', name: translate`Bottom` },
    { id: 'left', name: translate`Left` },
    { id: 'right', name: translate`Right` },
  ],
  size: [
    { id: 'extraSmall', name: translate`Extra Small` },
    { id: 'small', name: translate`Small` },
    { id: 'medium', name: translate`Medium` },
    { id: 'large', name: translate`Large` },
  ],
  color: [
    '#000000',
    '#FFFFFF',
    '#F65385',
    '#FF5C58',
    '#FD9038',
    '#F5B903',
    '#10C27B',
    '#18BDE9',
    '#276EF0',
    '#9162F9',
  ],
};
export const controlTypes = {
  informationText: 'informationText',
  toggle: 'toggle',
  onOff: 'onOff',
  list: 'list',
  numericValue: 'numericValue'
};

export const controlTypeConfig = {
  [controlTypes.informationText]: {
    id: controlTypes.informationText,
    name: translate`Textual Value`,
    icon: <InfoIcon />,
  },
  [controlTypes.toggle]: {
    id: controlTypes.toggle,
    name: translate`Toggle`,
    icon: <ToggleIcon />,
  },
  [controlTypes.onOff]: {
    id: controlTypes.onOff,
    name: translate`On/Off`,
    icon: <OnOffIcon />,
  },
  [controlTypes.list]: {
    id: controlTypes.list,
    name: translate`List`,
    icon: <ListIcon />,
  },
  [controlTypes.numericValue]: {
    id: controlTypes.numericValue,
    name: translate`Up/Down Arrows`,
    icon: <NumericControlIcon />,
  },
};

const bacnetUnitTypes = {
  'unknown': 0,
  'binary': 1,
  'reverseBinary': 2,
  'enumeration': 4,
  'value': 5,
};

const supportedControlByType = {
  [bacnetUnitTypes.unknown]: [controlTypes.informationText],
  [bacnetUnitTypes.binary]: [controlTypes.informationText, controlTypes.toggle, controlTypes.onOff],
  [bacnetUnitTypes.reverseBinary]: [controlTypes.informationText, controlTypes.toggle, controlTypes.onOff],
  [bacnetUnitTypes.enumeration]: [controlTypes.informationText, controlTypes.list],
  [bacnetUnitTypes.value]: [controlTypes.informationText, controlTypes.numericValue],
};

export const getDummyDataPointData = (dataPointConfig) => {
  const data = {
    labelPosition: 'top',
    labelColorConfig: '#000000',
    labelSize: 'small',
    controlType: supportedControlByType[dataPointConfig.type]?.[0],
    position: { x: dataPointConfig.x ?? 50, y: dataPointConfig.y ?? 50 },
    label: dataPointConfig.name,
    config: {
      ...dataPointConfig,
      supportedControls: supportedControlByType[dataPointConfig.type] || [],
    },
  };

  return data;
};

export const modalStages = {
  image: 'image',
  template: 'template',
};

export const templateSource = {
  new: 'new',
  existing: 'existing',
};

export const mergeUiTemplatePointsWithParamTemplate = (
  paramTemplateData,
  uiTemplatePoints
) => {
  if (!uiTemplatePoints || !paramTemplateData) {
    return {};
  }

  const points = { ...uiTemplatePoints };
  Object.keys(points).forEach((pointId) => {
    points[pointId].config = {
      ...paramTemplateData[pointId],
      supportedControls:
        supportedControlByType[paramTemplateData[pointId]?.type] || [],
    };
  });

  return points;
};

export const bacnetUnits = {
  0: { name: 'square-meters', symbol: 'm²' },
  1: { name: 'square-feet', symbol: 'ft²' },
  2: { name: 'milliamperes', symbol: 'mA' },
  3: { name: 'amperes', symbol: 'A' },
  4: { name: 'ohms', symbol: 'Ω' },
  5: { name: 'volts', symbol: 'V' },
  6: { name: 'kilovolts', symbol: 'kV' },
  7: { name: 'megavolts', symbol: 'MV' },
  8: { name: 'volt-amperes', symbol: 'VA' },
  9: { name: 'kilovolt-amperes', symbol: 'kVA' },
  10: { name: 'megavolt-amperes', symbol: 'MVA' },
  11: { name: 'volt-amperes-reactive', symbol: 'var' },
  12: { name: 'kilovolt-amperes-reactive', symbol: 'kvar' },
  13: { name: 'megavolt-amperes-reactive', symbol: 'Mvar' },
  14: { name: 'degrees-phase', symbol: '°' },
  15: { name: 'power-factor', symbol: 'PF' },
  16: { name: 'joules', symbol: 'J' },
  17: { name: 'kilojoules', symbol: 'kJ' },
  18: { name: 'watt-hours', symbol: 'Wh' },
  19: { name: 'kilowatt-hours', symbol: 'kWh' },
  20: { name: 'btus', symbol: 'BTU' },
  21: { name: 'therms', symbol: 'thm' },
  22: { name: 'ton-hours', symbol: 'ton·h' },
  23: { name: 'joules-per-kilogram-dry-air', symbol: 'J/kg-dry-air' },
  24: { name: 'btus-per-pound-dry-air', symbol: 'BTU/lb-dry-air' },
  25: { name: 'cycles-per-hour', symbol: 'cph' },
  26: { name: 'cycles-per-minute', symbol: 'cpm' },
  27: { name: 'hertz', symbol: 'Hz' },
  28: { name: 'grams-of-water-per-kilogram-dry-air', symbol: 'g/kg' },
  29: { name: 'percent-relative-humidity', symbol: '%' },
  30: { name: 'millimeters', symbol: 'mm' },
  31: { name: 'meters', symbol: 'm' },
  32: { name: 'inches', symbol: 'in' },
  33: { name: 'feet', symbol: 'ft' },
  34: { name: 'watts-per-square-foot', symbol: 'W/ft²' },
  35: { name: 'watts-per-square-meter', symbol: 'W/m²' },
  36: { name: 'lumens', symbol: 'lm' },
  37: { name: 'luxes', symbol: 'lx' },
  38: { name: 'foot-candles', symbol: 'fc' },
  39: { name: 'kilograms', symbol: 'kg' },
  40: { name: 'pounds-mass', symbol: 'lbm' },
  41: { name: 'tons', symbol: 'ton' },
  42: { name: 'kilograms-per-second', symbol: 'kg/s' },
  43: { name: 'kilograms-per-minute', symbol: 'kg/min' },
  44: { name: 'kilograms-per-hour', symbol: 'kg/h' },
  45: { name: 'pounds-mass-per-minute', symbol: 'lbm/min' },
  46: { name: 'pounds-mass-per-hour', symbol: 'lbm/h' },
  47: { name: 'watts', symbol: 'W' },
  48: { name: 'kilowatts', symbol: 'kW' },
  49: { name: 'megawatts', symbol: 'MW' },
  50: { name: 'btus-per-hour', symbol: 'BTU/h' },
  51: { name: 'horsepower', symbol: 'hp' },
  52: { name: 'tons-refrigeration', symbol: 'TR' },
  53: { name: 'pascals', symbol: 'Pa' },
  54: { name: 'kilopascals', symbol: 'kPa' },
  55: { name: 'bars', symbol: 'bar' },
  56: { name: 'pounds-force-per-square-inch', symbol: 'psi' },
  57: { name: 'centimeters-of-water', symbol: 'cmH₂O' },
  58: { name: 'inches-of-water', symbol: 'inH₂O' },
  59: { name: 'millimeters-of-mercury', symbol: 'mmHg' },
  60: { name: 'centimeters-of-mercury', symbol: 'cmHg' },
  61: { name: 'inches-of-mercury', symbol: 'inHg' },
  62: { name: 'degrees-celsius', symbol: '°C' },
  63: { name: 'degrees-kelvin', symbol: 'K' },
  64: { name: 'degrees-fahrenheit', symbol: '°F' },
  65: { name: 'degree-days-celsius', symbol: '°C·d' },
  66: { name: 'degree-days-fahrenheit', symbol: '°F·d' },
  67: { name: 'years', symbol: 'yr' },
  68: { name: 'months', symbol: 'mo' },
  69: { name: 'weeks', symbol: 'wk' },
  70: { name: 'days', symbol: 'd' },
  71: { name: 'hours', symbol: 'h' },
  72: { name: 'minutes', symbol: 'min' },
  73: { name: 'seconds', symbol: 's' },
  74: { name: 'meters-per-second', symbol: 'm/s' },
  75: { name: 'kilometers-per-hour', symbol: 'km/h' },
  76: { name: 'feet-per-second', symbol: 'ft/s' },
  77: { name: 'feet-per-minute', symbol: 'ft/min' },
  78: { name: 'miles-per-hour', symbol: 'mph' },
  79: { name: 'cubic-feet', symbol: 'ft³' },
  80: { name: 'cubic-meters', symbol: 'm³' },
  81: { name: 'imperial-gallons', symbol: 'gal (Imp)' },
  82: { name: 'liters', symbol: 'L' },
  83: { name: 'us-gallons', symbol: 'gal (US)' },
  84: { name: 'cubic-feet-per-minute', symbol: 'CFM' },
  85: { name: 'cubic-meters-per-second', symbol: 'm³/s' },
  86: { name: 'imperial-gallons-per-minute', symbol: 'gpm (Imp)' },
  87: { name: 'liters-per-second', symbol: 'L/s' },
  88: { name: 'liters-per-minute', symbol: 'L/min' },
  89: { name: 'us-gallons-per-minute', symbol: 'gpm (US)' },
};
