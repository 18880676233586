import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  panel:{
    padding: '10px 0',
    '& *':{
      fontFamily: 'Roboto',
    }
  },
  panelTitle:{
    padding:'16px 16px',
    color: theme.palette.primary.main,
    fontSize: 16,
    fontWeight: 500,
    borderBottom: '1px solid #D5D2D5',
  },

  dataPointConfig__item: {
    padding: '0',
  },
  dataPointConfig__itemHead: {
    padding:'0 16px',
    paddingTop:16,
    display: 'flex',
    alignItems: 'center',
    '&>div:first-child': {
      fontSize: 16,
      fontWeight: 400,
      marginRight: 'auto',
      color: '#7F7182',
    },
  },
  dataPointConfig__itemContent: {},
  configInter: {
    borderBottom: '1px solid #D5D2D5',
    padding: '16px',
    '&:last-Child': {
      marginBottom: 0,
    },
  },
  configInter__head: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '8px',

    '&>div:first-child': {
      fontSize: '14px',
      fontWeight: '400',
      marginRight: 'auto',
      color: '#7F7182',
    },
  },
  configInter__content: {},
  labelColorConfig: {
    display: 'grid',
    gridTemplateColumns: 'repeat(6, 20px)',
    gap: 9, // Adjust the gap between items as needed
  },
  colorBox: {
    width: '20px',
    height: '20px',
    border: '1px solid transparent',
    borderRadius: '5px',
    cursor: 'pointer',
    boxSize: 'border-box',
    display: 'flex',
    alignItems: 'center',
    '&>div': {
      width: '18px',
      height: '18px',
      borderRadius: '4px',
      boxSize: 'border-box',
    },
    '&.isSelected': {
      border: '1px solid #000',
    },
  },
  controlTypeSelection: {
    display: 'flex',
    alignItems: 'center',
    marginBottom:10
  },
  controlTypeSelection__radio: {
    padding: '10px 8px',
    '&:hover': {
      backgroundColor: 'transparent',
    },
    '& .MuiSvgIcon-root': {
      width: '16px',
      height: '16px',
    },
    '& .MuiRadio-root': {
      padding: 0,
    },
    '& .MuiIconButton-root': {
      padding: 0,
    },
    '&.Mui-checked': {
      '& .MuiSvgIcon-root': {
        '&:first-child': {
          color: '#545964',
        },
        '&:last-child': {
          color: '#545964',
        },
      },
    },
    '& .MuiSvgIcon-root:first-child': {
      color: '#545964',
    },
  },
  controlTypeSelection__label: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    '&>span:first-child': {
      fontSize: '14px',
      fontWeight: '400',
      lineHeight: '19.74px',
      color: '#545964',
      marginRight: '8px',
    },
  },
  labelSizeConfig: {
    display: 'flex',
    alignItems: 'center',
    '&>div': {
      width: '40px',
      height: '40px',
      textAlign: 'center',
      lineHeight: '40px',
      color: '#828282',
      border: '1px solid #fff',
      marginRight: '14px',
      backgroundColor: '#fff',
      borderRadius: '8px',
      cursor: 'pointer',
      '&.isSelected': {
        borderColor: '#000',
      },
      '&:last-child': {
        marginRight: 0,
      },
    },
  },
  customTextField: {
    '& .MuiOutlinedInput-root': {
      '& .MuiOutlinedInput-notchedOutline': {
        display: 'none', // Hides the fieldset
      },
        },
        '& .MuiInputBase-root': {
      border: 'none !important',
        },
        '& .MuiInputBase-input': {
      fontFamily: 'Roboto',
      fontSize: '12px',
      fontWeight: 400,
      lineHeight: '14.06px',
      backgroundColor: 'white',
      padding: '13px 8px',
      borderRadius: '4px',
      color: '#828282',
        },
      },
      customSelectField: {
        height: '40px',
        '&.MuiOutlinedInput-root': {
      '& .MuiOutlinedInput-notchedOutline': {
        display: 'none', // Hides the fieldset
      },
    },
    '& .MuiInputBase-root': {
      border: 'none !important',
    },
    '& .MuiInputBase-input': {
      fontFamily: 'Roboto',
      fontSize: '12px',
      fontWeight: 400,
      lineHeight: '14.06px',
      backgroundColor: 'white',
      padding: '5px 8px',
      borderRadius: '4px',
      color: '#828282',
      height:40,
      boxSizing:'border-box',
      display:'flex',
      alignItems:'center',
    },

  },
  customRadioLabel:{
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    gap:13
  }
}));

export default useStyles;
