import {
  Card,
  Checkbox,
  Dialog,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  makeStyles,
} from '@material-ui/core';
import clsx from 'clsx';
import { Close, LinkOff } from '@material-ui/icons';
import { sortBy, remove, filter } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { t } from 'ttag';
import Button from '@cool_widgets/Button';
import { manuallySetSystemViewStyle } from '../Device/ManuallySetSystems.style';
import useBacNet from '@hooks/useBacNet';
import { Delete } from '@material-ui/icons';
import { ConfirmationDialog } from '@components/ConfirmationDialog';
import { useStoreActions} from '@models/RootStore';
import { smartStructureConvert } from '@utils/StructureConversionUtils';

const showDelete = false;

const ManuallySetBacnetUnits = (props) => {
  const { device, closeModal, actions, setSelectedDeviceObj, line,fetchData } = props;
  const useStyles = makeStyles(manuallySetSystemViewStyle);
  const classes = useStyles();
  const {deleteBacNetUnit, updateBacNetUnit} = useBacNet();
  const [bacnetUnitsData, setBacnetUnitsData] = useState({});
  const [unitIdToDelete, setUnitIdToDelete] = useState(null);
  const { addMessage } = useStoreActions((action) => action.errorMessage);
  const [updatedUnits, setUpdatedUnits] = useState({});


  useEffect(() => {
    const units = smartStructureConvert(device?.bacnetUnits).object;
    setBacnetUnitsData(units);
  }, [device]);

  const sortedBacnetUnitsData = useMemo(() => {
    return bacnetUnitsData ? sortBy(filter(bacnetUnitsData, (entry) => {
      return entry.line == line && entry.isEngaged;
    }), ['title'], ['asc']) : [];
  }, [bacnetUnitsData]);

  const handleSave = async () => {
    console.log({updatedUnits});

    for (const unit of Object.values(updatedUnits)) {
      if (!unit) {
        console.error(`error - didn't find bacnet unit`);
        return;
      }
      if (unit.isVisible === undefined) {
        console.error(`error - didn't find ${unit.id} bacnet unit isVisible`);
        return;
      }
      if (unit.isVisible === bacnetUnitsData[unit.id].isVisible) {
        delete updatedUnits[unit.id];
      }

      await updateBacNetUnit({id:unit.id, data:{isVisible: unit.isVisible}});

    }

    fetchData();
    closeModal();

  };

  const handleCancel = () => {
    setUpdatedUnits({});
    setUnitIdToDelete(null);
    closeModal();
  };

  const handleDelete = async () => {
    try {
      await deleteBacNetUnit(unitIdToDelete);
      const updatedBacnetUnitsData = [...bacnetUnitsData];
      remove(updatedBacnetUnitsData, (unit) => unit.id === unitIdToDelete);
      setSelectedDeviceObj({
        ...device,
        bacnetUnits: updatedBacnetUnitsData,
      });
    } catch (error) {
      addMessage({ message: error.message });
    } finally {
      setUnitIdToDelete(null);
      actions.fetchData();
    }
  };

  const getLineCellValue = (line) => {
    if (isNaN(line)) {
      return 'unknown line';
    }
    if (line === 0) {
      return '0 (IP)';
    }
    return `${line} (MSTP)`;
  };

  const handleChange = (data, type, id) => {
    const newbacnetUnitsData = {...bacnetUnitsData[id]};
    if (!newbacnetUnitsData) {
      console.error(`error - didn't find ${id} bacnet unit`);
      return;
    }
    if (type === 'isVisible') {
      newbacnetUnitsData[type] = data;
    }
    setBacnetUnitsData({ ...bacnetUnitsData, [id]: newbacnetUnitsData });

    const updated = { ...updatedUnits };
    if(updatedUnits[id]){
      delete updated[id];
    }else{
      updated[id] = { ...newbacnetUnitsData };
    }
    setUpdatedUnits(updated);
  };

  const disableSave = useMemo(() => {
    return Object.values(updatedUnits).length === 0;
  }, [updatedUnits]);

  const disableEdit = Object.values(bacnetUnitsData || {}).every(
    ({ canUpdate = true }) => !canUpdate
  );

  return (
    <Dialog
      onClose={props.closeModal}
      aria-labelledby="simple-dialog-title"
      open={true}
      maxWidth="xl"
    >
      <div className={classes.dialogTitle}>
        <Typography className={classes.dialogText}>
          {t`Edit BACnet Devices`}
        </Typography>
        <IconButton disableRipple onClick={handleCancel}>
          <Close />
        </IconButton>
      </div>
      <div className={classes.dialogContent}>
        <Paper className={classes.systemAttributes}>
          <Card key="1" className={classes.otherFormWarpper}>
            <Typography className={classes.otherFormWarpperTitle}>
              BACnet Devices - Line {getLineCellValue(line)}
            </Typography>
            <div className={classes.scrollableTable}>
              <TableContainer>
                <Table size="small" stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      <TableCell
                        classes={{ root: classes.tableHeadCell }}
                        align="left"
                      >
                        {t`Device ID`}
                      </TableCell>
                      <TableCell
                        classes={{ root: classes.tableHeadCell }}
                        align="left"
                      >
                        {t`Name`}
                      </TableCell>
                      <TableCell
                        classes={{ root: classes.tableHeadCell }}
                        align="left"
                      >
                        {t`App name`}
                      </TableCell>
                      <TableCell
                        classes={{ root: classes.tableHeadCell }}
                        align="left"
                      >
                        {t`Line`}
                      </TableCell>
                      <TableCell
                        classes={{ root: classes.tableHeadCell }}
                        align="left"
                      >
                        {t`Vendor ID`}
                      </TableCell>
                      <TableCell
                        classes={{ root: classes.tableHeadCell }}
                        align="left"
                      >
                        {t`Org Name`}
                      </TableCell>
                      <TableCell
                        classes={{ root: classes.tableHeadCell }}
                        align="left"
                      >
                        {t`Model`}
                      </TableCell>
                      <TableCell
                        classes={{ root: classes.tableHeadCell }}
                        align="center"
                      >{t`Visible`}</TableCell>
                      <TableCell align="center" />
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {sortedBacnetUnitsData.map((bacnetUnit) => {
                      if (!bacnetUnit) {
                        return null;
                      }

                      const {
                        id,
                        bacnetId,
                        name,
                        line,
                        title = '',
                        vendorId,
                        vendorName,
                        modelName,
                        isConnected,
                        isVisible = true,
                      } = bacnetUnit;

                      return (
                        <TableRow key={id}
                          className={clsx(!isConnected && classes.disconnectedRow)}>
                          <TableCell align="left">
                            <div className={classes.bacnetIdCell}>
                              <Typography>{bacnetId}</Typography>
                              {!isConnected && <LinkOff style={{ color: '#ff6565', marginLeft: '5px' }} />}
                            </div>
                          </TableCell>
                          <TableCell align="left">
                            <Typography>{name}</Typography>
                          </TableCell>
                          <TableCell align="left">
                            <Typography>{title}</Typography>
                          </TableCell>
                          <TableCell align="left">
                            <Typography>{getLineCellValue(line)}</Typography>
                          </TableCell>
                          <TableCell align="left">
                            <Typography>{vendorId}</Typography>
                          </TableCell>
                          <TableCell align="left">
                            <Typography>{vendorName}</Typography>
                          </TableCell>
                          <TableCell align="left">
                            <Typography>{modelName}</Typography>
                          </TableCell>
                          <TableCell align="center">
                            <Checkbox
                              disabled={disableEdit}
                              onChange={(event) => {
                                handleChange(
                                  event.target.checked,
                                  'isVisible',
                                  id
                                );
                              }}
                              checked={isVisible}
                            />
                          </TableCell>
                          {showDelete && (
                            <TableCell align="left">
                              <IconButton
                                disabled={isConnected}
                                onClick={() => setUnitIdToDelete(id)}
                              >
                                <Delete />
                              </IconButton>
                            </TableCell>
                          )}
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </Card>
          <div className={classes.modalActions}>
            <Button
              title={t`close edit`}
              variant="contained"
              onClick={handleCancel}
              width={150}
              white="white"
              marginRight={true}
            >
              {t`Cancel`}
            </Button>

            <Button
              disabled={disableSave}
              title={t`save system`}
              type="submit"
              width={150}
              onClick={handleSave}
            >
              {t`Save`}
            </Button>
          </div>
        </Paper>
      </div>

      <ConfirmationDialog
        onCancel={() => setUnitIdToDelete(null)}
        onConfirm={handleDelete}
        onClose={() => setUnitIdToDelete(null)}
        title={t`Delete BACnet Device`}
        text={t`Are you sure?`}
        confirmLabel={t`Delete`}
        cancelLabel={t`No`}
        dialogType="normal"
        openDialog={!!unitIdToDelete}
      />
    </Dialog>
  );
};

export default ManuallySetBacnetUnits;
