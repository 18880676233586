import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  initialScreen: {
    padding: '160px 113px 0 113px',
    display: 'flex',
    gap: 80,
    justifyContent: 'space-between',
  },
  fieldsArea:{
    flex:1,
    color:theme.palette.primary.main,
    fontSize:16

  },
  separator:{
    padding:'33px 0',
  },
  dropzoneAreaWrapper: {
    position: 'relative',
    height: 353,
    width: 292,
  },
  dropzoneHeader:{
    marginBottom: 16,
    fontSize:16,
    fontWeight:500,
    color:'#7F7182'
  },
  dropZone: {
    width:292,
    height: 318,
    backgroundColor: '#EDEBED66',
    borderWidth: '2px',
    borderColor: '#979797',
    padding: '76px',
    '& .MuiDropzoneArea-textContainer': {
      display: 'flex',
      flexDirection: 'column-reverse',
      alignItems: 'center',
      '& p': {
        fontSize: 14,
        fontWeight: 400,
        color: '#545964',
      },
      '& svg': {
        height: 49,
      },
    },

  },
  dropzoneArea: {
    position: 'relative',
    width:292,
    height: 318,
  },
  dropzoneAreaButtonWrapper:{
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    position: 'absolute',
    bottom:'25%'
  },
  dropzoneAreaButton:{
    textTransform:'none',
    '&:hover':{
      backgroundColor:'#EDEBED',

    }
  },
  template__imagePreviewWrapper: {
    width:292,
    height: 318,
    background: '#fff',
    zIndex: 99,
    position: 'relative',
  },
  template__imagePreview: {
    width: '100%',
    height: '100%',
    top: '0',
    left: '0',
    backgroundSize: 'contain',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    borderWidth: '2px',
    borderColor: '#BDB8BD',
    borderStyle: 'dashed',
    borderRadius: '4px',
  },
  template__imagePreviewActions: {
    position: 'absolute',
    top:16,
    right: 16,
    padding: '5px',
  },
  deleteButton: {
    backgroundColor: '#fff',
  },

  dropzoneInfo:{
    paddingTop:16,
    fontSize:14,
    lineHeight:'normal',
    color:'#7F7182',
    fontWeight:400
  },

  source__radioWrap: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '19px',
    '&>span': {
      cursor: 'pointer',
    },
  },
  source__radio: {
    padding: '10px',
    '&:hover': {
      backgroundColor: 'transparent',
    },
    '& .MuiSvgIcon-root': {
      width: '16px',
      height: '16px',
    },
    '& .MuiRadio-root': {
      padding: 0,
    },
    '& .MuiIconButton-root': {
      padding: 0,
    },
    '&.Mui-checked': {
      '& .MuiSvgIcon-root': {
        '&:first-child': {
          color: '#545964',
        },
        '&:last-child': {
          color: '#545964',
        },
      },
    },
    '& .MuiSvgIcon-root:first-child': {
      color: theme.palette.primary.main,
    },
  },
  selected:{
    '& span':{
    color: theme.palette.primary.main,
    fontWeight: 500,
  },'& .makeStyles-source__radio-1685.Mui-checked .MuiSvgIcon-root:last-child': {
      color: theme.palette.primary.main,
    }
  },
  ////
  customAutocompleteField: {
    '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]': {
      padding: '1px 9px',
    },
  },
  customTextField: {
    '& .MuiInputBase-input': {
      fontFamily: 'Roboto',
      fontSize: '12px',
      fontWeight: 400,
      lineHeight: '14.06px',
      backgroundColor: 'white',
      padding: '10px 15px',
      borderRadius: '4px',
      color: '#828282',
    },
  },
  resetButtonWrapper: {
    marginTop: '10px',
    '&> span': {
      cursor: 'pointer',
      textDecoration: 'underline',
    },
  },
}));

export default useStyles;
