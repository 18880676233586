import { makeStyles } from '@material-ui/core';

const sideBarsWidth = '240px';

const useStyles = makeStyles((theme) => ({
  // Templating Area
  templateArea: {
    display: 'flex',
    height: '100%',
    overflow: 'hidden',
  },

  dataPointConfigWrap: {
    width: sideBarsWidth,
    backgroundColor: '#EDEBEDCC',
  },
  dataPointsListWrap: {
    width: sideBarsWidth,
    backgroundColor: '#EDEBEDCC',
  },
  dataPointConfigMessage:{
    width:'100%',
    height:'100%',
    display:'flex',
    justifyContent:'center',
    alignItems:'center',
    fontSize:20,
    lineHeight:2,
    padding:'0 25px',
    textAlign:'center'

  }
}));

export default useStyles;
