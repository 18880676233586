/* eslint-disable react/display-name */
import React, { memo, useCallback } from 'react';
import { t as translate } from 'ttag';
import { TextField, Select, MenuItem, Radio } from '@material-ui/core';
import clsx from 'clsx';
import useStyles from './styles';

import { controlTypeConfig, labelConfig, labelSizes } from './../../constants';
import RadioField from '@components/RadioField';

const DataPointConfigItem = memo(({ label, addon, children, classes }) => (
  <div className={classes.dataPointConfig__item}>
    {(label || addon) && (
      <div className={classes.dataPointConfig__itemHead}>
        <div>{label}</div>
        <div>{addon}</div>
      </div>
    )}
    <div className={classes.dataPointConfig__itemContent}>{children}</div>
  </div>
));

const ConfigInter = memo(({ label, children, addon, classes }) => (
  <div className={classes.configInter}>
    {(label || addon) && (
      <div className={classes.configInter__head}>
        <div>{label}</div>
        <div>{addon}</div>
      </div>
    )}
    <div className={classes.configInter__content}>{children}</div>
  </div>
));

const LabelSizeSelector = memo(
  ({ labelSize, onUpdateDataPoint, dataPoint, classes }) => (
    <div className={classes.labelSizeConfig}>
      {labelConfig.size.map((size) => (
        <div
          key={size.id}
          style={{
            fontSize: labelSizes[size.id],
          }}
          className={clsx({
            isSelected: labelSize === size.id,
          })}
          onClick={() =>
            onUpdateDataPoint({
              ...dataPoint,
              labelSize: size.id,
            })
          }
        >
          A
        </div>
      ))}
    </div>
  )
);

const LabelColorSelector = memo(
  ({ labelColorConfigKey, labelColorConfigVal, onUpdateDataPoint, dataPoint, classes }) => (
    <div className={classes.labelColorConfig}>
      {labelConfig.color.map((color) => (
        <div
          key={`color-${color}`}
          className={clsx(classes.colorBox, {
            isSelected: labelColorConfigVal === color,
          })}
          onClick={() =>
            onUpdateDataPoint({
              ...dataPoint,
              [labelColorConfigKey]: color,
            })
          }
        >
          <div style={{ backgroundColor: color }} />
        </div>
      ))}
    </div>
  )
);

const ControlTypeSelector = memo(
  ({ onUpdateDataPoint, classes, dataPoint }) => (
    <>
      {dataPoint.config.supportedControls.map((controlTypeId) => (
        <div key={controlTypeId} className={classes.controlTypeSelection}>
          <RadioField
          label={<span className={classes.customRadioLabel}>
          <span>{controlTypeConfig[controlTypeId].name}</span>
          <span>{controlTypeConfig[controlTypeId].icon}</span>
          </span>}
          checked={dataPoint.controlType === controlTypeId}
          value={controlTypeId}
          onClick={() =>
            onUpdateDataPoint({
              ...dataPoint,
              controlType: controlTypeId,
            })
          }
          />
        </div>
      ))}
    </>
  )
);

const DataPointConfig = ({ dataPoint, onUpdateDataPoint }) => {
  const classes = useStyles();

  const handleLabelChange = useCallback(
    (e) => {
      onUpdateDataPoint({
        ...dataPoint,
        label: e.target.value,
      });
    },
    [onUpdateDataPoint]
  );

  const handleLabelPositionChange = useCallback(
    (e) => {
      onUpdateDataPoint({
        ...dataPoint,
        labelPosition: e.target.value,
      });
    },
    [onUpdateDataPoint]
  );

  return (
    <div className={classes.panel}>
    <div className={classes.panelTitle}>
                  {translate`Configuration Panel`}
      </div>
      <DataPointConfigItem classes={classes}>
        <ConfigInter classes={classes} label={translate`Label`}>
          <TextField
            variant="outlined"
            fullWidth
            placeholder={translate`Set Param Name`}
            className={classes.customTextField}
            value={dataPoint?.label || ''}
            onChange={handleLabelChange}
          />
        </ConfigInter>
        <ConfigInter label={translate`Label Position`} classes={classes}>
          <Select
            value={dataPoint?.labelPosition || ''}
            variant="outlined"
            fullWidth
            className={classes.customSelectField}
            onChange={handleLabelPositionChange}
          >
            {labelConfig.position.map((position) => (
              <MenuItem key={position.id} value={position.id}>
                {position.name}
              </MenuItem>
            ))}
          </Select>
        </ConfigInter>
        <ConfigInter label={translate`Label Size`} classes={classes}>
          <LabelSizeSelector
            labelSize={dataPoint?.labelSize}
            onUpdateDataPoint={onUpdateDataPoint}
            dataPoint={dataPoint}
            classes={classes}
          />
        </ConfigInter>
        <ConfigInter label={translate`Label Color`} classes={classes}>
          <LabelColorSelector
            labelColorConfigKey={'labelColorConfig'}
            labelColorConfigVal={dataPoint?.labelColorConfig}
            onUpdateDataPoint={onUpdateDataPoint}
            dataPoint={dataPoint}
            classes={classes}
          />
        </ConfigInter>
        {
          dataPoint?.config?.errorPointEnabled && (
            <ConfigInter label={translate`Error Color`} classes={classes}>
              <LabelColorSelector
                labelColorConfigKey={'errorColorConfig'}
                labelColorConfigVal={dataPoint?.errorColorConfig}
                onUpdateDataPoint={onUpdateDataPoint}
                dataPoint={dataPoint}
                classes={classes}
              />
            </ConfigInter>
          )
        }
      </DataPointConfigItem>
      <DataPointConfigItem label={translate`Control Type`} classes={classes}>
        <ConfigInter classes={classes}>
          <ControlTypeSelector
            onUpdateDataPoint={onUpdateDataPoint}
            classes={classes}
            dataPoint={dataPoint}
          />
        </ConfigInter>
      </DataPointConfigItem>
    </div>
  );
};

export default memo(DataPointConfig);
