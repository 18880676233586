import { map } from 'lodash';

const applyCustomEnumsOptions: any = {
  systems: true,
  indoors: true,
  unitFanModes: true,
  unitOperationModes: true
}

let conditionUtils = {
  generateId: (() =>  {
    let idCounter = 0;

    const incrementCounter = function() {
      ++idCounter;
      return idCounter;
    };

    incrementCounter.reset = () => idCounter = 0;

    return incrementCounter;

  })()
};
const getCustomOptions = (source: any, enums: any, subType: any, serviceParamTypes: any, customEnumOptions: any) => {
  if (source === 'bacNetUnits') {
    return map(enums, (name, value) => ({ value, name }));
  }

  if(applyCustomEnumsOptions[source] && applyCustomEnumsOptions[enums]){
    if(source === "indoors"){
      return customEnumOptions[source]?.[subType]?.[enums] || [];
    }
    return customEnumOptions[source]?.[enums] || [];
  }
  const enumsOptions = serviceParamTypes[enums] || {};
  return  Object.keys(enumsOptions).map((key: any) => ({ name: enumsOptions[key], value: key }))
}
export default conditionUtils;
export { getCustomOptions };
