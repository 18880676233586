import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme)=>({
  stepsContainer:{
    display:'flex',
    justifyContent:'space-between',
    alignItems:'center',
    height: 40,
    gap:11,
    minWidth:35,
    padding: 12
  },
  stepItem:{
    width:12,
    height:12,
    borderRadius:12,
    backgroundColor:'#D9D9D9'
  },
  selected:{
    backgroundColor:'#4B1C46'
  }

}));

export default useStyles;
