import React, { useRef } from 'react';
import {t as translate } from 'ttag';
import {
  TextField,
  InputAdornment,
  IconButton,
  Button,
} from '@material-ui/core';
import { DropzoneAreaBase } from 'material-ui-dropzone';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Search as SearchIcon, Delete } from '@material-ui/icons';

import UploadFile from '@icons/UploadFile';
import useStyles from './styles';

import { templateSource } from './../constants';
import clsx from 'clsx';
import RadioField from '@components/RadioField';

const FILE_SIZE = 2 * 1024 * 1024; // 2MB

const getDropRejectMessage = (file) => {
  if (!file) return '';
  if (!file.type.includes('image')) {
    return translate`Only images are allowed`;
  }
  return translate`File size should not exceed 2MB`;
};

const InitialScreen = ({
  data,
  onChange,
  templates,
  templateImgPreview,
  resetToSelectedTemplateImg,
  removeTemplateImg,
}) => {

  const classes = useStyles();

  const setSource = (source) => {

    const update = {
      source: source,
    };

    if (source === templateSource.new) {
      update.template = null;
    }

    update.templateImg = null;
    removeTemplateImg();

    onChange({
      ...data,
      ...update,
    });
  };

  const inputRef = useRef();

  const handleAddFileButtonClick = () => {
    const element = document.querySelector('input[type="file"]');
    element.click();
  };

  const updateSource = (source) => () => {
    setSource(source);
  };

  const renderDropzoneArea = () => {
    if(templateImgPreview){
      return (
        <div className={classes.template__imagePreviewWrapper}>
        <div
          className={classes.template__imagePreview}
          style={{
            backgroundImage: `url(${templateImgPreview})`,
          }}
        ></div>
        <div className={classes.template__imagePreviewActions}>
          <IconButton variant="outlined" className={classes.deleteButton} onClick={removeTemplateImg}>
            <Delete />
          </IconButton>
        </div>
      </div>
      )
    }

    return(
      <div className={classes.dropzoneArea}>
          <DropzoneAreaBase
            inputProps={{ ref: inputRef }}
            fileObjects={data?.templateImg ? [data?.templateImg] : []}
            acceptedFiles={['image/*']}
            dropzoneText={translate`Drag and drop a file or click`}
            maxFileSize={FILE_SIZE}
            onAdd={(files) => {
              onChange({
                ...data,
                templateImg: files[0],
              });
            }}
            onDelete={() => {
              onChange({
                ...data,
                templateImg: null,
              });
            }}
            filesLimit={1}
            Icon={UploadFile}
            classes={{
              root: classes.dropZone,
            }}
            getDropRejectMessage={getDropRejectMessage}
          />
          <div className={classes.dropzoneAreaButtonWrapper}>
            <Button
              className={classes.dropzoneAreaButton}
              color="primary"
              variant="outlined"
              onClick={handleAddFileButtonClick}
            >
              Choose File
            </Button>
          </div>
        </div>
    )
  };

  return (
    <div className={classes.initialScreen}>
      <div className={classes.fieldsArea}>
        <div>
          <div
            className={clsx(classes.source__radioWrap, {
              [classes.selected]: data.source === templateSource.new,
            })}
          >
            <RadioField
              label={translate`Add new control`}
              value={templateSource.new}
              checked={data.source === templateSource.new}
              onClick={updateSource(templateSource.new)}
            />
          </div>
          <div>
            <TextField
              variant="outlined"
              name="templateName"
              placeholder={translate`Enter name`}
              className={classes.customTextField}
              disabled={data.source === templateSource.existing}
              value={data?.name}
              fullWidth
              onChange={(e) => {
                onChange({
                  ...data,
                  name: e.target.value,
                });
              }}
            />
          </div>
        </div>
        <div className={classes.separator}>{translate`OR`}</div>
        <div>
          <div
            className={clsx(classes.source__radioWrap, {
              [classes.selected]: data.source === templateSource.existing,
            })}
          >
            <RadioField
              label={translate`Choose from existing list`}
              value={templateSource.existing}
              checked={data.source === templateSource.existing}
              onClick={updateSource(templateSource.existing)}
            />
          </div>
          <div>
            <Autocomplete
              className={classes.customAutocompleteField}
              fullWidth
              options={templates}
              value={data.template}
              getOptionLabel={(option) => option.name}
              onChange={(event, newValue) => {
                onChange({
                  ...data,
                  template: newValue,
                });
              }}
              disabled={data.source === templateSource.new}
              renderInput={(params) => (
                <TextField
                  fullWidth
                  placeholder={translate`Search...`}
                  {...params}
                  variant="outlined"
                  className={classes.customTextField}
                  InputProps={{
                    ...params.InputProps,
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon style={{ fill: '#aaa2aa' }} />
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            />
          </div>
        </div>
        {data.source === templateSource.existing &&
        templateImgPreview != data.template?.fileURL && (
          <div className={classes.resetButtonWrapper}>
            Click <span onClick={resetToSelectedTemplateImg}>here</span> to
            reset to selected template image
          </div>
        )}
      </div>

      <div className={classes.dropzoneAreaWrapper}>
        <div className={classes.dropzoneHeader}>{translate`Upload your image`}</div>
        {renderDropzoneArea()}
        <div className={classes.dropzoneInfo}>{translate`Aspect ratio of your selected image should be ~ 9:10 . if your image have different ratio it will be completed with white margins on control screen.`}</div>
      </div>
    </div>
  );
};
export default InitialScreen;
