import { makeStyles } from '@material-ui/core';
import { over } from 'lodash';

const useStyles = makeStyles((theme) => ({
  //
  dataPoints: {
    paddingTop:18,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  dataPoints__section: {
    marginBottom: 32,
    '&.list-section':{
      flex:1,
      overflowY:'auto',
    }
  },
  dataPoints__section__instructions: {
    padding: '0 16px',
    color: theme.palette.primary.main,
    fontSize: 16,
    marginBottom:4,
    fontWeight: 'bolder',
    lineHeight: '21px',
  },
  dataPoints__section_List_instructions:{
    padding: '0 16px',
    color: theme.palette.primary.main,
    fontSize: 16,
    fontWeight: 'bolder',
    lineHeight: '21px',
    marginBottom:32,
  },
  dataPoints__list: {
    padding:'0 16px'
  },
  dataPoints__listItem: {
    padding: 10,
    cursor: 'pointer',
    fontSize: 15,
    fontWeight: 400,
    color: '#29132E',
    backgroundColor: '#fff',
    border: '1px solid #D5D2D5',
    marginBottom: 8,
    transition: '0.2s all',
    textOverflow:'ellipsis',
    whiteSpace:'nowrap',
    overflow:'hidden',
    userSelect:'none',
    borderRadius: 4,
    '&:hover': {
      backgroundColor: '#EDEBED66',
      color: '#29132E',
    },
    '&.isSelected': {
      backgroundColor: '#4B1C4C',
      color: '#fff',
    },
    '&.draggable':{
      cursor:'move',
    }
  },
  customAutocompleteField: {
    '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]': {
      padding: '1px 9px',
    },
  },
  customTextField: {
    '& .MuiInputBase-input': {
      fontFamily: 'Roboto',
      fontSize: '12px',
      fontWeight: 400,
      lineHeight: '14.06px',
      backgroundColor: 'transparent',
      padding: '10px 15px',
      borderRadius: '4px',
      color: '#828282',
    },
  },
}));

export default useStyles;
