import React, { memo, useCallback, useEffect, useRef } from 'react';
import Draggable from 'react-draggable';
import DraggableDataPoint from '../DraggableDataPoint';
import useStyles from './styles';
import clsx from 'clsx';

const getAdjustedPosition = (item, dropZone) => {

  if(!item || !dropZone){
    return false;
  }
  const itemRect = item.getBoundingClientRect();
  const dropZoneRect = dropZone.getBoundingClientRect();

  let adjustedX = itemRect.left - dropZoneRect.left;
  let adjustedY = itemRect.top - dropZoneRect.top;
  let isOverflowing = false;

  // Check for overflow and adjust position
  if (itemRect.left < dropZoneRect.left) {
    adjustedX = 0;
    isOverflowing = true;
  }
  if (itemRect.right > dropZoneRect.right) {
    adjustedX = dropZoneRect.width - itemRect.width;
    isOverflowing = true;
  }
  if (itemRect.top < dropZoneRect.top) {
    adjustedY = 0;
    isOverflowing = true;
  }
  if (itemRect.bottom > dropZoneRect.bottom) {
    adjustedY = dropZoneRect.height - itemRect.height;
    isOverflowing = true;
  }

  return isOverflowing ? { x: adjustedX, y: adjustedY } : false;
}

const MappingArea = memo(
  ({
    dataPoints = {},
    selectedDataPoint = null,
    onSelectDataPoint = () => {},
    onUpdateDataPointPosition = () => {},
    onDeleteDataPoint = () => {},
    templateImg,
    mode = 'edit',
    onChangeControlValue = () => {},
    dataPointsValues = {},
    onDropFromOutside = () => {},
  }) => {
    const classes = useStyles();
    const isEditMode = mode === 'edit';

    const dropZoneRef = useRef();
    const pointRefs = useRef({});
    const [rerender, setRerender] = React.useState(false);

    const handleDelete = useCallback(
      (dataPointId) => {
        onSelectDataPoint(null);
        onDeleteDataPoint(dataPointId);
      },
      [onSelectDataPoint, onDeleteDataPoint]
    );

    const handleSelect = useCallback(
      (dataPointId) => {
        if (isEditMode) {
          onSelectDataPoint(dataPointId);
        }
      },
      [isEditMode, onSelectDataPoint]
    );

    const handleDrag = useCallback(
      (dataPointId, { x, y }) => {
        if (isEditMode) {
          onUpdateDataPointPosition(dataPointId, { x, y });
        }
      },
      [isEditMode, onUpdateDataPointPosition]
    );

    const verifyAllItemsInBoundary = () => {
      const list = Object.entries(pointRefs.current);
      list.forEach(([key,item]) => {
        const adjustedPosition = getAdjustedPosition(item, dropZoneRef.current);
        if (adjustedPosition) {
          handleDrag(key,adjustedPosition)
        }
      })
    }

    useEffect(()=>{
      requestAnimationFrame(() => {
        verifyAllItemsInBoundary();
      });

    },[rerender])

    const handleDropFromOutside = (event) => {
      event.preventDefault();

      const dropZone = dropZoneRef.current.getBoundingClientRect();

      // Get relative X, Y coordinates
      const x = Math.max(0,event.clientX - dropZone.left - 100);
      const y = Math.max(0,event.clientY - dropZone.top - 50);

      onDropFromOutside(x, y);
      setRerender(!rerender);
    }

    const storeRef = (dataPointId) => (ref) => {
      pointRefs.current[dataPointId] = ref;
    }

    const renderDataPoint = useCallback(
      ([dataPointId, dataPoint]) => {

        const draggableProps = {
          key: dataPointId,
          bounds: 'parent',
          position: dataPoint.position,
          disabled: !isEditMode,
          onStart: () => handleSelect(dataPointId),
          onClick: () => handleSelect(dataPointId),
          onDrag: (_, position) => handleDrag(dataPointId, position),
        };

        return (
          <Draggable {...draggableProps}>
            <div ref={storeRef(dataPointId)} className={classes.dataPointMapWrapper}>
              <DraggableDataPoint
                isSelected={selectedDataPoint === dataPointId}
                dataPoint={dataPoint}
                onDelete={() => handleDelete(dataPointId)}
                mode={mode}
                controlValue={dataPointsValues[`${dataPointId}/85`]}
                onChangeControlValue={(value) => {
                  onChangeControlValue(`${dataPointId}`, value);
                }}
              />
            </div>
          </Draggable>
        );
      },
      [selectedDataPoint, isEditMode, handleSelect, handleDrag, handleDelete, dataPointsValues]
    );

    const allowDropFromOutside = (e) => {
      e.preventDefault();
    };



    return (
      <div onDrop={handleDropFromOutside} onDragOver={allowDropFromOutside} className={clsx(classes.mappingContainer,{[classes.alignTop]:!isEditMode})}>
        <div
          ref={dropZoneRef}
          className={clsx(classes.mappingHolder,{[classes.noBorder]:!isEditMode})}
          style={{ backgroundImage: `url(${templateImg})` }}
        >
          {Object.entries(dataPoints).map(renderDataPoint)}
        </div>
      </div>
    );
  }
);

MappingArea.displayName = 'MappingArea';

export default MappingArea;
