/* eslint-disable react/display-name */
import React, { useState, useCallback, memo, useMemo, useRef } from 'react';

import { getDummyDataPointData } from '../constants';

import DataPointConfig from './DataPointConfig';
import MappingArea from './MappingArea';
import DataPointsList from './DataPointsList';

import useStyles from './styles';
import { t } from 'ttag';

// New TemplateArea component with state management
const TemplateArea = memo(
  ({
    dataPoints,
    onChange,
    templateImgPreview,
    paramTemplates,
    onSelectParamTemplate,
    selectedParamTemplate,
  }) => {
    const classes = useStyles();
    const [selectedDataPoint, setSelectedDataPoint] = useState(null);

    const draggedDataPointInfo = useRef(null);

    const isHasItems = useMemo(
      () => Object.keys(dataPoints).length > 0,
      [dataPoints]
    );

    const handleSelectDataPoint = useCallback((dataPointId) => {
      setSelectedDataPoint(dataPointId);
    }, []);

    const handleUpdateDataPoint = useCallback((dataPointId, newData) => {
      onChange((prev) => ({
        ...prev,
        [dataPointId]: newData,
      }));
    }, []);

    const handleUpdateDataPointPosition = useCallback(
      (dataPointId, position) => {
        onChange((prev) => ({
          ...prev,
          [dataPointId]: {
            ...prev[dataPointId],
            position,
          },
        }));
      },
      []
    );

    const handleDeleteDataPoint = useCallback((dataPointId) => {
      onChange((prev) => {
        const { [dataPointId]: _, ...rest } = prev;
        return rest;
      });
      setSelectedDataPoint(null);
    }, []);

    const handleClickDataPoint = useCallback((dataPoint) => {
      onChange((prev) => {
        if (prev[dataPoint.id]) {
          setSelectedDataPoint(dataPoint.id);
          return prev;
        }
        return {
          ...prev,
          [dataPoint.id]: getDummyDataPointData(dataPoint),
        };
      });
      setSelectedDataPoint(dataPoint.id);
    }, []);

    const handleUpdateDataPointInfo = ({point}) => {
      draggedDataPointInfo.current = point;
    };

    const handleDropFromList = (x,y) => {
      const newPoint = {
        ...draggedDataPointInfo.current,
        x,
        y
      };
      handleClickDataPoint(newPoint);
    };

    const renderDataPointConfigContent = () => {
      if (!isHasItems) {
        return (<div className={classes.dataPointConfigMessage}>{t`Add Items from the side panel`}</div>);
      }

      if (!selectedDataPoint) {
        return <div className={classes.dataPointConfigMessage}>{t`Select an item to configure`}</div>;
      }

      return (
        <DataPointConfig
          dataPoint={dataPoints[selectedDataPoint]}
          onUpdateDataPoint={(data) => {
            handleUpdateDataPoint(selectedDataPoint, data);
          }}
        />
      );
    };

    return (
      <div className={classes.templateArea}>
        <div className={classes.dataPointsListWrap}>
          <DataPointsList
            onDragDataPoint={handleUpdateDataPointInfo}
            onClickDataPoint={setSelectedDataPoint}
            paramTemplates={paramTemplates}
            selectedParamTemplate={selectedParamTemplate}
            onSelectParamTemplate={onSelectParamTemplate}
            selectedDataPoint={selectedDataPoint}
            addedDataPoints={dataPoints}
          />
        </div>

        <MappingArea
          dataPoints={dataPoints}
          selectedDataPoint={selectedDataPoint}
          onSelectDataPoint={handleSelectDataPoint}
          onUpdateDataPointPosition={handleUpdateDataPointPosition}
          onDeleteDataPoint={handleDeleteDataPoint}
          templateImg={templateImgPreview}
          onDropFromOutside={handleDropFromList}
          mode="edit"
        />
        <div className={classes.dataPointConfigWrap}>
          {renderDataPointConfigContent()}
        </div>
      </div>
    );
  }
);

export default TemplateArea;
