import React, { useCallback, useMemo } from 'react';
import clsx from 'clsx';
import { Close as CloseIcon } from '@material-ui/icons';
import { isEmpty } from 'lodash';

import { labelSizes, bacnetUnits } from '../../constants';
import OnOff from '../Controls/OnOff';
import Toggle from '../Controls/Toggle';
import ListControl from '../Controls/List';
import InformationText from '../Controls/InformationText';
import NumericValue from '../Controls/NumericValue';
import useStyles from './styles';

const getDummyText = (dataPoint) => {
  const {config = {}} = dataPoint;

  const {customEnum,supportedControls} = config;

  if(Array.isArray(customEnum) && customEnum.length > 0){
    return customEnum[0];
  }

  if(customEnum){
    return Object.values(customEnum)[0];
  }

  if(supportedControls.includes('numericValue')){
    return Math.floor(Math.random() * (999 - 10 + 1)) + 10;
  }

  return 'Text';

};

const DraggableDataPoint = ({
  dataPoint,
  isSelected,
  onDelete,
  mode,
  onChangeControlValue,
  controlValue,
}) => {
  const classes = useStyles();

  const isInEditor = mode === 'edit';

  const isControlDisabled = isInEditor || (!isInEditor && dataPoint?.config?.readOnly);

  const dummyText = useMemo(() => getDummyText(dataPoint),[dataPoint]);

  const symbol = bacnetUnits[dataPoint?.config?.units]?.symbol || '';

  const triggerChange = useCallback((newVal) => {
    !isControlDisabled && onChangeControlValue?.(newVal);
  }, [isControlDisabled, onChangeControlValue]);

  const renderControlType = (controlType) => {
    if (controlType === 'informationText') {
      const formatValue = () => {
        if (isInEditor) {
          return dummyText;
        }

        const numValue = Number(controlValue);

        //Check if the value is a key for a custom enum and return the corresponding value.
        if(dataPoint?.config?.customEnum?.[numValue]){
          return dataPoint?.config?.customEnum[numValue];
        }

        if (isNaN(numValue)) {
          return controlValue;
        }

        return Number.isInteger(numValue)
          ? String(numValue)
          : numValue.toFixed(1);
      };

      const value = formatValue();

      return (
        <InformationText
          value={value}
          symbol={symbol}
          isDisabled={isControlDisabled}
          style={{
            fontSize: labelSizes[dataPoint.labelSize],
            color: dataPoint.labelColorConfig,
          }}
        />
      );
    }

    if (controlType === 'toggle') {
      return (
        <Toggle
          onChange={triggerChange}
          value={isInEditor ? true : controlValue}
          isDisabled={isControlDisabled}
        />
      );
    }

    if (controlType === 'onOff') {
      return (
        <OnOff
          onChange={triggerChange}
          value={isInEditor ? true : controlValue}
          isDisabled={isControlDisabled}
        />
      );
    }

    if (controlType === 'list') {
      let options = [];
      if (!isEmpty(dataPoint?.config?.customEnum)) {
        const customEnum = dataPoint?.config?.customEnum;
        options = Object.keys(customEnum).map((enumKey) => {
          return {
            value: enumKey,
            name: customEnum[enumKey],
          };
        });
      }

      return (
        <ListControl
          mode={mode}
          onChange={triggerChange}
          value={isInEditor ? null : controlValue}
          isDisabled={isControlDisabled}
          options={options}
        />
      );
    }

    if (controlType === 'numericValue') {
      return (
        <NumericValue
          value={isInEditor ? dummyText : controlValue}
          max={dataPoint?.config?.customEnum?.max}
          min={dataPoint?.config?.customEnum?.min}
          step={dataPoint?.config?.customEnum?.step}
          onChange={triggerChange}
          isDisabled={isControlDisabled}
          symbol={symbol}
        />
      );
    }

    return 'Undefined Control Type';
  };

  return (
    <div
      className={clsx(classes.draggableDataPoint, {
        [classes.labelTop]: dataPoint.labelPosition === 'top',
        [classes.labelBottom]: dataPoint.labelPosition === 'bottom',
        [classes.labelLeft]: dataPoint.labelPosition === 'left',
        [classes.labelRight]: dataPoint.labelPosition === 'right',
        isSelected: isSelected,
        moveable:isInEditor,
        inControlTab: !isInEditor,
      })}
    >
      <div
        className={classes.draggableDataPoint__label}
        style={{
          fontSize: labelSizes[dataPoint.labelSize],
          color: dataPoint.labelColorConfig,
        }}
      >
        {dataPoint.label}
      </div>
      <div className={classes.draggableDataPoint__control}>
        {renderControlType(dataPoint?.controlType)}
      </div>
      {isInEditor && isSelected && (
        <div
          className={classes.draggableDataPoint__CloseBtn}
          onClick={onDelete}
        >
          <CloseIcon />
        </div>
      )}
    </div>
  );
};

export default DraggableDataPoint;
