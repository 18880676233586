import {
  Button as MUIButton,
  Dialog,
  Grid,
  IconButton,
  Typography
} from "@material-ui/core";
import clsx from "clsx";
import _ from "lodash";
import React from "react";
import { useHistory } from "react-router-dom";
import { t } from "ttag";
import alertSeverities from "../../constants/alertSeverities";
import urlFlagMap from "../../constants/urlToFlagMapping";
import { Close } from "../../icons";
import { useStoreActions, useStoreState } from "../../models/RootStore";
import useStyles from "./AlertInfo.style";
import useChurnZero from "@hooks/useChurnZero";

const AlertInfoPopup = (props: any) => {
  const { open, alert, handleClose } = props;
  const severties: any = alertSeverities;
  const classes: any = useStyles();
  const history = useHistory();
  const setSelectedAlertObj = useStoreActions((actions) => actions.setSelectedAlert);
  const getUnitName = useStoreState((state) => state.units.getUnitName);
  const { eventTypesMirror } = useStoreState((state) => state);

  const getSystem = useStoreState((state) => state.systems.getSystem);
  const sitesFlags = useStoreState((s) => s.sites.sitesFlags);

  const {trackEvent} = useChurnZero()

  const getSystemsNames = (ids: string[]) => {
    let names = "";
    const lastIndex = ids.length - 1;
    ids.forEach((id: string, index: number) => names += getSystem(id)?.name + (lastIndex === index ? "" : ", "));
    return names;
  };

  const getMoreInfo = () => {
    setSelectedAlertObj(alert);
    history.push("/alerts");
  };

  const isUnitDisAlert = alert?.description?.toUpperCase() === "UNITS DISCONNECTED";
  const disconnectedUnits = isUnitDisAlert && alert?.unitIds.map((unitId: any) => getUnitName(unitId, false));
  const { eventTypes, trapPriorityTypes, eventTypesToSeverityMapping } = useStoreState((state) => state.types);
  const severity = severties[eventTypesToSeverityMapping[eventTypesMirror[alert.type]]];

  const isLowLineQualityError = eventTypes.lowLineQualityError === alert.type;
  const isAutomationLogicAlert = alert.type === eventTypes.commercialAction;
  const pageFlag = urlFlagMap.alerts;
  const disabled = sitesFlags[alert.siteId] && !sitesFlags[alert.siteId][pageFlag];
  const isScheduleFailures = alert.type === eventTypes.scheduleFailures;

  const handleMoreInfoClick = ()=>{
    trackEvent('AlertMoreInfo','The user clicked on “More Info” from an alert clicked  in the Dashboard');
    getMoreInfo();
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-info-dialog"
        aria-describedby="alert-description-dialog"
        maxWidth="md"
        classes={{ paper: classes.alertInfoDialogPaper }}
      >

        <Grid container className={classes.infoContainer}>
          <div className={clsx(classes.header, severity?.id && classes[severity?.id])}>
            <div className={classes.headerInfo}>
              <Typography className={classes.alertTypeStyle}>
                {
                  isLowLineQualityError && t`Line Quality` ||
                  severity?.name?.toUpperCase() === "ANOMALIES" && t`Anomaly Rule` ||
                  isScheduleFailures && t`Schedule Failure` ||
                  (isAutomationLogicAlert && t`Automation Logic: ` + alert?.description) ||
                  alert?.alertType
                }
                {alert?.errorCode && ` (${alert?.errorCode})`}
              </Typography>
              <Typography className={classes.alertIdStyle}>
                {t`ID: `}{alert.shortId}
              </Typography>

            </div>
            <div className={classes.headerActions}>
              <IconButton disableRipple onClick={handleClose} className={classes.iconBtnStyle}>
                <Close color="#fff" />
              </IconButton>
            </div>
          </div>

          <div className={classes.wrapper}>
            <div className={classes.content}>
              <div className={classes.desc}>
                <Typography className={classes.descTitle}>
                  {t`Description`}
                </Typography>
                {alert.type === eventTypes.bacnetUnitError ?
                  <Typography
                    className={classes.descInfo}
                    style={{color: alert.data?.color}}
                  >
                    {alert.errorDescription}
                  </Typography>
                  :
                  <Typography
                    className={classes.descInfo}
                    dangerouslySetInnerHTML={{
                      __html:
                        alert.styledDescription ||
                        alert.errorDescription ||
                        alert.description ||
                        "",
                    }}
                  />
                }
                <Typography className={classes.ruleName}
                  onClick={() => {
                    window.open(`${window.location.origin}/smart-rules/site/${alert.site}/rule/${alert.ruleId}`, '_blank');
                  }}
                >
                  {alert.ruleId && <span>({alert.ruleName})</span>}
                </Typography>
              </div>
              <div className={classes.infoRow}>
                <div className={classes.line}></div>
                <div className={classes.line}></div>
              </div>
              {alert?.errorCode &&
                <div className={classes.infoRow}>
                  <div className={classes.infoGroup}>
                    <div className={classes.infoTitle}>{t`Error Code`}</div>
                    <div className={classes.infoVal}>{alert.errorCode}</div>
                  </div>
                </div>}

              <div className={classes.infoRow}>
                {alert.unitName &&
                  <div className={classes.infoGroup}>
                    <div className={classes.infoTitle}>{t`Unit`}</div>
                    <div className={clsx(classes.infoVal, classes.cutLongNames)}>{alert.unitName}</div>
                  </div>}
                {isLowLineQualityError ?
                  <div className={classes.infoGroup}>
                    <div className={classes.infoTitle}>{alert.systemName === "multiple" ? t`Systems` : t`System`}</div>
                    <div className={classes.infoVal}>{alert.systemName === "multiple" ? getSystemsNames(alert.alertItemContainerIds.systemIds) : alert.systemName}</div>
                  </div>
                  : alert.systemName ?
                    <div className={classes.infoGroup}>
                      <div className={classes.infoTitle}>{t`System`}</div>
                      <div className={classes.infoVal}>{alert.systemName}</div>
                    </div> : null}
              </div>

              <div className={classes.infoRow}>
                {alert.siteName && <div className={classes.infoGroup}>
                  <div className={classes.infoTitle}>{t`Site`}</div>
                  <div className={classes.infoVal}>{alert.siteName}</div>
                </div>}
                {alert.customerName && <div className={classes.infoGroup}>
                  <div className={classes.infoTitle}>{t`Customer`}</div>
                  <div className={classes.infoVal}>{alert.customerName}</div>
                </div>}
              </div>

              <div className={classes.infoRow}>
                {alert.deviceName && <div className={classes.infoGroup}>
                  <div className={classes.infoTitle}>{!isLowLineQualityError ? t`Device` : t`Device / Line`}</div>
                  <div className={classes.infoVal}>{!isLowLineQualityError ? alert.deviceName : alert.deviceName + " / L" + alert.lineName}</div>
                </div>}
                {alert?.priority && <div className={classes.infoGroup}>
                  <div className={classes.infoTitle}>{t`Priority`}</div>
                  <div className={classes.infoVal}>{trapPriorityTypes[alert?.priority]}</div>
                </div>}
              </div>

              <div className={classes.infoRow}>
                <div className={classes.infoGroup}>
                  <div className={classes.infoTitle}>{t`Open Date`}</div>
                  <div className={classes.infoVal}>{alert.time}</div>
                </div>
                {alert.executeTime && <div className={classes.infoGroup}>
                  <div className={classes.infoTitle}>{isScheduleFailures ? t`Schedule Time` : t`Executed Time`}</div>
                  <div className={classes.infoVal}>{alert.executeTime}</div>
                </div>}
                {alert.clearTime && <div className={classes.infoGroup}>
                  <div className={classes.infoTitle}>{t`Clear Date`}</div>
                  <div className={classes.infoVal}>{alert.clearTime}</div>
                </div>}
              </div>

              <div className={classes.infoRow}>
                {alert.clearReason && <div className={classes.infoGroup}>
                  <div className={classes.infoTitle}>{t`Clear Reason`}</div>
                  <div className={classes.infoVal}>{alert.clearReason}</div>
                </div>}
              </div>

              {isUnitDisAlert &&
                <div className={clsx(classes.infoRow, classes.longInfoRow)}>
                  <div className={clsx(classes.infoGroup, classes.longInfoRow)}>
                    <div className={classes.infoTitle}>{t`Disconnected Units`}</div>
                    <div className={classes.infoVal}>{disconnectedUnits.join(", ")}</div>
                  </div>
                </div>
              }

            </div>
            <div className={clsx(classes.alertActions, classes.popupActions)}>
              <MUIButton
                variant="outlined"
                onClick={handleMoreInfoClick}
                style={{ minWidth: "unset", textTransform: "none" }}
                className={classes.cleanFilterBtnPopup}
                disabled={disabled}
              >
                {t`More Info`}
              </MUIButton>
            </div>
          </div>
        </Grid>
      </Dialog>
    </>
  );
};

export default AlertInfoPopup;
