import React from 'react';
import PropTypes from 'prop-types';
import { Button, CircularProgress, Dialog, IconButton } from '@material-ui/core';
import { t } from 'ttag';
import useStyles from './UiTemplateDialog.styles';
import { Close } from '@material-ui/icons';
import SimpleStepper from '../SimpleStepper';

const UiTemplateDialog = ({
  open,
  children,
  stage,
  onLeftButtonClick,
  onRightButtonClick,
  leftButtonDisabled,
  rightButtonDisabled,
  onClose,
  loading,
  error,
}) => {
  const { dialog, dialogTitle, dialogFooter, dialogContent, footerItem } =
    useStyles();

  const leftButtonText = stage === 'image' ? t`Cancel` : t`Back`;
  const rightButtonText = stage === 'image' ? t`Next` : t`Save`;
  const activeStep = stage === 'image' ? 0 : 1;

  const disableLeftButton = loading || leftButtonDisabled;
  const disableRightButton = loading || rightButtonDisabled;

  return (
    <Dialog open={open} className={dialog}>
      <div className={dialogTitle}>
        <span>{t`Add Control UI`}</span>
        <IconButton
          aria-label="close"
          onClick={onClose}
          className={'closeButton'}
          disabled={loading}
        >
          <Close />
        </IconButton>
      </div>
      <div className={dialogContent}>
        {children}
        <div>{error}</div>
      </div>
      <div className={dialogFooter}>
        <div className={footerItem}>
          <Button
            variant="outlined"
            onClick={onLeftButtonClick}
            disable={disableLeftButton}
          >
            {leftButtonText}
          </Button>
        </div>
        <div className={footerItem}>
          <SimpleStepper steps={2} activeStep={activeStep} />
        </div>
        <div className={footerItem}>
          <Button
            variant="contained"
            color="primary"
            onClick={onRightButtonClick}
            disabled={disableRightButton}
          >
            {loading ? (<CircularProgress />) :rightButtonText}
          </Button>
        </div>
      </div>
    </Dialog>
  );
};

UiTemplateDialog.propTypes = {};

export default UiTemplateDialog;
