import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme)=>({
  dialog:{
    '& *':{
      fontFamily:'Roboto'
    },
    '& .MuiDialog-paper': {
      width: 998, // Custom width
      height:832,
      maxWidth: 'unset',
    }
  },
  dialogTitle: {
    backgroundColor: '#F6F6F7',
    display:'flex',
    justifyContent:'space-between',
    alignItems:'center',
    padding:'0 24px',
    height:60,
    flexShrink:0,
    color:'#29132E',
    fontSize:18,
    fontWeight:500,
  },
  dialogContent:{
    height: 'calc(100% - 150px)',
    overflowY: 'auto',
    overflowX: 'hidden',
  },
  dialogFooter:{
    height:90,
    borderTop:'1px solid #D5D2D5',
    display:'flex',
    justifyContent:'space-between',
    alignItems:'center',
    padding:'0 16px',
    flexShrink:0,
  },
  footerItem:{
    display:'flex',
    alignItems:'center',
    justifyContent:'center',
    flex:1
  }
}));

export default useStyles;
