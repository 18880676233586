import React from 'react'
import PropTypes from 'prop-types'
import useStyles from './RadioField.styles'
import clsx from 'clsx';

const RadioField = ({onClick,label,checked,value}) => {

  const {radioField,radioBox,radioBoxInternal} = useStyles();

  const handleClick = () => {
    onClick(value);
  }

  return (
    <div onClick={handleClick} className={clsx(radioField,'radio-container')}>
      <div className={clsx(radioBox,'radio-box',{'checked':checked})}>
        <div className={clsx(radioBoxInternal,'radio-box-internal',{'checked':checked})}></div>
      </div>
      <span className='radio-label'>{label}</span>
    </div>
  )
}

RadioField.propTypes = {}

export default RadioField
