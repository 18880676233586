import { makeStyles } from "@material-ui/core";

export const systemViewStyle = makeStyles((theme: any) =>
({
  dialogStyle: {
    width: "100%",
    maxWidth: "90%",
    display: "flex",
    minHeight: "fit-content",
    maxHeight: "calc(100% - 140px)"
  },
  smallDialogStyle: {
    width: "60%",
    maxWidth: "90%",
    display: "flex",
    minHeight: "fit-content",
    maxHeight: "calc(100% - 140px)"
  },
  overWriteDisplayVirtualSystems: {
    display: "inline-flex",
    minWidth: "100%",
    justifyContent: "space-between",
    marginBottom: "42px",
    maxHeight: "32px"
  },
  overWriteDisplay: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    marginBottom: '10px',
  },
  iconButton: {
    minWidth: "fit-content",
    padding: 0
  },
  powerButton: {
    borderRadius: "50%",
    padding: 0,
    backgroundColor: "#fff",
  },
  powerButtonDisabled: {
    opacity: "0.5",
    pointerEvents: "none"
  },
  PowerButtonOperationStatusContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  },
  powerOn: {
    width: "35px",
    height: "35px",
    boxShadow: "0 0 4px 0 rgba(0, 0, 0, 0.05)",
    backgroundColor: theme.palette.primary.dark,
    borderRadius: "50%",
    color: "white"
  },
  powerOff: {
    background: "white",
    color: "gray",
    width: "35px",
    height: "35px",
    boxShadow: "0 0 4px 0 rgba(0, 0, 0, 0.05)",
    border: "solid 1px #d5d2d5",
    backgroundColor: "#ffffff",
    borderRadius: "50%"
  },
  indoor: {
    fontSize: "0.875rem",
    fontWeight: 450
  },
  outdoor: {
    fontSize: "0.875rem",
    fontWeight: 450
  },
  systemName: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    marginRight: "10px",
    fontFamily: "RobotoMedium",
    fontSize: 20,
    fontWeight: 500,
    letterSpacing: "normal",
    color: "#29132e",
    width: "190px"
  },
  groupIcons: {
    display: "inline-flex"
  },
  systemContainer: {

  },
  virtualSystemContainer: {
    width: "16.9rem",
    padding: "0.5rem",
    border: "1px solid rgb(0, 0, 0, 0.2)",
    borderRadius: "0.25rem",
    color: "rgb(0, 0, 0, 0.7)",
    marginRight: "10px",
    marginBottom: "10px",
    display: "flex",
    flexDirection: "column",
    minHeight: "10rem"
  },
  nameModelContainer: {
    width: '100%',
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: '15px'
  },
  logo: {
    width: "auto",
    marginRight: "1.25rem"
  },
  indoorOutdoorContainer: {
    width: "100%",
    marginBottom: '10px',
    display: "flex",
    alignItems: "center",
    '&>div': {
      marginRight: '10px',
      '&:last-child': {
        marginRight: 0
      },
      '&.SysInternalId': {
        marginLeft: 'auto'
      }
    }
  },
  modeButtons: {
    boxShadow: "none",
    height: "35px",
    width: "35px",
    minWidth: "unset",
    "&:hover": {
      cursor: "pointer"
    }
  },

  modeButton: {
    borderRight: "none",
    height: "35px",
    width: "35px",
    cursor: "pointer",
    color: "#fff"
  },
  modeButtonDisabled: {
    opacity: "0.5",
    pointerEvents: "none"
  },

  controlContainer: {
  },
  powerButtonSVG: {
    position: "relative",
    top: "15%",
    fontSize: "1.3rem"
  },
  overWriteIcon: {
    "&:hover": {
      borderRadius: "0"
    }
  },
  dialogTitle: {
    paddingLeft: 20,
    paddingRight: 13,
    backgroundColor: "#f6f6f7",
    borderBottom: "1px solid #d5d2d5",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    height: 60,
    minHeight: 60
  },
  dialogText: {
    fontFamily: "RobotoMedium",
    color: "#29132e",
    lineHeight: "normal",
    fontSize: 18,
    fontWeight: 500,
    letterSpacing: "normal"
  },
  clearButton: {
    width: "40px",
    height: "40px"
  },
  dialogContent: {
    flex: "1",
    display: "flex",
    height: "100%",
    maxHeight: "fit-content"
  },
  minLogoHeight: {
    height: "23.46px"
  },
  mappingDialogStyle: {
    width: "fit-content",
    maxWidth: "95%",
    display: "flex",
    minHeight: "200px"
  },
  mappingDialogContent: {
    minHeight: "150px",
    padding: "1rem",
    overflowY: "hidden",
    overflowX: "hidden",
    display: "flex",
    height: "100%",
    flex: 1,
    maxHeight: "fit-content"
  },
  smallMargin: {
    marginRight: "2px",
    userSelect: "none"
  },
  otherUnitDialog: {
    zIndex: 5000,
    padding: "30px 26px 27px 26px",
    minWidth: "500px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    height: 200
  },
  actionsHolder: {
    display: "flex",
    justifyContent: "flex-end",
    width: "100%"
  },
  unitTxtFeild: {
    width: "100%"
  },
  iconBtnStyle: {
    width: 30,
    height: 30,
    borderRadius: 6,
    padding: 0
  },
  iconBtn: {
    "&:hover": {
      backgroundColor: "transparent"
    }
  },
  badge: {
    position: "absolute",
    top: "-8px",
    right: "-7px",
    padding: "3px 7px",
    background: theme.palette.primary.main,
    color: "white",
    fontSize: "12px",
    lineHeight: "normal",
    borderRadius: "10px"
  },
  progressBar: {
    borderRadius: 5
  },
  progressRoot: {
    borderRadius: 5,
    height: 7
  },
  mappingText: {
    lineHeight: "normal",
    letterSpacing: "normal",
    fontWeight: 500,
    color: "#000",
    display: "flex",
    justifyContent: "space-between"
  },
  mappingContainer: {
    paddingRight: 6,
    paddingBottom: 5,
    display: "flex",
    justifyContent: "space-between",
    flexFlow: "column nowrap",
    height: 60,
    minHeight: 45
  },
  mappingTime: {
    lineHeight: "normal",
    letterSpacing: "normal",
    fontSize: "11px",
    fontWeight: 500,
    marginBottom: "10px",
    marginTop: "3px"
  },
  scheduleIcon: {
    transform: "scale(0.75)",
    marginLeft: "-6px",
    "& g": {
      opacity: 1
    }
  },
  scheduleIconBtn: {
    padding: 0,
    height: "25px",
    width: "30px",
    "&:hover": {
      backgroundColor: "transparent"
    }
  },
  DroppableOuter: {
    display: "flex",
    padding: "10px 0 0 10px !important",
    flexWrap: "wrap"
  },
  BaseTile: {
    color: "rgb(0, 0, 0, 0.7)",
    padding: "0.5rem",
    background: "#fff",
    height: '162px',
  },
  SiteSystem: {
    color: "rgb(0, 0, 0, 0.7)",
  },
  SiteSensor: {
    color: "rgb(0, 0, 0, 0.7)",
  },
  PowerMeters: {
    color: "rgb(0, 0, 0, 0.7)",
  },
  UnassignedLines: {
    justifyContent: "space-between",
    backgroundColor: "rgb(255, 240, 240)"
  },
  addNewSystem: {
    borderRadius: "4px !important",
    width: 60,
    height: "166px",
    background: "#f6f5f6 !important",
    border: "1px solid #d5d2d5 !important",
  },
  addIcon: {
    color: "#aaa2aa"
  },
  //Grid
  SiteSystemsGrid: {
    display: "flex",
    width: "100%",
    flexWrap: 'wrap',
  },
  Tile: {
    marginRight: '10px',
    marginBottom: '10px',
    transition: "opacity 0.2s ease-in-out",
    position: "relative",
    width: '270px',
    display: "flex",
    border: "1px solid rgb(0, 0, 0, 0.2)",
    borderRadius: "0.25rem",
    '&:active': {
      cursor: 'grabbing',
    },
    '&.Dragging': {
      opacity: "0.6",
    },
    '&.DragOver': {
      opacity: "0.4",
      position: 'relative',
      '&::before': {
        position: 'absolute',
        top: 0,
        left: '-8px',
        width: '3px',
        height: '100%',
        background: '#4B1C46',
        display: 'block',
        content: '""',
      }
    }
  },
  dragContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "16px",
    backgroundColor: "#AAA2AA1A",
    cursor: 'grabbing',
    color: '#AAA2AA',
    '&:active': {
      cursor: 'grabbing',
    }
  },
  whiteMoreVert: {
    height: 24,
    width: 3,
    "& path": {
      fill: "#AAA2AA"
    }
  },
  baseTileWrapper: {
    width: 'calc(100% - 16px)'
  }
}));
