import { action, Action, actionOn, ActionOn, computed, Computed, memo, thunk, Thunk } from "easy-peasy";
import { Site as siteSdk, Customer as customerSdk, BacnetDevice as bacnetDeviceSdk } from "coolremote-sdk";
import { IRootStoreModel } from "./RootStore";
import { isNil } from "lodash";

export interface IBacnetDevice {
    customer: string;
    site: string;
    accout: string;
    device: string;
    id: string;
    name: string;
    title: string;
    description: string;
    network: string;
    bacnetId: number;
    syncId: string;
    objectsDiscovered: boolean;
    permissions?: Record<string,boolean>;
}

export interface IBacnetDeviceObject {
    bacnetDevice: string
    id: string;
    name: string;
    description: string;
    objectId: string;
    bacnetType: number;
    bacnetId: number;
    bacnetProps: Record<string,any>
    syncId: string;
}

export interface BacnetServiceParam {
    code: string
    data_unit_of_measurement:string
    title:string
    hvac_param_name:string
    max?: number
    min?: number
    plotable: boolean
  }

export interface IBacnetDeviceMap {
    [key: string]: IBacnetDevice;
  }

export interface IBacnetDevicesModel {
    allBacnetDevices: IBacnetDeviceMap;
    allBacnetUnits: any;
    initialize: Action<IBacnetDevicesModel, any>;
    setBacnetUnits: Action<any, any>;
    onInitialized: ActionOn<IBacnetDevicesModel, IRootStoreModel>;
    getBacnetDevice: Computed<IBacnetDevicesModel, (id?: string | null) => IBacnetDevice | undefined>;
    getBacnetDeviceName: Computed<
        IBacnetDevicesModel,
        (bacnetDeviceId: string | null | undefined) => string,
        IRootStoreModel
    >;
    getBacnetDeviceById: Thunk<IBacnetDevicesModel, string>;
    updateBacnetDevice: Thunk<IBacnetDevicesModel, { id: string; data: any }>;
    updateBacnetDeviceState: Action<IBacnetDevicesModel, IBacnetDevice>;
    fetchSiteBacnetDevices: Thunk<IBacnetDevicesModel, string>;
    fetchCustomerBacnetDevices: Thunk<IBacnetDevicesModel, string>;
    getBacnetDeviceObjectsByDeviceId: Thunk<IBacnetDevicesModel, string>;
    discoverBacnetDeviceObjectsByDeviceId: Thunk<IBacnetDevicesModel, string>;
    getParamsForBacnetDeviceById: Thunk<IBacnetDevicesModel, string>;
    getBacnetDeviceStats: Thunk<
        IBacnetDevicesModel,
        {
        bacnetDeviceId: string;
        startTime: number;
        endTime: number;
        params?: any[];
        isReduced?: boolean;
        }>;
    getSiteBacnetUnits: Thunk<IBacnetDevicesModel, string>;
    getCustomerBacnetUnits: Thunk<IBacnetDevicesModel, string>;
    getBacnetUnitObjects: Thunk<IBacnetDevicesModel, string>;
    getBacnetUnitById: Thunk<IBacnetDevicesModel, string>;
    deleteBacnetUnit: Thunk<IBacnetDevicesModel, string>;
    updateBacnetUnit: Thunk<IBacnetDevicesModel, { id: string; data: any }>;
    createBacnetParameterTemplate: Thunk<IBacnetDevicesModel, any>;
    updateBacnetParameterTemplate: Thunk<IBacnetDevicesModel, { id: string; data: any }>;
    getSiteBacnetParameterTemplates: Thunk<IBacnetDevicesModel, string>;
    assignBacnetParameterTemplate: Thunk<IBacnetDevicesModel, { templateId: string; bacnetUnitId: string }>;
    unassignBacnetParameterTemplate: Thunk<IBacnetDevicesModel, { templateId: string; bacnetUnitId: string }>;
    assignBacnetUiTemplate: Thunk<IBacnetDevicesModel, { templateId: string; bacnetUnitId: string }>;
    unassignBacnetUiTemplate: Thunk<IBacnetDevicesModel, { templateId: string; bacnetUnitId: string }>;
    getBacnetUnitsStats: Thunk<
    IBacnetDevicesModel,
    {
      bacnetUnitId: string;
    startTime: number;
    endTime: number;
    params?: any[];
    isReduced?: boolean;
    }>;
    getBacnetParameterTemplateById: Thunk<IBacnetDevicesModel, string>;
    discoverBacnetUnitsByDevice: Thunk<IBacnetDevicesModel, { deviceId: string; line?: number }>;
    discoverBacnetUnitsBySite: Thunk<IBacnetDevicesModel, { siteId: string }>;
    discoverBacnetDeviceObjects: Thunk<IBacnetDevicesModel, { bacnetDeviceId: string }>;
    getSiteBacnetDeviceObjects: Thunk<IBacnetDevicesModel, string>;
}

export const bacnetDevicesModel: IBacnetDevicesModel = {
    allBacnetDevices: {},
    allBacnetUnits: {},
    initialize: action((state, payload) => {
      state.allBacnetDevices = payload;
    }),
    setBacnetUnits: action((state, payload) => {
      state.allBacnetUnits = payload;
    }),
    onInitialized: actionOn(
        (actions, storeActions) => [actions.initialize],
        (state, target) => { }
    ),
    getBacnetDevice: computed([state => state.allBacnetDevices], allBacnetDevices =>
        memo(id => {
            if (isNil(id)) {
            return undefined;
            }
            return allBacnetDevices[id];
        }, 100)
    ),
    getBacnetDeviceName: computed([(state) => state.allBacnetDevices], (allBacnetDevices) =>
        memo((bacnetDeviceId) => {
          if (!bacnetDeviceId) { return "-"; }
          return allBacnetDevices[bacnetDeviceId]?.title || allBacnetDevices[bacnetDeviceId]?.title;
        }, 100)
      ),
    getBacnetDeviceById: thunk(async (actions, payload) => {
        return bacnetDeviceSdk.getBacnetDeviceById(payload);
    }),
    updateBacnetDevice: thunk(async (actions, payload) => {
        const updatedBacnetDevice = await bacnetDeviceSdk.updateBacnetDeviceById(
          payload.id,
          payload.data
        );
        actions.updateBacnetDeviceState(updatedBacnetDevice);
        return updatedBacnetDevice;
    }),
    updateBacnetDeviceState: action((state, payload) => {
        state.allBacnetDevices[payload.id] = payload;
      }),
    fetchSiteBacnetDevices: thunk(async (actions, payload) => {
        const data = await siteSdk.getSiteBacnetDevices(payload, 0);
        actions.initialize(data);
        return data;
    }),
    fetchCustomerBacnetDevices: thunk(async (actions, payload) => {
        const data = await customerSdk.getCustomerBacnetUnits(payload, 0);
        actions.initialize(data);
        return data;
    }),
    getBacnetDeviceObjectsByDeviceId: thunk(async (actions, payload) => {
        const data = await bacnetDeviceSdk.getBacnetDeviceObjectsByDeviceId(payload);
        //actions.initialize(data);
        return data;
    }),
    discoverBacnetDeviceObjectsByDeviceId: thunk(async (actions, payload) => {
        const data = await bacnetDeviceSdk.discoverBacnetDeviceObjectsByDeviceId(payload);
        //actions.initialize(data);
        return data;
    }),
    getParamsForBacnetDeviceById: thunk(async (actions, payload) => {
        const response = await bacnetDeviceSdk.getBacnetDeviceObjectsByDeviceId(payload);
        const bacnetServiceParams: Record<string,BacnetServiceParam> = {}
        for (const key of Object.keys(response)) {
          const bacnetObject:IBacnetDeviceObject = response[key];
          const bacnetKey = `${bacnetObject.bacnetType}:${bacnetObject.bacnetId}/85`; // TODO for now we are using only present values
          bacnetServiceParams[bacnetKey] = {
            code: bacnetKey,
            title: bacnetObject.name,
            data_unit_of_measurement: "<unit>",
            hvac_param_name: "",
            plotable: true
          };
        }
        return bacnetServiceParams;
    }),
    getBacnetDeviceStats: thunk((actions, payload) => {
        const { bacnetDeviceId, startTime, endTime, params, isReduced } = payload;
        return bacnetDeviceSdk.getBacnetDeviceStats(
          bacnetDeviceId,
          startTime,
          endTime,
          params,
          isReduced
        );
      }),
    getSiteBacnetUnits: thunk(async (actions, payload) => {
        const data = await siteSdk.getSiteBacnetUnits(payload, 0);
        actions.setBacnetUnits(data);
        return data;
    }),
    getCustomerBacnetUnits: thunk(async (actions, payload) => {
        const data = await customerSdk.getCustomerBacnetUnits(payload, 0);
        return data;
    }),
    getBacnetUnitObjects: thunk(async (actions, payload) => {
      const data = await bacnetDeviceSdk.getBacnetUnitObjects(payload);
      return data;
    }),
    getBacnetUnitById: thunk(async (actions, payload) => {
      return bacnetDeviceSdk.getBacnetUnitById(payload);
    }),
    deleteBacnetUnit: thunk(async (actions, payload) => {
      return bacnetDeviceSdk.deleteBacnetUnit(payload);
    }),
    updateBacnetUnit: thunk( (actions, payload) => {
      const {id, data} = payload;
      return bacnetDeviceSdk.updateBacnetUnit(id, data);
    }),
    createBacnetParameterTemplate: thunk(async (actions, payload) => {
      return bacnetDeviceSdk.createBacnetParameterTemplate(payload);
    }),
    updateBacnetParameterTemplate: thunk(async (actions, payload) => {
      const {id, data} = payload;
      return bacnetDeviceSdk.updateBacnetParameterTemplate(id, data);
    }),
    getSiteBacnetParameterTemplates: thunk(async (actions, payload) => {
      return await siteSdk.getSiteBacnetParameterTemplates(payload);
    }),
    assignBacnetParameterTemplate: thunk(async (actions, payload) => {
      const {templateId, bacnetUnitId} = payload;
      return bacnetDeviceSdk.assignBacnetParameterTemplate(templateId, bacnetUnitId);
    }),
    unassignBacnetParameterTemplate: thunk(async (actions, payload) => {
      const {templateId, bacnetUnitId} = payload;
      return bacnetDeviceSdk.unassignBacnetParameterTemplate(templateId, bacnetUnitId);
    }),
    assignBacnetUiTemplate: thunk(async (actions, payload) => {
      const {templateId, bacnetUnitId} = payload;
      return bacnetDeviceSdk.assignBacnetUiTemplate(templateId, bacnetUnitId);
    }),
    unassignBacnetUiTemplate: thunk(async (actions, payload) => {
      const {templateId, bacnetUnitId} = payload;
      return bacnetDeviceSdk.unassignBacnetUiTemplate(templateId, bacnetUnitId);
    }),
    getBacnetUnitsStats: thunk((actions, payload) => {
      const { bacnetUnitId, startTime, endTime, params, isReduced } = payload;
      return bacnetDeviceSdk.getBacnetUnitsStats(
        bacnetUnitId,
        startTime,
        endTime,
        params,
        isReduced
      );
    }),
    getBacnetParameterTemplateById: thunk(async (actions, payload) => {
      return bacnetDeviceSdk.getBacnetParameterTemplateById(payload);
    }),
    discoverBacnetUnitsByDevice: thunk(async (actions, payload) => {
      return bacnetDeviceSdk.discoverBacnetUnitsByDevice(payload.deviceId, payload.line ? payload.line : undefined);
    }),
    discoverBacnetUnitsBySite: thunk(async (actions, payload) => {
      return bacnetDeviceSdk.discoverBacnetUnitsBySite(payload.siteId);
    }),
    discoverBacnetDeviceObjects: thunk(async (actions, payload) => {
      return bacnetDeviceSdk.discoverBacnetDeviceObjects(payload.bacnetDeviceId);
    }),
    getSiteBacnetDeviceObjects: thunk(async (actions, payload) => {
      const data = await siteSdk.getSiteBacnetDeviceObjects(payload);
      return data;
    }),
}
