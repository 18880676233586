import { makeStyles, Theme } from "@material-ui/core";
import { createStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    font: {
      fontSize: "0.8125rem"
    },
    headerContainer: {
      position: "relative",
      display: "flex",
      justifyContent: "space-between",
      width: "100%",
      paddingBottom: "12px"
    },
    rangePickerContainer: {
      backgroundColor: "#fff",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-evenly",
      alignItems: "center",
      boxShadow: "5px 5px 10px 0px rgba(138,138,138,1)"
    },
    calendarButtons: {
      display: "flex",
      flexFlow: "row nowrap",
      width: "100%",
      justifyContent: "flex-end",
      paddingRight: "20px",
      alignItems: "center",
      marginTop: "-10px",
      paddingBottom: "10px"
    },
    doneButton: {
      backgroundColor: "#421a40",
      marginRight: "10px",
      color: "#fff",
      width: "100px",
      "&:hover": {
        backgroundColor: "#421a40"
      }
    },
    cancelButton: { width: "100px" },
    dateButtons: {
      paddingLeft: "2rem !important",
      "@media (max-width: 1600px)": {
        maxWidth: "100%",
        padding: "1rem 1rem 0 !important"
      }
    },
    buttonGroup: {
      boxShadow: "0 3px 6px -6px #aaa2aa",
      border: "solid 2px #d5d2d5",
      backgroundColor: "#fefefe",
      borderRadius: 8
    },
    // ! HACK !
    firstDateButton: {
      borderRadius: 0,
      width: "5rem"
    },
    dateButton: {
      borderLeft: "solid 2px #d5d2d5",
      width: "5rem",
      borderRadius: "0",
      span: {
        fontSize: "0.6rem"
      }
    },
    dateButtonSelected: {
      backgroundColor: "#edebed",
      width: "5rem",
      span: {
        fontSize: "0.6rem"
      }
    },
    dateButton__label: {
      fontSize: "0.6rem"
    },
    checkboxes: {
      "@media (max-width: 1600px)": {
        maxWidth: "100%",
        padding: "1.25rem 1rem 0 !important"
      }
    },

    svgAlert: {
      width: "10px"
    },

    exportBtn: {
      padding: "5px",
      color: "#000000",
      width: "101%",
      justifyContent: "start",
      marginRight: "-16px",
      textTransform: "none"
    },

    legend: {
      margin: "1rem 3rem 0 2.4rem",
      padding: "0.4rem 1rem",
      background: "#f6f5f6",
      minHeight: "2.8rem"
    },

    SVGDownload: {
      fontSize: "1.3rem"
    },

    checkboxForm: {
      float: "right",
      alignContent: "center",
      flexWrap: "nowrap",
      whiteSpace: "nowrap",
      "& span": {
        fontSize: "0.8125rem",
        fontFamily: "Roboto"
      }
    },

    chipStyle: {
      padding: "12px 2px 12px 8px !important"
    },

    closeIcon: {
      marginLeft: "0 !important"
    },
    hide: {
      display: "none"
    },

    "font span": {
      fontSize: "0.8rem"
    },

    dateRange: {
      "& p": { fontWeight: 500 }
    },

    // graph itself
    crosshairInfoWindow: {
      width: "40ch",
      whiteSpace: "nowrap",
      border: "1px #EE617D dashed",
      padding: "0.4rem 0.8rem",
      marginLeft: "-4px"
    },

    graphRenderer: {
      height: 405
    },

    YAxis1: {
      text: {
        color: "blue"
      }
    },
    YAxis2: {
      text: {
        color: "red"
      }
    },

    // Operation modes container
    modeContainer: {
      padding: "0 55px 0 45px"
    },
    refreshStyleLoading: {},
    chartContainer: {
      position: "relative",
      display: "flex",
      flexDirection: "column",
      width: "100%",
      height: "500px",
      alignItems: "center",
      flexWrap: "initial",
      "& *": {
        cursor: "pointer",
        "-webkit-touch-callout": "none" /* iOS Safari */,
        "-webkit-user-select": "none" /* Safari */,
        "-khtml-user-select": "none" /* Konqueror HTML */,
        "-moz-user-select": "none" /* Old versions of Firefox */,
        "-ms-user-select": "none" /* Internet Explorer/Edge */,
        "user-select":
          "none" /* Non-prefixed version, currently supported by Chrome, Opera and Firefox */
      }
    },
    resetZoomBtn: {
      minHeight: "40px",
      maxHeight: "40px",
      minWidth: "169px",
      fontSize: "16px",
      fontWeight: "normal",
      lineHeight: "normal",
      color: "#fff",
      textTransform: "capitalize"
    },
    customTooltip: {
      backgroundColor: "#fff",
      border: "1px solid black",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      padding: "20px",
      "& *": {
        fontSize: "14px"
      },
      marginLeft: "50px",
      marginRight: "50px"
    },
    legendWrapper: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      marginLeft: "-4px",
      position: "relative",
      top: "-11px"
    },
    legendContainer: {
      display: "flex",
      flex: 2,
      flexWrap: "wrap",
      backgroundColor: "#efeff2",
      minHeight: "45px",
      alignItems: "center",
      padding: "0 5px"
    },
    legendOrthers: {
      display: "flex",
      flex: 1,
      width: "100%",
      justifyContent: "flex-end",
      alignItems: "center"
    },
    legendItem: {
      display: "flex",
      border: "1px solid black",
      borderRadius: "20px",
      boxSizing: "border-box",
      margin: "5px",
      height: "30px",
      justifyContent: "center",
      alignItems: "center",
      paddingRight: "7px"
    },
    legendRemove: {
      margin: "0 10px",
      marginRight: 0,
      fontSize: "1.2rem"
    },
    legendRemoveSelected: {
      color: "#fff"
    },
    zoomControlsSection: {
      display: "flex",
      flex: 1,
      justifyContent: "flex-end"
    },
    zoomInstruc: {
      display: "flex",
      flex: 2,
      justifyContent: "center",
      fontSize: "12px"
    },
    paramCont: {
      width: "100%",
      display: "flex"
    },
    paramName: {
      display: "flex",
      flex: 3,
      fontWeight: "unset"
    },
    paramVal: {
      display: "flex",
      flex: 1,
      justifyContent: "flex-start",
      fontWeight: "unset",
      textAlign: "left",
      textTransform: "capitalize"
    },
    errorTooltip: {
      display: "flex",
      flexDirection: "column",
      maxWidth: 450,
      "& *": {
        color: "#f00"
      }
    },
    System_Error: {
      "& g": { fill: "#f05146" }
    },
    Indoor_Error: {
      "& g": { fill: "#fd905f" }
    },
    Maintenance: {
      "& g": { fill: "#f8b133" }
    },
    Not_Connected: {
      "& g": { fill: "#35a8e0" }
    },
    Anomalies: {
      "& g": { fill: "#bb6ed8" }
    },
    All_Is_Well: {
      "& g": { fill: "red" }
    },
    highlightedReset: {
      background: "#421a40",
      color: "#fff",
      "&:hover": {
        backgroundColor: "#421a40"
      }
    },
    chartContainer1: {
      paddingLeft: "8px",
      paddingRight: "16px",
      position: "relative",
      display: "flex",
      flexDirection: "column",
      width: "100%",
      height: "100%",
      alignItems: "center",
      flexWrap: "initial",
      "& *": {
        cursor: "pointer",
        "-webkit-touch-callout": "none" /* iOS Safari */,
        "-webkit-user-select": "none" /* Safari */,
        "-khtml-user-select": "none" /* Konqueror HTML */,
        "-moz-user-select": "none" /* Old versions of Firefox */,
        "-ms-user-select": "none" /* Internet Explorer/Edge */,
        "user-select":
          "none" /* Non-prefixed version, currently supported by Chrome, Opera and Firefox */
      }
    },
    fixedWidth: {
      minWidth: "111px"
    },
    exportDialog: {
      minWidth: "300px"
    },
    capText: {
      textTransform: "capitalize"
    },
    loadingContainer: { height: "100%", width: "100%", display: "flex", position: "absolute", zIndex: 3, backgroundColor: "#efeff2", opacity: 0.4, alignItems: "center", justifyContent: "center" },
    refreshIcon: {
      fontSize: "50px !important"
    },
    loaderContainer: {
      width: 32.8, alignSelf: "center"
    },
    iconBtnStyle: {
      width: 30,
      height: 30,
      borderRadius: 6,
      padding: 0,
      alignSelf: "center",
      "&:disabled": {
        opacity: 0.6
      }
    },
    capitalizedText: {
      textTransform: "uppercase"
    },
    toggleAxisStyle: {
      marginRight: "5px",
      height: "29px",
      borderRight: `1px solid #fff`,
      width: "30px",
      borderTopLeftRadius: "50%",
      borderBottomLeftRadius: "50%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",

      "& svg": {
        transform: "scale(0.7)"
      }
    },
    flipIcon: {
      "& svg": {
        transform: "scale(0.7) rotateY(180deg)"
      }
    }
  })
);

export default useStyles;
