/* eslint-disable react/display-name */
import { isEmpty, set } from 'lodash';
import React, { useState, useCallback, memo, useEffect, useMemo } from 'react';
import {
  useCreateBacnetUiTemplate,
  useUpdateBacnetUiTemplate,
  useUploadBacnetUiTemplateImg,
  useGetSiteBacnetUiTemplates,
} from '@hooks/useBacnetUiTemplatesApi';
import { useStoreState } from '@models/RootStore';
import useBacNet from '@hooks/useBacNet';
import TemplateArea from './TemplateArea';
import InitialScreen from './InitialScreen';
import {
  modalStages,
  templateSource,
  mergeUiTemplatePointsWithParamTemplate,
} from './constants';
import useSaveConfirmationDialog from './SaveConfirmationModal'; // Adjust path as needed

import UiTemplateDialog from './components/UiTemplateDialog';

const AddEditBacnetUiTemplateDialog = ({
  open,
  onClose,
  defaultParamTemplate,
}) => {
  const { siteId } = useStoreState((s) => s.selections.selections);
  const [showConfirmation, DialogComponent] = useSaveConfirmationDialog();

  const [stage, setStage] = useState(modalStages.image);
  const [isCreating, setIsCreating] = useState(false);

  // Inital data
  const { data: templates, refetch: refetchGetSiteBacnetUiTemplates } =
    useGetSiteBacnetUiTemplates(siteId);
  const { mutate: uploadBacnetUiTemplateImg } = useUploadBacnetUiTemplateImg();
  const [templateImgPreview, setTemplateImagePreview] = useState(null);
  const [initialData, setInitialData] = useState({
    name: '',
    templateImg: null,
    source: templateSource.new,
  });

  const templatesMap = useMemo(() => {
    if (!templates) return {};
    return templates.reduce((acc, template) => {
      acc[template.id] = template;
      return acc;
    },{});
  },[templates]);

  const isNextButtonDisabled = useMemo(() => {
    return (
      (initialData.source === templateSource.new &&
        isEmpty(initialData.name)) ||
      (initialData.source === templateSource.existing && !initialData.template)
    );
  }, [initialData, templateImgPreview]);

  const handleGoToTemplateStage = useCallback(() => {
    setStage(modalStages.template);
  });

  useEffect(() => {
    setStage(modalStages.image);
    if (templatesMap && defaultParamTemplate) {
      const template = templatesMap[defaultParamTemplate];
      if (template) {
        setInitialData({
          source: templateSource.existing,
          template,
        });
      }
    } else {
      setInitialData({
        name: '',
        templateImg: null,
        source: templateSource.new,
      });
      setTemplateImagePreview(null);
    }
  }, [templatesMap, defaultParamTemplate]);

  useEffect(() => {
    if (initialData?.templateImg) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setTemplateImagePreview(reader.result);
      };
      reader.readAsDataURL(initialData.templateImg.file);
    }
  }, [initialData]);

  // Template area data
  const { mutate: createBacnetUiTemplate } = useCreateBacnetUiTemplate();
  const { mutate: updateBacnetUiTemplate } = useUpdateBacnetUiTemplate();
  const { getSiteBacNetParameterTemplates, siteBacNetParameterTemplates } =
    useBacNet();
  const [selectedParamTemplate, setSelectedParamTemplate] = useState(null);
  const [currentDataPoints, setCurrentDataPoints] = useState({});

  useEffect(() => {
    if (siteId) {
      getSiteBacNetParameterTemplates(siteId);
    }
  }, [siteId]);

  const handleClose = useCallback(() => {
    onClose?.();
  }, [onClose]);

  useEffect(() => {
    setSelectedParamTemplate(defaultParamTemplate);
  }, [defaultParamTemplate]);

  // watch for template data and initialize the stage
  useEffect(() => {
    if (initialData.template) {
      setSelectedParamTemplate(initialData.template.paramTemplate);
      const points = mergeUiTemplatePointsWithParamTemplate(
        siteBacNetParameterTemplates[initialData.template.paramTemplate]?.data,
        initialData.template?.data?.points
      );
      setCurrentDataPoints(points);
      setTemplateImagePreview(initialData.template?.fileURL);
    }else{
      setSelectedParamTemplate(null);
      setCurrentDataPoints({});
      setTemplateImagePreview(null);
    }
  }, [initialData.template]);

  // Handle save template area
  const handleSave = useCallback(async () => {
    let isNewTemplate = true;
    let templateName = initialData.name;
    if (initialData?.source === templateSource.existing) {
      const result = await showConfirmation();
      if (!result) {
        return;
      }
      isNewTemplate = result.isNew;
      templateName = isNewTemplate ? result.newName : templateName;
    }

    setIsCreating(true);

    let fileId = initialData?.template?.fileId;

    if (initialData.templateImg) {
      try {
        fileId = await uploadBacnetUiTemplateImg({
          filePayload: initialData.templateImg,
        });
      } catch (error) {
        console.error('Failed to upload Bacnet UI template image:', error);
        setIsCreating(false);
        return;
      }
    }

    try {
      if (!isNewTemplate) {
        await updateBacnetUiTemplate({
          templateId: initialData.template.id,
          payload: {
            data: {
              points: currentDataPoints,
            },
            paramTemplate: selectedParamTemplate,
            fileId,
          },
        });
      } else {
        await createBacnetUiTemplate({
          data: {
            points: currentDataPoints,
          },
          name: templateName,
          description: `BACnet UI template for ${siteId} ${templateName}`,
          site: siteId,
          paramTemplate: selectedParamTemplate,
          fileId,
        });
      }
      handleClose();
    } catch (error) {
      console.error('Failed to save Bacnet UI template:', error);
    } finally {
      setIsCreating(false);
      refetchGetSiteBacnetUiTemplates();
      handleClose();
    }
  }, [
    uploadBacnetUiTemplateImg,
    createBacnetUiTemplate,
    currentDataPoints,
    handleClose,
    siteId,
    initialData,
    selectedParamTemplate,
  ]);

  const renderScreen = () => {
    if (stage === modalStages.image) {

      return (
        <InitialScreen
          data={initialData}
          onChange={setInitialData}
          templates={templates}
          templateImgPreview={templateImgPreview}
          resetToSelectedTemplateImg={() => {
            setTemplateImagePreview(initialData.template?.fileURL);
          }}
          removeTemplateImg={() => {
            setInitialData({
              ...initialData,
              templateImg: null,
            });
            setTemplateImagePreview(null);
          }}
        />
      );
    }
    return (
      <TemplateArea
        templateImgPreview={templateImgPreview}
        dataPoints={currentDataPoints}
        onChange={setCurrentDataPoints}
        paramTemplates={siteBacNetParameterTemplates}
        selectedParamTemplate={selectedParamTemplate}
        onSelectParamTemplate={(newVal) => {
          setSelectedParamTemplate(newVal);
          setCurrentDataPoints({});
        }}
      />
    );
  };

  const cancelButtonAction =
    stage === modalStages.image
      ? handleClose
      : () => setStage(modalStages.image);
  const nextButtonAction =
    stage === modalStages.image ? handleGoToTemplateStage : handleSave;

  return (
    <>
      <UiTemplateDialog
        open={open}
        stage={stage}
        rightButtonDisabled={isNextButtonDisabled}
        onLeftButtonClick={cancelButtonAction}
        onRightButtonClick={nextButtonAction}
        onClose={handleClose}
        loading={isCreating}
      >
        {renderScreen()}
      </UiTemplateDialog>

      <DialogComponent />
    </>
  );
};

export default memo(AddEditBacnetUiTemplateDialog);
