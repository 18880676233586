import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx';
import useStyles from './SimpleStepper.styles';

const SimpleStepper = ({steps,activeStep}) => {

  const {selected,stepItem,stepsContainer } = useStyles();

  const getComputedStep = () => {

    if(isNaN(activeStep)){
      return 0;
    }
    if(activeStep > steps){
      return steps;
    }
    return activeStep;
  }

  const computedStep = getComputedStep();

  const getComputedSteps = () => {
    return new Array(steps).fill({}).map((value,index)=> ({selected: index === activeStep}));
  }

  const computedSteps = useMemo(() => getComputedSteps(),[steps,computedStep]);

  if(!steps || steps === 0){
    console.error('SimpleStepper: steps or activeStep is not provided',{steps,activeStep});
    return null;
  }

  return (
    <div className={stepsContainer}>
      {computedSteps.map((step,index) => (
        <div key={index} className={clsx(stepItem,{[selected]:step.selected})}></div>
      ))}
    </div>
  )
}

SimpleStepper.propTypes = {}

export default SimpleStepper
