import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  // Mapping Container
  mappingContainer: {
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    '&>*': {
      userSelect: 'none',
    },
    [theme.breakpoints.up('desktop2k')]: {
      alignItems:'start',
    },
  },
  alignTop:{
    [theme.breakpoints.up('desktop2k')]: {
      alignItems:'start',
    },
  },
  //730, 800
  mappingHolder: {
    position: 'relative',
    width: 486,
    height: 530,
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    border: '1px solid #D5D2D5',
    borderRadius: 8,
    backgroundColor: '#fff',
  },
  noBorder:{
    border: 'none',
    [theme.breakpoints.up('desktop2k')]: {
      transform:'scale(1.9) translate(0, 23%);',
    },
  },
  dataPointMapWrapper: {
    display: 'inline-block',
    position: 'absolute',
  },
}));

export default useStyles;
