
import React from 'react';
import PropTypes from 'prop-types';

/**
 * DetectionIcon component renders an SVG icon with customizable dimensions and class name.
 *
 * @param {Object} props - The properties object.
 * @param {number} props.height - The height of the SVG icon.
 * @param {number} props.width - The width of the SVG icon.
 * @param {string} props.className - The CSS class name to apply to the SVG element.
 * @returns {JSX.Element} The rendered SVG icon.
 */
const DetectionIcon = ({ height, width, className }) => {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width={width} height={height} rx="12" fill="#35A8E0" />
      <path
        d="M19.3515 12.2509L21.0001 8.79964L19.1291 9.46237C17.9926 6.69146 15.2844 4.85123 12.2728 4.85123C9.13851 4.85123 6.32938 6.84017 5.2821 9.79854L6.68119 10.2942C7.51915 7.92703 9.76646 6.33553 12.2728 6.33553C14.6563 6.33553 16.7912 7.78371 17.717 9.96206L15.8993 10.605L19.3515 12.2509Z"
        fill="#29132E"
      />
      <path
        d="M3.54541 15.7459L5.41566 15.0831C6.55293 17.8547 9.26038 19.6943 12.2727 19.6943C15.407 19.6943 18.2161 17.7053 19.2634 14.7469L17.8643 14.2513C17.0257 16.6185 14.7791 18.21 12.2728 18.21C9.88916 18.21 7.75429 16.7618 6.82973 14.5834L8.64605 13.9405L5.19406 12.2939L3.54541 15.7459Z"
        fill="#29132E"
      />
    </svg>
  );
};
DetectionIcon.propTypes = {
  height: PropTypes.number,
  width: PropTypes.number,
  className: PropTypes.string,
};

DetectionIcon.defaultProps = {
  height: 24,
  width: 24,
  className: '',
};

export default DetectionIcon;
