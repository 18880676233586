// / <reference types="googlemaps" />
/* global google */
import Grid from "@material-ui/core/Grid";
import { find, isEmpty, isUndefined } from "lodash";
import moment from "moment";
import "moment-timezone";
import React, { useCallback, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { t } from "ttag";
import { symbols } from "../../constants/sensorsUnitsSymbols";
import { useStoreActions, useStoreState } from "../../models/RootStore";
import ErrorBox from "../WarningBox/ErrorBox";
import UnitStatsGraph from "./UnitStatsGraph";
import UnitStatsSlider from "./UnitStatsSlider";
import UnitStatsTable from "./UnitStatsTable";
import useCoolPoll from "@hooks/useCoolPoll";

const MAX_ALLOWED_GAP_PRO = 1000 * 60 * 20; // (20 mins)
const AUTOUPDATE_INTERVAL = 1000 * 60; // one minute
const MAX_ALLOWED_STATS_SELECTIONS = 6;
const paramsColorsSet: any = ["#7a6095", "#35a8e0", "#00b259", "#ef3b2f", "#f8b133", "#7f7182"];

const UnitStats: React.FC<any> = ({ sensor, site, setSelectedTime, setReFetchUnit, type, allPowerMeters, minDate }) => {
  const getSensorStats = useStoreActions((a) => a.sensors.getSensorStats);
  const getPowerStats = useStoreActions((a) => a.powerMeters.getPowerStats);
  const getAllPowerStats = useStoreActions((a) => a.powerMeters.getAllPowerStats);
  const getAllSensorsStats = useStoreActions((a) => a.sensors.getAllSensorsStats);
  const getSiteSensorsAsParams = useStoreState((a) => a.sensors.getSiteSensorsAsParams);
  const { addMessage } = useStoreActions((action) => action.errorMessage);
  const temperatureScale = useStoreState((s) => s.users.me.temperatureScale || 0);
  const userPreferences = useStoreState((state) => state.users.userPreferences);
  const updateUserPreferences = useStoreActions((actions) => actions.users.updateUserPreferences);
  const { dateFormat, timeFormat } = useStoreState((state) => state.users);
  const [isLoading, setIsLoading] = useState(true);
  const [statDataCollection, setStatDataCollection] = useState<any>([]);
  const [autoupdate, setAutoupdate] = useState(false);
  const [paramsTable, setParamsTable] = useState<any>({});
  const [updateTime, setUpdateTime] = useState<any>("");
  const [unitFetchedData, setUnitFetchedData] = useState<any>({});
  const [paramsColors, setParamsColors] = useState<any>(new Map());
  const [colorSet, setColorSet] = useState<any>(paramsColorsSet);

  const [selectedStartTime, setSelectedStartTime] = useState<any>(new Date().getTime() - 1 * 24 * 60 * 60 * 1000); //defaultDatePast);
  const [selectedEndTime, setSelectedEndTime] = useState<any>(new Date().getTime()); //defaultDateNow);
  const [showAutoUpdateWarning, handleShowAutoUpdateWarning] = useState<boolean>(false);
  const [disableUpdateBtn, setDisableUpdateBtn] = useState<boolean>(false);
  const [counter, setCounter] = useState<number>(0);

  const dateTimeFormat = `${dateFormat} ${timeFormat}`;
  const timezone = site?.timezone || moment.tz.guess();
  const storedTrap = localStorage.getItem("trap");
  const location: any = useLocation();
  const { state } = location;
  const serviceParams = useStoreState((s) => s.serviceParams);
  const getTrapParams = useStoreActions((a) => a.traps.getTrapParams);
  const getUnitType = useStoreState((u) => u.units.getUnitType);
  const userMeasurementUnits = useStoreState((s) => s.users.me.measurementUnits || 1);
  const enums = useStoreState((state) => state.serviceParamTypes);

  const unitType = getUnitType(sensor.id);

  useEffect(() => {
    setColorSet([...paramsColorsSet]);
  }, [sensor.site, type]);

  useEffect(() => {
    if (isLoading) {
      return;
    }
    prepareUserPrefData();
  }, [paramsTable]);

  const prepareUserPrefData = () => {
    if (isEmpty(paramsTable)) {
      return;
    }

    if(sensor.id == "all"){
      return;
    }

    const showingParams = Object.values(paramsTable).filter((row: any) => row?.isShowing);
    let data: any = {};
    showingParams.map((row: any) => data[row.id] = true);
    let userPrefObj: any = userPreferences?.serviceRecentParamsO;
    const showed = userPrefObj && userPrefObj["Externals"] && userPrefObj["Externals"][sensor.id] ? userPrefObj["Externals"][sensor.id] : {};
    if (userPrefObj) {
      userPrefObj["Externals"] = {
        ...userPrefObj["Externals"],
        [sensor.id]: { ...showed, [type]: data }
      };
    }
    else {
      userPrefObj = {
        ["Externals"]: {
          [sensor.id]: { [type]: data }
        }
      };
    }
    let userPrefObjSelected: any = userPreferences?.serviceSelectedStats;
    const sitePreSelected = userPrefObjSelected && userPrefObjSelected["Externals"] && userPrefObjSelected["Externals"][sensor.id] ? userPrefObj["Externals"][sensor.id] : {};
    const savedParams: any = {};
    Array.from(paramsColors, ([id]) => savedParams[id] = true);

    if (userPrefObjSelected) {
      userPrefObjSelected["Externals"] = {
        ...userPrefObjSelected["Externals"],
        [sensor.id]: { ...sitePreSelected, [type]: savedParams }
      };
    }
    else {
      userPrefObjSelected = {
        ["Externals"]: {
          [sensor.id]: { [type]: savedParams }
        }
      };
    }
    updateUserPreferences({ serviceRecentParamsO: userPrefObj, serviceSelectedStats: userPrefObjSelected });
  };
  const prepareGraphsData = (statsData: any) => {
    const statDataCollectionTemp: any = [];

    statDataCollectionTemp.push({
      timestamp: selectedStartTime,
      noData: 0.5
    });

    statsData.forEach((statPoint: any, statPointIndex: any) => {
      let point = { ...statPoint, noData: 0.5 };

      statDataCollectionTemp.push({
        ...point
      });

      if (
        statsData[statPointIndex + 1] &&
        statsData[statPointIndex + 1].timestamp >= statPoint.timestamp + MAX_ALLOWED_GAP_PRO
      ) {
        statDataCollectionTemp.push({
          timestamp: statPoint.timestamp + 2,
          noData: 0.5
        });
      }

    });

    statDataCollectionTemp.push({
      timestamp: selectedEndTime,
      noData: 0.5
    });

    setStatDataCollection(statDataCollectionTemp);
  };

  const fetchStatsData = (id: string, startTime: number, endTime: number, type: string) => {
    setIsLoading(true);
    if (type === "powerMeter") {
      if (id === "all") {
        getAllPowerStats({
          siteId: sensor.site,
          data: { startTimeUTC: startTime, endTimeUTC: endTime }
        })
          .then((res: any) => {
            setUnitFetchedData(res);
            return res;
          })
          .then((data: any) => {
            const result: any = {};
            Object.keys(data).forEach((key: string) => {
              result[key] = data[key]?.entries || {};
            });
            setStatDataCollection(result);
            prepareMultiTableData(data, type, {});
            setIsLoading(false);
          })
          .catch((err: any) => {
            setIsLoading(false);
            addMessage({ message: err.message });
          }).finally(() => {
            if (autoupdate) {
              if (counter === 59) {
                setAutoupdate(false);
                handleShowAutoUpdateWarning(true);
                setCounter(0);
              } else {
                setCounter(counter + 1);
              }
            }
          });
        return;
      }

      getPowerStats({
        id,
        data: { startTimeUTC: startTime, endTimeUTC: endTime }
      })
        .then((res: any) => {
          setUnitFetchedData(res);
          return res;
        })
        .then((data: any) => {
          prepareGraphsData(data.entries);
          prepareTableData(data, type);
          setIsLoading(false);
        })
        .catch((err: any) => {
          setIsLoading(false);
          addMessage({ message: err.message });
        }).finally(() => {
          if (autoupdate) {
            if (counter === 59) {
              setAutoupdate(false);
              handleShowAutoUpdateWarning(true);
              setCounter(0);
            } else {
              setCounter(counter + 1);
            }
          }
        });
      return;
    }

    if (id === "all") {
      const sensorsData = getSiteSensorsAsParams(sensor.site, false);
      const { sensors, sensorsAsParams } = sensorsData;
      getAllSensorsStats({
        params: sensorsAsParams,
        data: { startTimeUTC: startTime, endTimeUTC: endTime }
      })
        .then((res: any) => {
          setUnitFetchedData(res);
          return res;
        })
        .then((data: any) => {
          const result: any = {};
          Object.keys(data).forEach((key: string) => {
            result[key] = data[key]?.entries || {};
          });
          setStatDataCollection(result);
          prepareMultiTableData(data, type, sensors);
          setIsLoading(false);
        })
        .catch((err: any) => {
          setIsLoading(false);
          addMessage({ message: err.message });
        }).finally(() => {
          if (autoupdate) {
            if (counter === 59) {
              setAutoupdate(false);
              handleShowAutoUpdateWarning(true);
              setCounter(0);
            } else {
              setCounter(counter + 1);
            }
          }
        });
      return;
    }

    const { userMinMax } = sensor;
    getSensorStats({
      id,
      data: { startTimeUTC: startTime, endTimeUTC: endTime, userMinMax }
    })
      .then((res: any) => {
        setUnitFetchedData(res);
        return res;
      })
      .then((data: any) => {
        prepareGraphsData(data.entries);
        prepareTableData(data, type);
        setIsLoading(false);
      })
      .catch((err: any) => {
        setIsLoading(false);
        addMessage({ message: err.message });
      }).finally(() => {
        if (autoupdate) {
          if (counter === 59) {
            setAutoupdate(false);
            handleShowAutoUpdateWarning(true);
            setCounter(0);
          } else {
            setCounter(counter + 1);
          }
        }
      });
  };

  useEffect(() => {
    if (!sensor || !selectedStartTime || !selectedEndTime) {
      return;
    }

    fetchStatsData(sensor.id, selectedStartTime, selectedEndTime, type);
  }, [sensor, selectedStartTime, selectedEndTime, temperatureScale]);

  const onRefresh = useCallback(() => {
    if (!selectedStartTime) {
      return;
    }

    onDateRangeChange({ startDate: new Date(moment(selectedStartTime).tz(timezone).format("llll")), endDate: new Date(moment().tz(timezone).format("llll")) }, true);
  }, [selectedStartTime]);

  const isToday = useCallback((date: any) => {
    const today = new Date(moment().tz(timezone).format("llll"));
    return date.getDate() === today.getDate() &&
      date.getMonth() === today.getMonth() &&
      date.getFullYear() === today.getFullYear();
  }, []);

  const onDateRangeChange = useCallback(async (
    dateRange: {
      startDate?: Date | undefined;
      endDate?: Date | undefined;
    },
    skipDateFrameChecking = false) => {
    if (!isUndefined(dateRange.startDate) && !isUndefined(dateRange.endDate)) {
      const timezoneOffset = moment().tz(timezone).utcOffset() * 60 * 1000;
      const checkIsToday = isToday(dateRange.endDate);
      const currentHourMinsArray = moment().tz(timezone).format("HH:mm").split(":");
      const startHoursArray = moment(dateRange.startDate).format("HH:mm").split(":");
      const startTime = Date.UTC(dateRange.startDate.getFullYear(),
        dateRange.startDate.getMonth(), dateRange.startDate.getDate(), +startHoursArray[0], +startHoursArray[1]) - timezoneOffset;
      const endTime = checkIsToday ? Date.UTC(dateRange.endDate.getFullYear(),
        dateRange.endDate.getMonth(), dateRange.endDate.getDate(), +currentHourMinsArray[0], +currentHourMinsArray[1], 0) - timezoneOffset :
        Date.UTC(dateRange.endDate.getFullYear(),
          dateRange.endDate.getMonth(), dateRange.endDate.getDate(), 23, 59, 59) - timezoneOffset;

      if (!isToday(new Date(moment(endTime).tz(timezone).format("llll"))) || (!skipDateFrameChecking && (endTime - startTime > 86400000))) {
        autoupdate && setAutoupdate(false);
        !disableUpdateBtn && setDisableUpdateBtn(true);
        counter && setCounter(0);
      } else {
        disableUpdateBtn && setDisableUpdateBtn(false);
      }

      setSelectedStartTime(startTime);
      setSelectedEndTime(endTime);
    }
  }, [autoupdate, disableUpdateBtn, counter]);

  const exportFile = useCallback(async () => {
    if (sensor.id === "all") {
      let headers = "Date/Time,";
      const selectedIds: any = [];
      Array.from(paramsColors, ([id]) => {
        const sensor = paramsTable[id];
        if (sensor) {
          selectedIds.push(id);
          headers = headers + sensor.name + ",";
          return;
        }
      });

      let rows = headers + "\r\n";

      if (!unitFetchedData[selectedIds[0]]?.entries) {
        return;
      }
      unitFetchedData[selectedIds[0]].entries.forEach((row: any, index: number) => {
        const { timestamp } = row;
        const date = moment(timestamp).tz(timezone).format(`${dateTimeFormat}`);
        rows += `${date},`;

        selectedIds.forEach((id: string) => {
          const { name, enums = null, tempSign = "", unit } = paramsTable[id];
          const value = unitFetchedData[id]?.entries?.[index][type === "sensor" ? "reading_value" : "power"];
          const unitSymbol = symbols[unit] || "";

          rows += `${enums ? enums[value] || "-" : value}` + unitSymbol + tempSign + ",";
        });
        rows += "\r\n";
      });

      let link = window.document.createElement("a");
      link.setAttribute("href", "data:text/csv;charset=utf-8,%EF%BB%BF" + encodeURI(rows));
      link.setAttribute("download", `all-${type}.csv`);
      link.click();
      return;
    }

    let rows = "";
    if(type === "powerMeter") {
      // Extract relevant headers dynamically, excluding specified keys
      const excludedKeys = new Set([
        'timestamp',
        'energy',
        'power',
        'device_serial',
      ]);

      const dataHeaders = Object.keys(
        unitFetchedData?.entries?.[0] || {}
      ).filter((key) => !excludedKeys.has(key));

      // Construct CSV headers with meaningful names
      const headers = [
        'Date/Time',
        'Power Meter Name',
        'Energy',
        'Power',
        ...dataHeaders.map((code) => serviceParams[code]?.title || code),
      ];

      // Initialize rows with headers
      rows = headers.join(',') + '\r\n';

      // Process each data entry and append to CSV rows
      (unitFetchedData?.entries || []).forEach((dataRow: any) => {
        const { timestamp, power, energy, ...readings } = dataRow;
        const date = moment(timestamp).tz(timezone).format(`${dateTimeFormat}`);
        const values = dataHeaders.map((code) => readings[code]).join(',');
        rows += `${date},${sensor?.name},${energy},${power},${values}` + '\r\n';
      });

    } else {
      const { name = "", tempSign = "", unit, enums = null } = sensor || {};
      const unitSymbol = symbols[unit] || "";
      rows = `Date/Time, Sensor Name, Value ${unitSymbol}${tempSign}` + "\r\n";
  
      (unitFetchedData?.entries || []).forEach((dataRow: any) => {
        const { timestamp, reading_value } = dataRow;
        const date = moment(timestamp).tz(timezone).format(`${dateTimeFormat}`);
  
        rows += `${date},${name},${enums ? enums[reading_value] : reading_value}` + "\r\n";
      });
    }

    let link = window.document.createElement("a");
    link.setAttribute("href", "data:text/csv;charset=utf-8,%EF%BB%BF" + encodeURI(rows));
    link.setAttribute("download", `${name}.csv`);
    link.click();
  }, [unitFetchedData, paramsColors, paramsTable]);

  useCoolPoll({
    frequency: AUTOUPDATE_INTERVAL,
    enabled: autoupdate && !!selectedStartTime,
    onPoll: () => {
      onRefresh();
    },
  });

  const showHideAllParams = useCallback((isChecked: boolean) => {
    const rows = { ...paramsTable };

    Object.keys(rows).forEach((key: any) => {
      const obj = rows[key];
      obj.isShowing = isChecked;
      if (!isChecked) {
        obj.isChecked = false;
      }
    });

    let colors = colorSet;
    if (!isChecked) {
      colors = [...paramsColorsSet];
    }
    Array.from(paramsColors, ([id]) => {
      if (rows[id]) {
        if (!rows[id].isChecked) {
          paramsColors.delete(id);
        }
      }
    });

    setParamsColors(new Map(paramsColors));
    setColorSet(colors);
    setParamsTable(rows);
  }, [paramsColors, colorSet, paramsTable]);

  const showHideParam = useCallback((code: any) => {
    const oldVal = !!paramsTable[code].isShowing;
    if (paramsColors.has(code)) {
      setColorSet([...colorSet, paramsColors.get(code)]);
      paramsColors.delete(code);
      setParamsColors(paramsColors);
    }
    setParamsTable({ ...paramsTable, [code]: { ...paramsTable[code], isShowing: !oldVal, isChecked: false } });
  }, [paramsColors, colorSet, paramsTable]);

  const prepareTableData = async (data: any, type: string) => {
    let selectedStats: any = userPreferences?.serviceSelectedStats;
    let prevPreferdStats: any = selectedStats['Externals']?.[sensor.id]?.[type] || {};
    const colorSetTemp = [...paramsColorsSet];

    if (!data) {
      setParamsTable({});
      setUpdateTime("");
      return;
    }

    const { current, ranges } = data;

    if (isEmpty(current)) {
      setParamsTable({});
      setUpdateTime("");
      return;
    }

    const tableRows: any = {};
    const { timestamp, reading_value, power, energy } = current[0];

    if (type === "powerMeter") {
      const newSelected: any = new Map();

      const slider = ranges.power ? <UnitStatsSlider data={{
        code: "power",
        slider: {
          min: +ranges.power.min,
          max: +ranges.power.max,
          selectionMin: +ranges.power.min,
          selectionMax: +ranges.power.max,
          value: power
        },
        value: ranges.power.value,
        name: t`Power`
      }} index={`sliderreading_value_power`} /> : null;

      const sliderEnergy = ranges.energy ? <UnitStatsSlider data={{
        code: "energy",
        slider: {
          min: +ranges.energy.min,
          max: +ranges.energy.max,
          selectionMin: +ranges.energy.min,
          selectionMax: +ranges.energy.max,
          value: energy
        },
        value: ranges.energy.value,
        name: t`Energy`
      }} index={`sliderreading_value_energy`} /> : null;

      tableRows.power = {
        id: "power",
        editable: false,
        name: t`Power (kW)`,
        timestamp,
        slider,
        displayValue: power,
        isChecked: !!prevPreferdStats["power"],
        disabled: false,
        plottable: true,
      };

      tableRows.energy = {
        id: "energy",
        editable: false,
        name: t`Energy (kWh)`,
        timestamp,
        slider: sliderEnergy,
        displayValue: energy,
        isChecked: !!prevPreferdStats["energy"],
        disabled: false,
        plottable: true,
      };

      const unitSupportedParams = Object.keys(ranges);
      const currentObj = current[0];
      if (data && !isEmpty(serviceParams) && unitSupportedParams && currentObj) {
        const trapId = state?.trap || storedTrap;
        const trapParams = trapId && trapId !== "null" ? await getTrapParams(trapId) : null;

        localStorage.removeItem("unitId");
        localStorage.removeItem("trap");

        const measuredArr: any = [];
        const unMeasuredArr: any = [];

        unitSupportedParams.forEach((code) => {

          if (["power", "energy", "timestamp", "device_serial"].includes(code)) {
            return;
          }
          const servParam = serviceParams[code];
          if (!servParam) {
            return;
          }

          const {
            min: minRanges = 0,
            max: maxRanges = 0
          } = ranges[code];
          const value = currentObj[code];

          const {
            showInGraph = true,
            plotable,
            data_unit_of_measurement: measurementUnit = "",
            min = 0,
            max = 0,
            title = "",
            enum: enumName = null,
            editable,
            value_type,
            techInfo,
            button,
            buttonText,
            buttonValue,
            isCalculated,
            isCustomParam = false,
            id,
            formula
          } = servParam;

          let isChecked = false, hideInTable = false, isShowing = true;

          if ((trapParams?.includes(`${code}`) || (prevPreferdStats[code] && newSelected.size < MAX_ALLOWED_STATS_SELECTIONS))) {
            isChecked = true;
            hideInTable = true;
            newSelected.set(code, colorSetTemp.pop());
          }

          if (!showInGraph && !hideInTable) {
            //tableRows[code] = null;
            return;
          }

          hideInTable = showInGraph;
          const measuredParam = servParam ? (plotable !== undefined ? plotable : true) : false;

          (measuredParam ? measuredArr : unMeasuredArr).push(code);

          let measurementUnits = measurementUnit;
          if (temperatureScale === 2 && measurementUnit === "°C") {
            measurementUnits = "°F";
          }
          if (userMeasurementUnits === 2 && (measurementUnit === "kg/cm2" || measurementUnit === "MPa")) {
            measurementUnits = "PSI";
          }
          if (userMeasurementUnits === 1 && measurementUnit === "PSI") {
            measurementUnits = "kg/cm2";
          }

          const enumVal = enumName ? enums?.[enumName]?.[value] : null;
          const slider = measuredParam ? (
            <UnitStatsSlider
              data={{
                code,
                slider: { min, max, selectionMin: minRanges, selectionMax: maxRanges, value },
                value,
                name: title
              }}
              index={`slider${code}`}
            />
          ) : <span />;

          tableRows[code] = {
            code,
            editable,
            name: title,
            value,
            measurementUnits,
            slider,
            showInGraph,
            hvac_param_name: servParam.hvac_param_name,
            plottable: measuredParam,
            isShowing,
            type: "unit",
            isChecked,
            enumVal,
            value_type,
            enum: enumName,
            techInfo,
            button,
            buttonText,
            buttonValue,
            hideInTable,
            isCalculated,
            isCustomParam,
            id: id || code,
            formula
          };
        });
      }

      tableRows.power.isChecked && newSelected.set("power", "green");
      tableRows.energy.isChecked && newSelected.set("energy", "blue");

      setParamsColors(new Map(newSelected));
      setParamsTable(tableRows);
      setUpdateTime(timestamp);
      return;
    }

    const slider = ranges.reading_value || ranges.reading_value === 0 ? <UnitStatsSlider data={{
      code: "reading_value",
      slider: {
        min: +ranges.reading_value.min,
        max: +ranges.reading_value.max,
        selectionMin: +ranges.reading_value.min,
        selectionMax: +ranges.reading_value.max,
        value: reading_value
      },
      value: ranges.reading_value.value,
      name: t`Value`
    }} index={`sliderreading_value`} /> : null;

    tableRows.reading_value = {
      id: "reading_value",
      editable: false,
      name: t`Value`,
      value: sensor.value,
      timestamp,
      slider,
      tempSign: sensor.tempSign,
      unit: sensor.unit,
      displayValue: sensor?.enums ? sensor.enums[reading_value] : sensor.tempSign ? Math.round(reading_value) : reading_value,
      disabled: true,
      isChecked: true,
      plottable: true,
    };

    setParamsTable(tableRows);
    setUpdateTime(timestamp);
  };

  const prepareMultiTableData = (data: any, type: string, sensors: any) => {
    if (!data) {
      setParamsTable({});
      setUpdateTime("");
      return;
    }
    const newSelected: any = new Map();
    let memo: any = null;
    let recentParams: any = null;

    let selectedStats: any = userPreferences?.serviceSelectedStats;
    let prevPreferdStats: any = {};
    const colorSetTemp = [...paramsColorsSet];

    const tableRows: any = {};
    Object.keys(data).forEach((key: any) => {
      const { current, ranges } = data[key];
      if (isEmpty(current)) {
        return;
      }
      const userPrefObj: any = userPreferences?.serviceRecentParamsO;
      if (userPrefObj && userPrefObj["Externals"] && userPrefObj["Externals"][sensor.id] && userPrefObj["Externals"][sensor.id][type]) {
        memo = userPrefObj["Externals"][sensor.id][type];
      }
      if (selectedStats && selectedStats["Externals"] && selectedStats["Externals"][sensor.id] && selectedStats["Externals"][sensor.id][type]) {
        prevPreferdStats = selectedStats["Externals"][sensor.id][type];
      }

      if (type === "powerMeter") {
        const name = allPowerMeters[key]?.name;
        if (!name) {
          return;
        }

        const { timestamp, power, energy } = current[0];

        const slider = ranges.power ? <UnitStatsSlider data={{
          code: "power",
          slider: {
            min: +ranges.power.min,
            max: +ranges.power.max,
            selectionMin: +ranges.power.min,
            selectionMax: +ranges.power.max,
            value: power
          },
          value: ranges.power.value,
          name: t`Power`
        }} index={`sliderreading_value_power`} /> : null;

        const sliderEnergy = ranges.energy ? <UnitStatsSlider data={{
          code: "energy",
          slider: {
            min: +ranges.energy.min,
            max: +ranges.energy.max,
            selectionMin: +ranges.energy.min,
            selectionMax: +ranges.energy.max,
            value: energy
          },
          value: ranges.energy.value,
          name: t`Energy`
        }} index={`sliderreading_value_energy`} /> : null;

        let isCheckedE = false;
        let isCheckedP = false;

        if ((memo && memo.hasOwnProperty(`${key}-p`) && prevPreferdStats[`${key}-p`] && newSelected.size < MAX_ALLOWED_STATS_SELECTIONS)) {
          isCheckedP = true;
          newSelected.set(`${key}-p`, colorSetTemp.pop());
        }
        if ((memo && memo.hasOwnProperty(`${key}-e`) && prevPreferdStats[`${key}-e`] && newSelected.size < MAX_ALLOWED_STATS_SELECTIONS)) {
          isCheckedE = true;
          newSelected.set(`${key}-e`, colorSetTemp.pop());
        }

        tableRows[`${key}-p`] = {
          id: `${key}-p`,
          editable: false,
          name: `${name} (Power kW)`,
          timestamp,
          slider,
          displayValue: power,
          isChecked: isCheckedP,
          isShowing: memo ? !!memo[`${key}-p`] : true,
          plottable: true,
          isAll: true,
          isAllKey: 'power'
        };

        tableRows[`${key}-e`] = {
          id: `${key}-e`,
          editable: false,
          name: `${name} (Energy kWh)`,
          timestamp,
          slider: sliderEnergy,
          displayValue: energy,
          isChecked: isCheckedE,
          isShowing: memo ? !!memo[`${key}-e`] : true,
          plottable: true,
          isAll: true,
          isAllKey: 'energy'
        };
      } else {
        const sensor = sensors[key];
        if (!sensor) {
          return;
        }
        const { name, userMinMax, unit, tempSign, valueMax, valueMin, enums } = sensor;
        const id = key;
        const { timestamp, reading_value: data = {} } = current;
        const { value, min, max } = data;

        const slider = valueMax || valueMin ? <UnitStatsSlider data={{
          code: id,
          slider: {
            min: userMinMax ? userMinMax.min : valueMin,
            max: userMinMax ? userMinMax.max : valueMax,
            selectionMin: min,
            selectionMax: max,
            value
          },
          value,
          name
        }} index={`slider${id}`} /> : null;

        let isChecked = false;

        if ((memo && memo.hasOwnProperty(id) && prevPreferdStats[id] && newSelected.size < MAX_ALLOWED_STATS_SELECTIONS)) {
          isChecked = true;
          newSelected.set(id, colorSetTemp.pop());
        }
        tableRows[id] = {
          id,
          name,
          tempSign,
          slider,
          enums,
          displayValue: enums ? enums[value] : value,
          isChecked,
          isShowing: memo ? !!memo[id] : true,
          unit,
          plottable: true,
        };
      }

    });

    setParamsColors(newSelected);

    setUpdateTime("");
    setParamsTable(tableRows);
  };
  useEffect(() => {
    const usedColors = Object.values(Object.fromEntries(paramsColors));
    const unUsedColors = paramsColorsSet.filter((color: any) => usedColors.indexOf(color) === -1);
    setColorSet(unUsedColors);
  }, [paramsColors]);

  const updateParamRow = useCallback((id: any) => {
    const oldVal = paramsTable[id].isChecked;
    if (!oldVal && paramsColors.size >= MAX_ALLOWED_STATS_SELECTIONS) {
      return;
    }
    if (!oldVal) {
      const colorSetTemp = colorSet;
      const newColor = colorSetTemp.pop();
      paramsColors.set(id, newColor);

      setColorSet(colorSetTemp);
    } else {
      setColorSet([...colorSet, paramsColors.get(id)]);
      paramsColors.delete(id);
    }
    setParamsColors(new Map(paramsColors));
    setParamsTable({ ...paramsTable, [id]: { ...paramsTable[id], isChecked: !oldVal } });
  }, [paramsTable, colorSet, paramsColors]);

  return (
    <Grid container spacing={0} style={{ padding: "16px 0" }}>
      <Grid item xs={5}>
        <UnitStatsTable
          paramsTable={paramsTable}
          endTime={selectedEndTime}
          isToday={isToday}
          onRefresh={onRefresh}
          isLoading={isLoading}
          updateTime={updateTime}
          autoupdate={autoupdate}
          setAutoupdate={setAutoupdate}
          timezone={timezone}
          updateParamRow={updateParamRow}
          showHideAllParams={showHideAllParams}
          showHideParam={showHideParam}
          type={type === "sensor" ? "sensor" : "power meter"}
          multi={sensor.id === "all"}
          disableUpdateBtn={disableUpdateBtn}
        />
      </Grid>
      <Grid item={true} xs={7}>
        <UnitStatsGraph
          isToday={isToday}
          startTime={selectedStartTime}
          endTime={selectedEndTime}
          unitStatsData={sensor.id === "all" ? statDataCollection : Object.values(statDataCollection)}
          onDateRangeChange={onDateRangeChange}
          onRefresh={onRefresh}
          isLoading={isLoading}
          exportFile={exportFile}
          enums={sensor?.enums}
          timezone={timezone}
          dateFormat={dateFormat}
          timeFormat={timeFormat}
          hideLegends={isEmpty(paramsTable)}
          type={type}
          paramName={type === "sensor" ? t`Value` : t`Power`}
          paramsColors={paramsColors}
          multi={sensor.id === "all"}
          paramsTable={paramsTable}
          onChipDelete={updateParamRow}
          minDate={minDate}
        />
      </Grid>
      {showAutoUpdateWarning && <ErrorBox
        title={t`Confirm`}
        error={"Auto Update has stopped. Do you want to proceed?"}
        onAccept={() => { handleShowAutoUpdateWarning(false); setAutoupdate(true); }}
        onClose={() => handleShowAutoUpdateWarning(false)}
        acceptTitle={t`Yes`}
        cancelTitle={t`No`}
      />
      }
    </Grid>
  );
};

export default UnitStats;
