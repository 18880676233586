/**
 * Converts an input (Array/Object) into an object with two properties:
 * - object: The input converted to an object with IDs as keys.
 * - array: The input converted into an array of objects.
 *
 * If the input is not an array of objects or an object, returns:
 * { object: {}, array: [] }
 *
 * @param {Array|Object} input - The input to be converted.
 * @returns {{object: Object, array: Array}} The converted object and array.
 */
export const smartStructureConvert = (input) => {
  if (Array.isArray(input)) {
    if (input.every(item => typeof item === 'object' && item !== null && 'id' in item)) {
      const object = input.reduce((acc, item) => {
        acc[item.id] = item;
        return acc;
      }, {});
      return { object, array: [...input] };
    }
  } else if (typeof input === 'object' && input !== null) {
    const array = Object.keys(input).map(key => ({ id: key, ...input[key] }));
    return { object: {...input}, array };
  }
  console.warn('Input is neither an array of objects with "id" properties nor a valid object.');
  return { object: {}, array: [] };
};

