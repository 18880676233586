import {  makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  bacnetControlPanel: {
    padding: '0 10px',
    minWidth:526,
    minHeight:614,
    overflow: 'hidden',
  },
  bacnetControlPanel__header: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    padding: '42px 0',
    justifyContent: 'center',
    position: 'relative',
  },
  bacnetControlPanel__temperature: {
    fontSize: '24px',
    fontWeight: '400',
    lineHeight: '28.13px',
    letterSpacing: '-0.03em',
    color: '#9B8B9B',
    position: 'absolute',
    left: 0,
    display: 'flex',
    '&>span:last-child': {
      fontSize: '16px',
      fontWeight: '400',
      lineHeight: '18.75px',
      marginLeft: '3px',
    },
  },
  bacnetControlPanel__unitName: {
    fontSize: '28px',
    fontWeight: '400',
    lineHeight: '32.81px',
    color: '#FFFFFF',
    textAlign: 'center',
  },
  bacnetControlPanel__body: {
        borderRadius: '8px',
        minHeight: '400px',
      [theme.breakpoints.up('desktop2k')]: {
        height:1000,
      },
  },
}));

export default useStyles;
