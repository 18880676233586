import { Typography } from '@material-ui/core/';
import clsx from 'clsx';
import React from 'react';
import useStyle from './styles';
import { Disconnect as DisconnectIcon } from '@icons';
import { useStoreState } from '@models/RootStore';

const BacnetUnitItem = ({ siteId, onSelect, groupId, selectedItem, entry }) => {
  const classes = useStyle();
  const { title = '', id, isConnected, device } = entry;
  const devices = useStoreState((state) => state.devices.allDevices);
  const deviceIsConnected = devices[device] && devices[device].isConnected;
  const isDisconnected = !deviceIsConnected || !isConnected;

  const isSelected = selectedItem.id === id && selectedItem.groupId === groupId;
  const isSelectable = !!entry.uiTemplate;

  const handleClick = () => {
    // if not UI template then it's not selectable
    if (!isSelectable || isDisconnected) {
      return;
    }

    onSelect(siteId, {
      isGroup: false,
      id,
      groupId,
      type: 'bacnetUnit',
    });
  };

  return (
    <div
      className={clsx(
        classes.bacnetUnitItem__container,
        isDisconnected && classes.erroredDiv,
        {
          [classes['bacnetUnitItem--selected']]: isSelected,
          [classes['bacnetUnitItem--selectable']]: isSelectable,
        }
      )}
      onClick={handleClick}
    >
      <div className={classes.bacnetUnitItem__locationSection}>
        <Typography
          noWrap
          className={clsx(classes.bacnetUnitItem__name, {
            [classes['bacnetUnitItem__name--bold']]: isSelected,
          })}
        >
          {title}
        </Typography>
      </div>
      {isDisconnected && (
        <div className={classes.disconnectedIconContainer}>
          <DisconnectIcon />
        </div>
      )}
    </div>
  );
};

export default BacnetUnitItem;
