import {
  Button,
  Checkbox,
  CircularProgress,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  IconButton,
  InputAdornment,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography
} from "@material-ui/core";
import { Close, ExpandMore, LinkOff, Sort } from "@material-ui/icons";
import clsx from "clsx";
import { System as SystemSdk } from "coolremote-sdk";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { differenceWith, isEmpty, isEqual, orderBy, pick, omit} from "lodash";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { t } from "ttag";
import * as Yup from "yup";
import { Loader } from "../../components/Loader";
import CoolButton from "../../cool_widgets/Button";
import { Arrow as SvgArrow, SortUp } from "../../icons";
import { DeleteIcon } from "../../logos";
import { useStoreActions, useStoreState } from "../../models/RootStore";
import useStyles from "./AddEditSystem.style";
import ErrorBox from "../../components/WarningBox/ErrorBox";
import LightTooltip from "../../components/Tooltip/LightTooltip";
import useChurnZero from "@hooks/useChurnZero";

const unitRoles: any = { 0: "Unknown", 1: "Master", 2: "Slave" };

const AddEditSystem = (props: any) => {
  const classes = useStyles();
  const { selectedSystemInfo, actions, refreshUnits, setRefreshUnits, isService } = props;
  const { selectedSystem, selectedDevice, selectedUnit, unitType } = selectedSystemInfo;

  const types = useStoreState((state) => state.types);
  const unitTypesMirror = useStoreState((state) => state.unitTypesMirrror);
  const allSystems = useStoreState((state) => state.systems.allSystems);
  const allUnits = useStoreState((state) => state.units.allUnits);
  const getDeviceLines = useStoreActions((actions) => actions.devices.getDeviceLines);
  const createSystem = useStoreActions((actions) => actions.systems.createSystem);
  const { addMessage } = useStoreActions((action) => action.errorMessage);
  const updateUnits = useStoreActions((actions) => actions.units.updateUnits);
  const updateUnitSystem = useStoreActions((actions) => actions.units.updateUnitSystem);
  const editSystem = useStoreActions((actions) => actions.systems.updateSystem);
  const getLineUnits = useStoreActions((actions) => actions.devices.getDeviceUnitsAPI);
  const getSystems = useStoreActions((action) => action.systems.getSystems);
  const [lines, setLines] = useState<any>([]);
  const [editSystemInfo, setEditSystem] = useState<boolean>(false);
  const [brandsMap, setBrandsMap] = useState<any>({});
  const [brandsOptions, setBrandsOptions] = useState<any>([]);
  const [capacityUnits, setCapacityUnits] = useState<any>({});
  const [capacityUnitsOptions, setCapacityUnitsOptions] = useState<any>([]);
  const [units, setUnits] = useState<any>({ all: {}, IDU: {}, ODU: {}, BS: {} });
  const [expandIndoor, setExpandIndoor] = useState<boolean>(true);
  const [expandOutdoor, setExpandODU] = useState<boolean>(true);
  const [expandBS, setExpandBS] = useState<boolean>(false);
  const [sorting, setSorting] = useState<any>({ IDU: { by: "address", isAsc: true }, ODU: { by: "name", isAsc: true }, BS: { by: "name", isAsc: true } });
  const [saving, setSaving] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const allDevices = useStoreState((s) => s.devices.allDevices);
  const selectedDeviceObj = allDevices[selectedDevice];
  const isSensiboDevice = selectedDeviceObj?.type === types.deviceBrands.sensibo;

  const [openConfirmDialog, SetOpenConfirmDialog] = useState<boolean>(false);

  const [indoorUnitsDirty, setIndoorUnitsDirty] = useState<boolean>(false);
  const [outdoorUnitsDirty, setOutdoorUnitsDirty] = useState<boolean>(false);
  const [bsUnitsDirty, setBSUnitsDirty] = useState<boolean>(false);
  const [sysDirty, setSysDirty] = useState<boolean>(false);

  const sysFormRef = useRef<any>(null);
  const indoorUnitsformikRef = useRef<any>(null);
  const outdoorUnitsformikRef = useRef<any>(null);
  const bsUnitsformikRef = useRef<any>(null);

  const [openCopyDialog, setOpenCopyDialog] = useState<boolean>(false);
  const [copyProps, setCopyProps] = useState<{ name: boolean, capacity: boolean }>({ name: true, capacity: true });

  const { systemTypes, capacityMeasurementUnitTypes } = types;


  useEffect(() => {
    if (isEmpty(capacityMeasurementUnitTypes)) {
      return;
    }
    const units = Object.keys(capacityMeasurementUnitTypes).reduce((result: any, item: any) => {
      const val = capacityMeasurementUnitTypes[item];
      result.map[val] = item;
      result.options.push(<MenuItem key={item} value={val}>{item}</MenuItem>);
      return result;
    }, { map: {}, options: [] });
    setCapacityUnits(units.map);
    setCapacityUnitsOptions(units.options);
  }, [capacityMeasurementUnitTypes]);

  useEffect(() => {
    if (isEmpty(brandsMap)) {
      return;
    }

    getDeviceLines(selectedSystemInfo.selectedDevice)
      .then((res: any) => {

        const sorted = orderBy(res, ["id"], ["asc"]);
        const lines = sorted?.map((line: any) => <MenuItem value={line.id} key={`lineOption-${line.id}`}>{`${line.id}${brandsMap[line.brand] ? ` - ${brandsMap[line.brand]}` : ""}`}</MenuItem>);
        setLines(lines);
      });
  }, [brandsMap]);

  useEffect(() => {
    if (!types || !types.hvacBrands) {
      return;
    }

    const options = [];
    const map: any = {};
    for (let brand of types.hvacBrands) {
      options.push(<MenuItem value={brand.value} key={`brandoption-${brand.value}`}>{brand.name}</MenuItem>);
      map[brand.value] = brand.name;
    }

    setBrandsOptions(options);
    setBrandsMap(map);
  }, [types]);

  useEffect(() => {
    if (!selectedSystem || selectedSystem === true || !lines?.length) {
      return;
    }
    setLoading(true);
    const sys: any = allSystems[selectedSystem];
    const prom = [SystemSdk.getUnits(selectedSystem)];

    const lineObj = lines.filter((l: any) => l.id === sys.line)[0];

    if (lineObj?.controlLine) {
      prom.push(getLineUnits({ id: selectedDevice, line: lineObj.controlLine }));
    }

    Promise.all(prom)
      .then((resp: any) => {
        const [sysUnits, lineUnits] = resp;
        const unitsData: any = Object.values(sysUnits).reduce((result: any, item: any) => {

          if (unitTypesMirror[item.type] === "indoor") {
            result.IDU[item.id] = item;
          }
          if (unitTypesMirror[item.type] === "outdoor") {
            result.ODU[item.id] = item;
          }
          if (unitTypesMirror[item.type] === "bsBox") {
            result.BS[item.id] = item;
          }

          return result;
        }, { IDU: {}, ODU: {}, BS: {} });

        if (!isEmpty(lineUnits)) {
          const lineUnitsTemp: any = Object.values(lineUnits).reduce((result: any, item: any) => {
            if (unitTypesMirror[item.type] === "indoor" && item.system === selectedSystem) {
              result[item.id] = item;
            }
            return result;
          }, {});

          unitsData["IDU"] = { ...unitsData["IDU"], ...lineUnitsTemp };
        }
        setUnits({ all: sysUnits, ...unitsData });
        setLoading(false);
      });

    if (+unitType === +types.unitTypes["outdoor"]) {
      setExpandIndoor(false);
      setExpandODU(true);
    }
  }, [selectedSystem, refreshUnits, lines]);

  const isOpen = selectedSystem && selectedDevice;
  const close = () => {
    if (indoorUnitsDirty || outdoorUnitsDirty || bsUnitsDirty || sysDirty)
      SetOpenConfirmDialog(true);
    else
      actions.openAddEditSystem(null, null)
  };
  const isEdit = !!selectedSystemInfo.selectedDevice && !!selectedSystemInfo.selectedSystem && selectedSystemInfo.selectedSystem !== true;

  const unlinkUnit = (id: string, type: string) => {
    updateUnitSystem({ unitId: id, oldSystem: selectedSystem, newSystem: "" })
      .then(() => {
        const { [id]: removeAll, ...restAll } = units.all;
        const { [id]: remoteType, ...restType } = units[type];

        setUnits({
          ...units,
          all: { ...restAll },
          [type]: { ...restType }
        });
      })
      .catch((err: any) => addMessage({ message: err.message }))
      .finally(() => {
        // Deleting the ODU can impact system capacity, which is calculated on the backend. As a result, it's necessary to reload the system data.
        // However, the current implementation reloads data for all systems, rather than targeting only the affected one.
        getSystems();
        actions.fetchData();
      });
  };

  const createSys = (values: any) => {
    const data = { ...omit(values, ['capacity', 'capacityMeasurementUnits']), device: selectedDevice };
    createSystem(data)
      .then((res: any) => {
        return actions.fetchData();
      })
      .catch((err: any) => addMessage({ message: err.message }))
      .finally(() => actions.openAddEditSystem(null, null));
  };

  const handleSave = () => {
    if (indoorUnitsDirty && indoorUnitsformikRef.current) { indoorUnitsformikRef.current.handleSubmit() }
    if (outdoorUnitsDirty && outdoorUnitsformikRef.current) { outdoorUnitsformikRef.current.handleSubmit() }
    if (bsUnitsDirty && bsUnitsformikRef.current) { bsUnitsformikRef.current.handleSubmit() }
    if (sysDirty && sysFormRef.current) { sysFormRef.current.handleSubmit() }
  }

  const updateSort = (type: string, column: string) => {
    const sortObj: any = sorting[type];
    if (sortObj.by === column) {
      sortObj.isAsc = !sortObj.isAsc;
    }
    else {
      sortObj.by = column;
      sortObj.isAsc = true;
    }

    setSorting({ ...sorting, [type]: { ...sortObj } });
  };

  const updateSystem = (values: any) => {
    setSaving(true);
    const updatedData: any = pick(values, ["name", "brandNum", "type", "series", "line"]);
    editSystem({ systemId: values.id, updatedData })
      .then(() => {
        setSaving(false);
        setEditSystem(false);
        actions.openAddEditSystem(null, null);
      })
      .catch((err: any) => addMessage({ message: err.message }))
      .finally(() => actions.fetchData());
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .required("Required"),
    brandNum: Yup.mixed()
      .required("Required"),
    line: Yup.mixed()
      .required("Required")
  });

  const handleServiceAddressMappingClick = () => {
    actions.openSrvMapDialog(true);
  };

  const unitValidationSchema = Yup.object({
    items: Yup.array().of(
      Yup.object({
        name: Yup.string().required('Name is required')
      })
    )
  });

  const sysObj: any = useMemo(() => {
    const system: any = allSystems[selectedSystem] ?? {};

    const { brandNum } = system;

    //If the system is Mitsubishy Electric (4) convert the internalId to base 10 from base 16.
    if (brandNum === 4 && system.internalId !== undefined) {
      return { ...system, internalId: parseInt(system.internalId, 16) };
    }
    return system;
  }, [selectedSystem]);


  const isDaikin: boolean = brandsMap[sysObj?.brandNum]?.toLowerCase()?.indexOf("daikin") > -1;
  const isNew = selectedSystem === true;
  const editSys = editSystemInfo || isNew;
  const { canUpdate = true } = sysObj || {};

  return (
    <Dialog
      onClose={close}
      aria-labelledby="simple-dialog-title"
      open={!!isOpen}
      maxWidth="xl"
      fullWidth
      PaperProps={{
        style: { height: isEdit ? "100%" : "auto" }
      }}
    >
      <div className={classes.dialogHeader}>
        <Typography className={classes.headerTitle}>{(isEdit ? t`Edit System` : t`Add New System`) + ` (Device ${selectedDeviceObj?.serial})`}</Typography>
        <IconButton disableRipple className={classes.iconBtnStyle} onClick={close}><Close style={{ color: "#7f7692" }} /></IconButton>
      </div>
      <div className={classes.dialogContent}>

        {saving && <Loader showLoader={true} className={classes.loaderWrapper} />}

        <div className={classes.sysInfoBox}>
          <Formik
            innerRef={sysFormRef}
            initialValues={!isNew ? { ...sysObj } : { name: "", brandNum: "", type: "", series: "", line: "" }}
            validationSchema={validationSchema}
            onSubmit={(values) => {
              isNew ? createSys(values) : updateSystem(values)
            }}
          >
            {({ values, dirty }) => {
              setSysDirty(dirty)
             return (
              // @ts-ignore
              <Form translate="yes" style={{ width: "100%" }}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell className={classes.tableHeadCell} style={{
                        width: "10%"
                      }}>
                        {t`System Name`}
                      </TableCell>
                      <TableCell className={classes.tableHeadCell} style={{
                        width: "10%"
                      }}>
                        {t`Brand`}
                      </TableCell>
                      {
                        sysObj?.internalId && (
                          <TableCell className={classes.tableHeadCell} style={{
                            width: "10%"
                          }}>
                            {t`System #`}
                          </TableCell>
                        )
                      }
                      <TableCell className={classes.tableHeadCell} style={{
                        width: "10%"
                      }}>
                        {t`Type`}
                      </TableCell>
                      <TableCell className={classes.tableHeadCell} style={{
                        width: "10%"
                      }}>
                        {t`Series`}
                      </TableCell>
                      <TableCell className={classes.tableHeadCell} style={{
                        width: "10%"
                      }}>
                        {t`Capacity`}
                      </TableCell>
                      <TableCell className={classes.tableHeadCell} style={{
                        width: "10%"
                      }}>
                        {t`Line`}
                      </TableCell>
                      {/* <TableCell className={classes.tableHeadCell} style={{
                        width: "15%"
                      }}>
                      </TableCell> */}
                    </TableRow>
                  </TableHead>
                  <TableBody>

                    <TableRow>
                      <TableCell className={classes.tableCell}>
                        <Field name="name" >
                          {({ field, form, meta }: any) => {
                            return <TextField variant="outlined" error={meta.error} size="small" {...field} />
                          }}
                        </Field>
                        <div className={classes.errorRequired}>
                          <ErrorMessage name="name" />
                        </div>
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                        <Field name="brandNum">
                          {({ field, form, meta }: any) => {
                            return (<FormControl
                              className={classes.textField}
                              variant="filled"
                            >
                              <Select
                                error={meta.error}
                                variant="outlined"
                                className={classes.input}
                                IconComponent={SvgArrow}
                                {...field}
                              >
                                {[...brandsOptions]}
                              </Select>
                              <div className={clsx(classes.errorRequired, classes.selectError)}>
                                <ErrorMessage name="brandNum" />
                              </div>
                            </FormControl>
                            );
                          }}
                        </Field>
                      </TableCell>
                      {
                        sysObj?.internalId && (
                          <TableCell className={classes.tableCell}>
                            <Field name="internalId" >
                              {({ field }: any) => {
                                return <TextField {...field} variant="outlined" size="small" disabled/>;
                              }}
                            </Field>
                          </TableCell>
                        )
                      }
                      <TableCell className={classes.tableCell}>
                        <Field name="type">
                          {({ field, form, meta }: any) => {
                            return (<FormControl
                              className={classes.textField}
                              variant="filled"
                            >
                              <Select
                                error={meta.error}
                                variant="outlined"
                                className={classes.input}
                                IconComponent={SvgArrow}
                                {...field}
                              >
                                {["Other", ...(systemTypes || [])].map((type: any) => <MenuItem value={type} key={`typeOption-${type}`}>{type}</MenuItem>)}
                              </Select>
                            </FormControl>);
                          }}
                        </Field>
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                        <Field name="series" >
                          {({ field }: any) => {
                            return <TextField {...field} variant="outlined" size="small" />;
                          }}
                        </Field>
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                        <div className={classes.capacityFields}>
                          <Field name="capacity" >
                            {({ field }: any) => {
                              return (
                                <TextField
                                  {...field}
                                  variant="outlined"
                                  size="small"
                                  value={`${sysObj?.capacity ?? 0} ${capacityUnits?.[sysObj?.capacityMeasurementUnits] ?? ''}`}
                                  disabled
                                />
                              );
                            }}
                          </Field>

                        </div>
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                        <Field name="line">
                          {({ field, form, meta }: any) => {
                            return (<FormControl
                              className={classes.textField}
                              variant="filled"
                            >
                              <Select
                                error={meta.error}
                                variant="outlined"
                                className={classes.input}
                                IconComponent={SvgArrow}
                                {...field}
                              >
                                {lines}
                              </Select>
                              <div className={clsx(classes.errorRequired, classes.selectError)}>
                                {meta.error}
                              </div>
                            </FormControl>);
                          }}
                        </Field>
                      </TableCell>
                      {/* <TableCell className={classes.tableCell}>
                        {isNew ? <span />
                          : (!editSys ?
                            (
                              <IconButton disabled={!canUpdate} onClick={() => setEditSystem(true)}>
                                <EditIcon />
                              </IconButton>
                            ) :
                            (
                              <>
                                <IconButton onClick={() => updateSystem(values)}>
                                  <Check />
                                </IconButton>
                                <IconButton onClick={() => setEditSystem(false)}>
                                  <Close />
                                </IconButton>
                              </>
                            ))}
                      </TableCell> */}
                    </TableRow>
                  </TableBody>
                </Table>
                {isNew &&
                  <div className={classes.createModalActions}>
                    <CoolButton
                      title={t`close create`}
                      variant="contained"
                      onClick={close}
                      width={150}
                      white
                      marginRight
                    >
                      {t`Close`}
                    </CoolButton>
                    <CoolButton
                      title={t`create system`}
                      width={150}
                      marginRight
                      type="submit"
                    >
                      {t`Create System`}
                    </CoolButton>
                  </div>
                }
              </Form>
            )
            }}
          </Formik>
        </div>
        {!isNew &&
          <>
            {/* //////////////////////////////////////////// IDUs */}

            <div className={classes.unitsBox}>
              <div style={{ display: "flex" }}>
                <div
                  className={classes.sectionTitle}
                  style={{
                    visibility: !isEmpty(units.IDU) ? "visible" : "hidden"
                  }}
                  onClick={() => setExpandIndoor(!expandIndoor)}
                >
                  <ExpandMore
                    style={{
                      transform: expandIndoor ? "rotate(0deg)" : "rotate(-90deg)",
                      marginRight: "7px",
                      transitionDuration: "0.5s",
                      transitionProperty: "transform"
                    }}
                  />
                  <Typography>{t`Units` + ` (${Object.keys(units.IDU)?.length})`}</Typography>
                </div>

                <LightTooltip title={indoorUnitsDirty ? t`Save control properties first` : ""}>
                  <span>
                    <Button
                      variant="outlined"
                      onClick={() => setOpenCopyDialog(true)}
                      className={classes.CopyPropertiesBtn}
                      disabled={indoorUnitsDirty}

                    >
                      {t`Copy control properties to service`}
                    </Button>
                  </span>
                </LightTooltip>

                {isService && !isSensiboDevice &&
                  <Button
                    variant="outlined"
                    onClick={handleServiceAddressMappingClick}
                    className={classes.serviceMappingBtn}
                  >
                    {t`Service Address Mapping`}
                  </Button>
                }
              </div>
              {!isEmpty(units.IDU) &&
                <Collapse in={expandIndoor} timeout="auto" unmountOnExit>
                  <Formik
                    innerRef={indoorUnitsformikRef}
                    validationSchema={unitValidationSchema}
                    initialValues={{ items: orderBy(Object.values(units.IDU), [sorting.IDU.by], [sorting.IDU.isAsc ? "asc" : "desc"]) }}
                    enableReinitialize={true}
                    onSubmit={(values, { setSubmitting }) => {
                      const updatedUnits = differenceWith(values.items, Object.values(units.IDU), isEqual).map((unit: any) => ({
                        unitId: unit.id,
                        serialNumber: unit.serialNumber ?? '',
                        name: unit.name,
                        capacity: unit.capacity,
                        capacityMeasurementUnits: unit.capacityMeasurementUnits,
                        model: unit.model ?? '',
                        isVisible: unit.isVisible
                      }));
                      updateUnits({ unitsArr: updatedUnits })
                      .then(() => actions.openAddEditSystem(null, null))
                      .catch((err: any) => addMessage({ message: err.message }));
                    }}
                  >
                    {({ values, dirty, touched, errors }: any) => {
                      setIndoorUnitsDirty(dirty)
                      return (
                      // @ts-ignore
                      <Form translate="yes" style={{ width: "100%" }}>
                        <Table>
                          <TableHead>
                            <TableRow>
                              <TableCell className={classes.tableHeadCell} style={{
                                width: "20%",
                                minWidth: "130px"
                              }}>
                                <div className={classes.tableHeadCont} onClick={() => updateSort("IDU", "name")}>
                                  {t`Unit Name`}
                                  {sorting.IDU.by === "name" ?
                                    <SortUp className={classes.sortIconBase} flip={sorting.IDU.isAsc} /> :
                                    <Sort className={classes.sortIconBase} />
                                  }
                                </div>
                              </TableCell>
                              <TableCell className={classes.tableHeadCell} style={{
                                width: "5%",
                                minWidth: "140px"
                              }}>
                                <div className={classes.tableHeadCont} onClick={() => updateSort("IDU", "address")}>
                                  {t`Control ID`}
                                  {
                                    sorting.IDU.by === "address" ?
                                      <SortUp className={classes.sortIconBase} flip={sorting.IDU.isAsc} /> :
                                      <Sort className={classes.sortIconBase} />
                                  }
                                </div>

                              </TableCell>
                              <TableCell className={classes.tableHeadCell} style={{
                                width: "23%"
                              }}>
                                <div className={classes.tableHeadCont} onClick={() => updateSort("IDU", "capacity")}>
                                  {t`Capacity`}
                                  {
                                    sorting.IDU.by === "capacity" ?
                                      <SortUp className={classes.sortIconBase} flip={sorting.IDU.isAsc} /> :
                                      <Sort className={classes.sortIconBase} />
                                  }
                                </div>

                              </TableCell>
                              <TableCell className={classes.tableHeadCell} style={{
                                width: "10%"
                              }}>
                                {t`SRV Name`}
                              </TableCell>
                              <TableCell className={classes.tableHeadCell} style={{
                                width: "10%",
                                minWidth: "142px"
                              }}>
                                {isDaikin ? t`AirNet(Internal)` : t`SRV Address`}
                              </TableCell>
                              <TableCell className={classes.tableHeadCell} style={{
                                width: "9%"
                              }}>
                                {t`Model`}
                              </TableCell>
                              <TableCell className={classes.tableHeadCell} style={{
                                width: "9%"
                              }}>
                                {t`SN`}
                              </TableCell>
                              <TableCell className={classes.tableHeadCell} style={{
                                width: "9%"
                              }}>
                                <div className={classes.tableHeadCont} onClick={() => updateSort("IDU", "isVisible")}>
                                  {t`Visible`}
                                  {
                                    sorting.IDU.by === "isVisible" ?
                                      <SortUp className={classes.sortIconBase} flip={sorting.IDU.isAsc} /> :
                                      <Sort className={classes.sortIconBase} />
                                  }
                                </div>
                              </TableCell>
                              <TableCell className={classes.tableHeadCell} style={{
                                width: "20%"
                              }}>
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>

                            {
                              loading ?
                                <div className={classes.progress}>
                                  <CircularProgress />
                                </div>
                                :
                                values.items.map((unit: any, index: any) => {
                                  const hasMultiServ = unit.serviceUnits?.filter((srvId: any) => !!allUnits[srvId])?.length > 1;
                                  const firstServ = unit?.serviceUnits.length && units.all[unit?.serviceUnits[0]];
                                  const lastServ = unit?.serviceUnits[unit?.serviceUnits.length - 1];
                                  const { canUpdate: canUpdateUnit = true } = unit || {};
                                  return (
                                    <>
                                      <TableRow key={`${unit.id}-${index}`} className={clsx(!unit.isConnected && classes.disconnectedRow, selectedUnit === unit.id && classes.highLight)}>
                                        <TableCell className={clsx(classes.tableCell, hasMultiServ && classes.servUnit)}
                                          style={{
                                            borderTop: "1px solid rgba(224, 224, 224, 1)"
                                          }}
                                        >
                                          <div className={classes.disconnectedIconParent}>
                                            <Field name={`items[${index}].name`}>
                                              {({ field }: any) => (
                                                <TextField
                                                  {...field}
                                                  variant="outlined"
                                                  size="small"
                                                  error={touched.items && touched.items[index] && !!errors.items && !!errors.items[index]?.name}
                                                  helperText={touched.items && touched.items[index] && errors.items && errors.items[index]?.name}
                                                />
                                              )}
                                            </Field>
                                            {!unit.isConnected && <LinkOff style={{ color: "#ff6565", marginLeft: "5px" }} />}
                                          </div>
                                        </TableCell>
                                        <TableCell className={classes.tableCell}>
                                          <Typography>{unit.address}</Typography>
                                        </TableCell>
                                        <TableCell className={classes.tableCell}>
                                          <Field name={`items[${index}].capacity`} >
                                            {({ field }: any) => <TextField {...field} variant="outlined" size="small"
                                              InputProps={{
                                                endAdornment: (
                                                  <InputAdornment position="end">
                                                    <Field name={`items[${index}].capacityMeasurementUnits`}>
                                                      {({ field }: any) =>
                                                        <FormControl
                                                          className={classes.textField}
                                                          variant="filled"
                                                        >
                                                          <Select
                                                            variant="standard"
                                                            className={classes.input}
                                                            IconComponent={SvgArrow}
                                                            disableUnderline
                                                            {...field}
                                                            value={field.value || "hp"}
                                                          >
                                                            {capacityUnitsOptions}
                                                          </Select>
                                                        </FormControl>
                                                      }
                                                    </Field>
                                                  </InputAdornment>
                                                )
                                              }}
                                            />
                                            }
                                          </Field>

                                        </TableCell>
                                        <TableCell className={classes.tableCell}>
                                          <Typography>{(hasMultiServ) ? "" : (firstServ?.name || "")}</Typography>
                                        </TableCell>
                                        <TableCell className={classes.tableCell}>
                                          <Typography>{(hasMultiServ) ? "" : (!!firstServ ? (isDaikin ? `${firstServ.airnet}(${firstServ?.address})` : firstServ?.address) : "")}</Typography>
                                        </TableCell>
                                        <TableCell className={classes.tableCell}>
                                          <Field name={`items[${index}].model`} >
                                            {({ field, form, meta }: any) => <TextField {...field} variant="outlined" size="small" />}
                                          </Field>
                                        </TableCell>
                                        <TableCell className={classes.tableCell}>
                                          <Field name={`items[${index}].serialNumber`}>
                                            {({ field, form, meta }: any) => <TextField {...field} variant="outlined" size="small" />}
                                          </Field>
                                        </TableCell>
                                        <TableCell className={classes.tableCell}>
                                          <Field name={`items[${index}].isVisible`}>
                                            {({ field, form, meta }: any) => {
                                              return <Checkbox {...field} checked={field.value} />;
                                            }}
                                          </Field>
                                        </TableCell>
                                        <TableCell className={classes.tableCell}>
                                          <div
                                            style={{
                                              display: "flex",
                                              justifyContent: "space-evenly",
                                              width: "100%",
                                              height: "100%"
                                            }}>
                                            <>
                                              <IconButton disabled={!canUpdateUnit} onClick={() => unlinkUnit(unit.id, "IDU")}>
                                                <DeleteIcon />
                                              </IconButton>
                                            </>
                                          </div>
                                        </TableCell>
                                      </TableRow>

                                      {
                                        isService && hasMultiServ && unit.serviceUnits.map((serId: any, i: any) => {
                                          const serUnit = units.all[serId];
                                          if (!serUnit) {
                                            return null;
                                          }
                                          return (
                                            <TableRow key={`${unit.id}-${index}-${serId}`} className={clsx(!unit.isConnected && classes.disconnectedRow)}>
                                              <TableCell className={clsx(classes.servTableCell, classes.servUnit, serId === lastServ && classes.lastServUnit)} >
                                                {!serUnit.isConnected && <LinkOff style={{ color: "#ff6565", marginRight: "5px" }} />}
                                              </TableCell>
                                              <TableCell className={classes.servTableCell}>
                                                <Typography />
                                              </TableCell>
                                              <TableCell className={classes.servTableCell}>
                                                <Typography>{serUnit.capacity}</Typography>
                                              </TableCell>
                                              <TableCell className={classes.servTableCell}>
                                                <Typography>{serUnit.name}</Typography>
                                              </TableCell>
                                              <TableCell className={classes.servTableCell}>
                                                <Typography>{isDaikin ? `${serUnit.airnet}(${serUnit.address})` : serUnit.address}</Typography>
                                              </TableCell>
                                              <TableCell className={classes.servTableCell}>
                                                <Typography>{serUnit.model}</Typography>
                                              </TableCell>
                                              <TableCell className={classes.servTableCell}>
                                                <Typography>{serUnit.serialNumber}</Typography>
                                              </TableCell>
                                              <TableCell className={classes.servTableCell}>
                                              </TableCell>
                                              <TableCell className={classes.servTableCell}>
                                              </TableCell>
                                            </TableRow>
                                          );
                                        })
                                      }
                                    </>
                                  );
                                })
                            }
                          </TableBody>
                        </Table>
                      </Form>
                    )}}
                  </Formik>
                </Collapse>
              }
            </div>

            {/* //////////////////////////////////////////// ODUs */}

            {!isEmpty(units.ODU) && <div className={classes.unitsBox}>
              <div className={classes.sectionTitle} onClick={() => setExpandODU(!expandOutdoor)} >
                <ExpandMore
                  style={{
                    transform: expandOutdoor ? "rotate(0deg)" : "rotate(-90deg)",
                    marginRight: "7px",
                    transitionDuration: "0.5s",
                    transitionProperty: "transform"
                  }}
                />
                <Typography>
                  {t`Outdoor Units` + ` (${Object.keys(units.ODU)?.length})`}
                </Typography>
              </div>
              <Collapse in={expandOutdoor} timeout="auto" unmountOnExit>
                <Formik
                  innerRef={outdoorUnitsformikRef}
                  validationSchema={unitValidationSchema}
                  initialValues={{ items: orderBy(Object.values(units.ODU), [sorting.ODU.by], [sorting.ODU.isAsc ? "asc" : "desc"]) }}
                  enableReinitialize={true}
                  onSubmit={(values, { setSubmitting }) => {
                    const updatedUnits = differenceWith(values.items, Object.values(units.ODU), isEqual).map((unit: any) => ({
                      unitId: unit.id,
                      serialNumber: unit.serialNumber ?? '',
                      name: unit.name,
                      capacity: unit.capacity,
                      capacityMeasurementUnits: unit.capacityMeasurementUnits,
                      model: unit.model ?? "",
                      task: unit.task
                    }));
                    updateUnits({ unitsArr: updatedUnits })
                      .then(() => {
                        actions.openAddEditSystem(null, null)
                        // Updating the ODU can impact system capacity, which is calculated on the backend. As a result, it's necessary to reload the system data.
                        // However, the current implementation reloads data for all systems, rather than targeting only the affected one.
                        getSystems();
                      })
                      .catch((err: any) => addMessage({ message: err.message }));
                  }}
                >
                  {({ values, dirty, touched, errors }: any) => {
                    setOutdoorUnitsDirty(dirty)
                    return (
                    // @ts-ignore
                    <Form translate="yes">
                      <Table style={{ width: "unset" }}>
                        <TableHead>
                          <TableRow>
                            <TableCell
                              className={classes.tableHeadCell}
                              style={{
                                width: "20%",
                                minWidth: "130px"
                              }}
                            >
                              <div
                                className={classes.tableHeadCont}
                                onClick={() => updateSort("ODU", "name")}
                              >
                                {t`Unit Name`}
                                {sorting.ODU.by === "name" ? (
                                  <SortUp
                                    className={classes.sortIconBase}
                                    flip={sorting.ODU.isAsc}
                                  />
                                ) : (
                                  <Sort className={classes.sortIconBase} />
                                )}
                              </div>
                            </TableCell>
                            <TableCell
                              className={classes.tableHeadCell}
                              style={{
                                width: "20%"
                              }}
                            >
                              <div
                                className={classes.tableHeadCont}
                                onClick={() => updateSort("ODU", "capacity")}
                              >
                                {t`Capacity`}
                                {sorting.ODU.by === "capacity" ? (
                                  <SortUp
                                    className={classes.sortIconBase}
                                    flip={sorting.ODU.isAsc}
                                  />
                                ) : (
                                  <Sort className={classes.sortIconBase} />
                                )}
                              </div>
                            </TableCell>
                            <TableCell
                              className={classes.tableHeadCell}
                              style={{
                                width: "10%",
                                minWidth: "142px"
                              }}
                            >
                              {isDaikin
                                ? t`AirNet(Internal)`
                                : t`SRV Address`}
                            </TableCell>
                            <TableCell
                              className={classes.tableHeadCell}
                              style={{
                                width: "9%"
                              }}
                            >
                              {t`Role`}
                            </TableCell>
                            <TableCell
                              className={classes.tableHeadCell}
                              style={{
                                width: "13%"
                              }}
                            >
                              {t`Model`}
                            </TableCell>
                            <TableCell
                              className={classes.tableHeadCell}
                              style={{
                                width: "13%"
                              }}
                            >
                              {t`SN`}
                            </TableCell>
                            <TableCell
                              className={classes.tableHeadCell}
                              style={{
                                width: "15%"
                              }}
                            ></TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {values.items.map((unit: any, index: any) => {
                            const { canUpdate: canUpdateUnit = true } = unit || {};
                            return (
                              <>
                                <TableRow key={`${unit.id}-${index}`} className={clsx(!unit.isConnected && classes.disconnectedRow, selectedUnit === unit.id && classes.highLight)}>
                                  <TableCell className={classes.tableCell}
                                    style={{
                                      borderTop: "1px solid rgba(224, 224, 224, 1)"
                                    }}
                                  >
                                    <div className={classes.disconnectedIconParent}>
                                      <Field name={`items[${index}].name`}>
                                        {({ field }: any) => (
                                          <TextField
                                            {...field}
                                            variant="outlined"
                                            size="small"
                                            error={touched.items && touched.items[index] && !!errors.items && !!errors.items[index]?.name}
                                            helperText={touched.items && touched.items[index] && errors.items && errors.items[index]?.name}
                                          />
                                        )}
                                      </Field>
                                      {!unit.isConnected && <LinkOff style={{ color: "#ff6565", marginLeft: "5px" }} />}
                                    </div>
                                  </TableCell>
                                  <TableCell className={classes.tableCell}>

                                    <Field name={`items[${index}].capacity`} >
                                      {({ field }: any) => <TextField {...field}
                                        variant="outlined"
                                        size="small"
                                        value={field.value || 0}
                                        InputProps={{
                                          endAdornment: (
                                            <InputAdornment position="end">
                                              <Field name={`items[${index}].capacityMeasurementUnits`} >
                                                {({ field }: any) => {
                                                  return (
                                                    <FormControl
                                                      className={classes.textField}
                                                      variant="filled"
                                                    >
                                                      <Select
                                                        variant="standard"
                                                        className={classes.input}
                                                        IconComponent={SvgArrow}
                                                        disableUnderline
                                                        {...field}
                                                        value={field.value || "hp"}
                                                      >
                                                        {capacityUnitsOptions}
                                                      </Select>
                                                    </FormControl>
                                                  );
                                                }}
                                              </Field>
                                            </InputAdornment>
                                          )
                                        }}
                                      />}
                                    </Field>
                                  </TableCell>
                                  <TableCell className={classes.tableCell}>
                                    <Typography>{isDaikin ? `${unit.airnet}(${unit.address})` : unit.address}</Typography>
                                  </TableCell>
                                  <TableCell className={classes.tableCell} >
                                    <Field name={`items[${index}].task`}>
                                      {({ field }: any) =>
                                        <FormControl
                                          className={classes.textField}
                                          variant="filled"
                                        >
                                          <Select
                                            variant="outlined"
                                            className={classes.input}
                                            IconComponent={SvgArrow}
                                            defaultValue={0}
                                            {...field}
                                            value={+field.value}
                                          >
                                            {Object.keys(unitRoles).map((role: any) => <MenuItem value={role} key={`${unit.id}-${unitRoles[role]}`}>{unitRoles[role]}</MenuItem>)}
                                          </Select>
                                        </FormControl>
                                      }
                                    </Field>
                                  </TableCell>
                                  <TableCell className={classes.tableCell}

                                  >
                                    <Field name={`items[${index}].model`} >
                                      {({ field, form, meta }: any) => <TextField {...field} variant="outlined" size="small" />}
                                    </Field>
                                  </TableCell>
                                  <TableCell className={classes.tableCell} >
                                    <Field name={`items[${index}].serialNumber`}>
                                      {({ field, form, meta }: any) => <TextField {...field} variant="outlined" size="small" />}
                                    </Field>
                                  </TableCell>
                                  <TableCell className={classes.tableCell}>
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "space-evenly",
                                        width: "100%",
                                        height: "100%"
                                      }}>
                                      <IconButton disabled={!canUpdateUnit} onClick={() => unlinkUnit(unit.id, "ODU")}>
                                        <DeleteIcon />
                                      </IconButton>
                                    </div>
                                  </TableCell>
                                </TableRow>
                              </>
                            );
                          })
                          }
                        </TableBody>
                      </Table>
                    </Form>
                  )}}
                </Formik>
              </Collapse>
            </div>}

            {/* //////////////////////////////////////////// BS Units */}

            {!isEmpty(units.BS) && <div className={classes.unitsBox}>
              <div className={classes.sectionTitle} onClick={() => setExpandBS(!expandBS)}>
                <ExpandMore
                  style={{
                    transform: expandBS ? "rotate(0deg)" : "rotate(-90deg)",
                    marginRight: "7px",
                    transitionDuration: "0.5s",
                    transitionProperty: "transform"
                  }}
                />
                <Typography>
                  {t`Branch Box` + ` (${Object.keys(units.BS)?.length})`}
                </Typography>
              </div>
              <Collapse in={expandBS} timeout="auto" unmountOnExit>
                <Formik
                  innerRef={bsUnitsformikRef}
                  validationSchema={unitValidationSchema}
                  initialValues={{ items: orderBy(Object.values(units.BS), [sorting.BS.by], [sorting.BS.isAsc ? "asc" : "desc"]) }}
                  enableReinitialize={true}
                  onSubmit={(values, { setSubmitting }) => {
                    const updatedUnits = differenceWith(values.items, Object.values(units.BS), isEqual).map((unit: any) => ({
                      unitId: unit.id,
                      serialNumber: unit.serialNumber ?? '',
                      name: unit.name,
                      capacity: unit.capacity,
                      capacityMeasurementUnits: unit.capacityMeasurementUnits,
                      model: unit.model ?? ''
                    }));
                    updateUnits({ unitsArr: updatedUnits })
                    .then(()=> actions.openAddEditSystem(null, null))
                    .catch((err: any) => addMessage({ message: err.message }));
                  }}
                >
                  {({ values, dirty, touched, errors }: any) => {
                    setBSUnitsDirty(dirty)
                    return (
                    // @ts-ignore
                    <Form translate="yes" style={{ width: "70%" }}>
                      <Table style={{ width: "unset" }}>
                        <TableHead>
                          <TableRow>
                            <TableCell
                              className={classes.tableHeadCell}
                              style={{
                                width: "20%",
                                minWidth: "130px"
                              }}
                            >
                              <div
                                className={classes.tableHeadCont}
                                onClick={() => updateSort("BS", "name")}
                              >
                                {t`Unit Name`}
                                {sorting.BS.by === "name" ? (
                                  <SortUp
                                    className={classes.sortIconBase}
                                    flip={sorting.BS.isAsc}
                                  />
                                ) : (
                                  <Sort className={classes.sortIconBase} />
                                )}
                              </div>
                            </TableCell>
                            <TableCell className={classes.tableHeadCell} style={{
                              width: "16%"
                            }}>
                              <div className={classes.tableHeadCont} onClick={() => updateSort("BS", "capacity")}>
                                {t`Capacity`}
                                {sorting.BS.by === "capacity" ?
                                  <SortUp className={classes.sortIconBase} flip={sorting.BS.isAsc} /> :
                                  <Sort className={classes.sortIconBase} />
                                }
                              </div>
                            </TableCell>
                            <TableCell className={classes.tableHeadCell} style={{
                              width: "10%",
                              minWidth: "142px"
                            }}>
                              {isDaikin ? t`AirNet(Internal)` : t`SRV Address`}
                            </TableCell>
                            <TableCell className={classes.tableHeadCell} style={{
                              width: "9%"
                            }}>
                              {t`Model`}
                            </TableCell>
                            <TableCell className={classes.tableHeadCell} style={{
                              width: "9%"
                            }}>
                              {t`SN`}
                            </TableCell>
                            <TableCell className={classes.tableHeadCell} style={{
                              width: "22%"
                            }}>
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>

                          {
                            values.items.map((unit: any, index: any) => {
                              const { canUpdate: canUpdateUnit = true } = unit || {};
                              return (
                                <>
                                  <TableRow key={`${unit.id}-${index}`} className={clsx(!unit.isConnected && classes.disconnectedRow, selectedUnit === unit.id && classes.highLight)}>
                                    <TableCell className={classes.tableCell}
                                      style={{
                                        borderTop: "1px solid rgba(224, 224, 224, 1)"
                                      }}>
                                      <div className={classes.disconnectedIconParent}>
                                        <Field name={`items[${index}].name`}>
                                          {({ field }: any) => (
                                            <TextField
                                              {...field}
                                              variant="outlined"
                                              size="small"
                                              error={touched.items && touched.items[index] && !!errors.items && !!errors.items[index]?.name}
                                              helperText={touched.items && touched.items[index] && errors.items && errors.items[index]?.name}
                                            />
                                          )}
                                        </Field>
                                        {!unit.isConnected && <LinkOff style={{ color: "#ff6565", marginLeft: "5px" }} />}
                                      </div>
                                    </TableCell>
                                    <TableCell className={classes.tableCell}>
                                      <Field name={`items[${index}].capacity`}>
                                        {({ field }: any) =>
                                          <TextField
                                            {...field}
                                            variant="outlined"
                                            size="small"
                                            value={field.value || 0}
                                            InputProps={{
                                              endAdornment: (
                                                <InputAdornment position="end">
                                                  <Field name={`items[${index}].capacityMeasurementUnits`}>
                                                    {({ field }: any) =>
                                                      <FormControl
                                                        className={classes.textField}
                                                        variant="filled"
                                                      >
                                                        <Select
                                                          variant="standard"
                                                          className={classes.input}
                                                          IconComponent={SvgArrow}
                                                          disableUnderline
                                                          {...field}
                                                          value={field.value || "hp"}
                                                        >
                                                          {capacityUnitsOptions}
                                                        </Select>
                                                      </FormControl>
                                                    }
                                                  </Field>
                                                </InputAdornment>
                                              )
                                            }}
                                          />}
                                      </Field>
                                    </TableCell>
                                    <TableCell className={classes.tableCell}>
                                      <Typography>{unit.address}</Typography>
                                    </TableCell>
                                    <TableCell className={classes.tableCell}>
                                      <Field name={`items[${index}].model`} >
                                        {({ field, form, meta }: any) => <TextField {...field} variant="outlined" size="small" />}
                                      </Field>
                                    </TableCell>
                                    <TableCell className={classes.tableCell}>
                                      <Field name={`items[${index}].serialNumber`} >
                                        {({ field, form, meta }: any) => <TextField {...field} variant="outlined" size="small" />}
                                      </Field>
                                    </TableCell>
                                    <TableCell className={classes.tableCell}>
                                      <div
                                        style={{
                                          display: "flex",
                                          justifyContent: "space-evenly",
                                          width: "100%",
                                          height: "100%"
                                        }}>
                                        <IconButton disabled={!canUpdateUnit} onClick={() => unlinkUnit(unit.id, "BS")}>
                                          <DeleteIcon />
                                        </IconButton>
                                      </div>
                                    </TableCell>
                                  </TableRow >
                                </>
                              );
                            })
                          }
                        </TableBody>
                      </Table>
                    </Form>
                  )}}
                </Formik>
              </Collapse>
            </div>}
          </>}
      </div>

      {
        !isNew && <div className={classes.modalActions}>
          <CoolButton
            title={t`close edit`}
            variant="contained"
            onClick={close}
            width={150}
            white
            marginRight
          >
            {t`Close`}
          </CoolButton>

          <CoolButton
            title={t`Save edit`}
            variant="contained"
            onClick={handleSave}
            width={150}
            marginRight
            disabled={!indoorUnitsDirty && !outdoorUnitsDirty && !bsUnitsDirty && !sysDirty}
          >
            {t`Save`}
          </CoolButton>
        </div>
      }

      {
        openConfirmDialog && <ErrorBox
          title={t`Discard Changes`}
          error={"Are you sure you want to discard changes made on this page?"}
          onAccept={() => actions.openAddEditSystem(null, null)}
          onClose={() => SetOpenConfirmDialog(false)} />
      }

      <Dialog
        open={openCopyDialog}
        onClose={() => { setOpenCopyDialog(false) }}
        aria-labelledby="responsive-dialog-title"
        classes={{ paper: classes.dialogStyle }}
      >

        <DialogTitle id="simple-dialog-title">
          {t`Copy control properties to service`}
        </DialogTitle>

        <DialogContent classes={{ root: classes.dialogContentStyle }}>
          <FormControlLabel
            control={
              <Checkbox
                checked={copyProps.name}
                onChange={(event: any) => { setCopyProps({ name: !copyProps.name, capacity: copyProps.capacity }) }}
                name="isDefault"
                color="primary"
              />
            }
            label={t`Name`}
            labelPlacement={"start"}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={copyProps.capacity}
                onChange={(event: any) => { setCopyProps({ name: copyProps.name, capacity: !copyProps.capacity }) }}
                name="isDefault"
                color="primary"
              />
            }
            label={t`Capacity`}
            labelPlacement={"start"}
          />
        </DialogContent>

        <DialogActions>
          <CoolButton
            onClick={() => setOpenCopyDialog(false)}
            autoFocus={true}
            width={100}
            marginRight

          >
            {t`Cancel`}
          </CoolButton>
          <CoolButton
            onClick={() => {
              const updateArr: any = [];
              Object.values(units.IDU).forEach((unit: any) => {
                unit.serviceUnits.forEach((sunit: any) => {
                  let data: any = {
                    unitId: sunit
                  }
                  if (copyProps.name) data.name = unit.name;
                  if (copyProps.capacity) {
                    data.capacity = unit.capacity;
                    data.capacityMeasurementUnits = unit.capacityMeasurementUnits
                  }
                  updateArr.push(data);
                });
              });
              updateUnits({ unitsArr: updateArr })
                .catch((err: any) => addMessage({ message: err.message }))
                .finally(() => { setOpenCopyDialog(false); setRefreshUnits(refreshUnits + 1) });
            }}
            white
            width={100}
            disabled={!(copyProps.name || copyProps.capacity)}
          >
            {t`Submit`}
          </CoolButton>
        </DialogActions>


      </Dialog>
    </Dialog >
  );
};

export default AddEditSystem;
