/* eslint-disable react/display-name */
import React, { memo, useMemo } from 'react';
import { t as translate } from 'ttag';
import { TextField } from '@material-ui/core';
import { isEmpty, map } from 'lodash';

import Autocomplete from '@material-ui/lab/Autocomplete';
import useConfirmationDialog from '@components/ConfirmationDialogV2';

import useStyles from './styles';
import clsx from 'clsx';

// Separate the data points list into its own component
const DataPointsList = memo(
  ({
    onDragDataPoint,
    paramTemplates,
    onClickDataPoint,
    selectedParamTemplate,
    onSelectParamTemplate,
    selectedDataPoint,
    addedDataPoints = {},
  }) => {
    const [showConfirmation, ConfirmationDialog] = useConfirmationDialog();
    const classes = useStyles();

    const paramTemplateData = useMemo(() => {
      return paramTemplates[selectedParamTemplate]?.data
        ? map(
          Object.keys(paramTemplates[selectedParamTemplate].data),
          (dataPoint) => {
            return {
              id: dataPoint,
              ...paramTemplates[selectedParamTemplate].data[dataPoint],
            };
          }
        )
        : [];
    }, [paramTemplates, selectedParamTemplate]);

    const handleSelectParamTemplate = async (newValue) => {
      let result = true;
      if (selectedParamTemplate) {
        result = await showConfirmation({
          title: translate`Confirm changing param template?`,
          bodyMessage: translate`Changing param template will reset control mapping?`,
          confirmText: translate`Confirm`,
          cancelText: translate`Cancel`,
        });
      }

      if (result) {
        onSelectParamTemplate(newValue.id);
      }
    };

    const handleNativeDrag = (point) => () => {
      onDragDataPoint({point});
    };

    const handleDataPointClick = (dataPoint) => () => {
      if(addedDataPoints[dataPoint.id]){
        onClickDataPoint(dataPoint.id);
      }
    };

    return (
      <div className={classes.dataPoints}>
        <div className={classes.dataPoints__section}>
          <div className={classes.dataPoints__section__instructions}>
            {translate`Parameter Template`}
          </div>
          <div
            className={classes.dataPoints__section__content}
            style={{
              padding: '0 16px',
            }}
          >
            {Object.keys(paramTemplates).length > 0 && (
              <Autocomplete
                className={classes.customAutocompleteField}
                fullWidth
                options={Object.values(paramTemplates)}
                getOptionLabel={(option) => option.name}
                onChange={(_, newValue) => {
                  handleSelectParamTemplate(newValue);
                }}
                value={paramTemplates[selectedParamTemplate]}
                disableClearable={true}
                renderInput={(params) => (
                  <TextField
                    fullWidth
                    placeholder={translate`Select Template`}
                    {...params}
                    variant="outlined"
                    className={classes.customTextField}
                    InputProps={{
                      ...params.InputProps,
                    }}
                  />
                )}
              />
            )}
          </div>
        </div>
        {!isEmpty(paramTemplateData) && (
          <div className={clsx(classes.dataPoints__section, 'list-section')}>
            <div className={classes.dataPoints__section_List_instructions}>
              {translate`Parameter List`}
            </div>
            <div className={classes.dataPoints__list}>
              {paramTemplateData.map((dataPoint) => (
                <div
                  draggable={!addedDataPoints[dataPoint.id]}
                  onDrag={handleNativeDrag(dataPoint)}
                  onClick={handleDataPointClick(dataPoint)}
                  key={dataPoint.id}
                    title={dataPoint.name}
                    className={clsx(classes.dataPoints__listItem, {
                      isSelected: selectedDataPoint === dataPoint.id,
                      draggable: !addedDataPoints[dataPoint.id],
                    })}
                  >
                    {dataPoint.name}
                  </div>
              ))}
            </div>
          </div>
        )}
        <ConfirmationDialog />
      </div>
    );
  }
);

export default DataPointsList;
